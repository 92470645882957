import React, { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Catalog from '~/Catalog';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Toolbar from '~/components/molecule/Toolbar';
import ControlHUD from '~/components/organism/ControlHUD';
import deserializeFormBuilder from '~/components/page/Forms/utils/deserializeFormBuilder';
import {
  useUpdateFormMutation,
  type FormBuilderFragment,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';

import Breadcrumbs from '~/components/molecule/ContentContainer/components/Breadcrumbs';
import Button from '~/components/atom/Button';
import { REACT_FLOW_MINI_MAP } from '~/components/page/Automation/v2/components/Builder/constants/reactFlowLayers';
import AsideContainer from '../AsideContainer';
import useResetFormBuilder from '../../hooks/useResetFormBuilder';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { issueState } from '../../state/issues';
import {
  formState as formStateAtom,
  initialFormAtom,
  interactionState,
} from '../../state';
import { Label } from '~/components/atom/Typography';
import Icon from '~/components/atom/Icon';
import useConfirmModal from '~/hooks/useConfirmModal';
import ButtonWithTooltip from '~/components/molecule/ButtonWithTooltip';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import useBrandSettings from '~/hooks/useBrandSettings';
import composeWidgetUrl from '~/util/composeWidgetURL';

const text = {
  save: 'Opslaan',
  cancel: 'Annuleren',
  viewLive: 'Bekijk live',
  viewLiveDisabledTooltipMessages: {
    disabled: (
      <>
        Dit formulier is niet geactiveerd.
        <br /> Activeer het formulier en sla deze op voor dat je het live kan
        bekijken
      </>
    ),
    missingURL: (
      <>
        Helaas kunnen wij geen live preview aanbieden zonder jouw website URL.
        <br />
        Ga naar Huisstijl in jou account settings on jou URL op te geven
      </>
    ),
  },
  errorsInFormBuilder:
    'Er zijn fouten in je formulier. Bekijk deze in foutherstel.',
  enableWarningLabels: {
    title: 'Pas op!',
    message:
      'Formulier is niet geactiveerd. Activeer het om het live te bekijken.',
    buttonConfirmTitle: 'Activeer en opslaan',
    saveWithoutEnable: 'Opslaan zonder activeren',
  },

  deactivateLabel: 'Deactiveer het formulier',
  activateLabel: 'Activeer het formulier',
};

type Props = {
  id: string;
  formState: FormBuilderFragment | null;
  isFullScreen?: boolean;
  hasChanges: boolean;
  toggleFullScreen: () => void;
};

const ControlComponents: React.FC<Props> = ({
  id,
  formState,
  isFullScreen = false,
  hasChanges,
  toggleFullScreen,
}) => {
  const theme = useTheme();
  const account = useCurrentAccount();
  const addToast = useAddToast();
  const setFormState = useSetRecoilState(formStateAtom);
  const initialFormState = useRecoilValue(initialFormAtom);
  const setInteraction = useSetRecoilState(interactionState);
  const brandSetting = useBrandSettings();
  const [updateForm, { loading: updateLoading }] = useUpdateFormMutation({});

  const setInitialFormState = useSetRecoilState(initialFormAtom);

  const resetAllStates = useResetFormBuilder();
  const issues = useRecoilValue(issueState);
  const hasBlockingIssues =
    issues.filter(({ level }) => level === 'error').length > 0;

  const breadcrumbs = [
    { label: 'Formulieren', to: '/-/forms' },
    { label: formState?.name ?? 'form' },
  ];

  const { setShowModal, modal } = useConfirmModal({
    labels: text.enableWarningLabels,
    buttons: [
      {
        label: text.enableWarningLabels.saveWithoutEnable,
        onClick: () => onSaveForm({ formState, enable: false }),
        appearance: 'primary',
        ghost: true,
      },
      {
        label: text.enableWarningLabels.buttonConfirmTitle,
        onClick: () => onSaveForm({ formState, enable: true }),
        appearance: 'secondary',
      },
    ],
  });

  const onSaveForm = useCallback(
    ({
      formState,
      enable,
    }: {
      formState: FormBuilderFragment | null;
      enable?: boolean;
    }) => {
      if (!formState) return;

      const enabledProp = {
        enabled: formState.enabled,
      };

      if (enable === true) {
        enabledProp.enabled = enable;
        setFormState(prev => {
          if (!prev) return prev;
          return { ...prev, enabled: true };
        });
      }

      const deserialized = deserializeFormBuilder({
        ...formState,
        ...enabledProp,
      });

      void updateForm({
        variables: {
          accountId: account.id,
          formBuilderId: id,
          formBuilder: deserialized,
        },
      }).then(({ errors, data }) => {
        if (errors && errors.length !== 0) {
          return addToast([
            formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
          ]);
        }

        if (data) {
          // Update the states so the change counter gets reset.
          setFormState(data.updateForm);
          setInitialFormState(data.updateForm);

          return addToast([
            formatToastMessage(Catalog.genericSuccessMessage, 'success'),
          ]);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account.id, id],
  );

  const viewLiveProps = (() => {
    if (brandSetting && (!brandSetting.url || brandSetting.url === '')) {
      return {
        disable: false,
        message: text.viewLiveDisabledTooltipMessages.missingURL,
        onClick: () => {
          setInteraction({
            interactionType: 'prompt-url',
          });
        },
      };
    }

    if (initialFormState && initialFormState.enabled === false) {
      return {
        disable: true,
        message: text.viewLiveDisabledTooltipMessages.disabled,
      };
    }

    return { disable: false, message: null };
  })();

  const onViewLiveDefault = () => {
    if (brandSetting && brandSetting.url && formState) {
      return window.open(
        composeWidgetUrl({
          url: brandSetting.url,
          formId: formState.id,
        }),
        '_blank',
      );
    }

    return window.open(formState?.liveURL, '_blank');
  };

  return (
    <Container>
      {modal}
      {!isFullScreen && (
        <JustificationContainer
          align="start"
          justification="start"
          direction="column"
          margin={['l', null, null, null]}
        >
          <BreadcrumbContainer align="center" justification="space-between">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbContainer>
          <Toolbar>
            <JustificationContainer gap="l" align="center" width="100%">
              <ButtonWithTooltip
                size="medium"
                label={text.viewLive}
                ghost
                disabled={viewLiveProps.disable}
                tooltip={
                  viewLiveProps.disable
                    ? { message: viewLiveProps.message }
                    : null
                }
                icon="eye"
                onClick={viewLiveProps.onClick || onViewLiveDefault}
              />
              <ToggleCheckbox
                containerProps={{
                  direction: 'row',
                  align: 'center',
                  gap: 'xxs',
                  width: '100%',
                }}
                size="small"
                label={
                  formState?.enabled ? text.deactivateLabel : text.activateLabel
                }
                onChange={() => {
                  setFormState(prev => {
                    if (!prev) return prev;

                    return {
                      ...prev,
                      enabled: !prev?.enabled,
                    };
                  });
                }}
                value={formState?.enabled ?? false}
              />
            </JustificationContainer>
            <JustificationContainer
              gap="s"
              align="center"
              width="100%"
              justification="end"
            >
              {hasBlockingIssues && (
                <JustificationContainer align="center" gap="xxs">
                  <Icon
                    name="exclamation"
                    color={theme.color('danger')}
                    strokeWidth={2.5}
                    margin={[null, null, 'xxxs', null]}
                  />
                  <Label color={{ group: 'danger' }} margin={[null]}>
                    {text.errorsInFormBuilder}
                  </Label>
                </JustificationContainer>
              )}

              <Button
                size="medium"
                label={text.cancel}
                ghost
                onClick={() => {
                  resetAllStates();
                }}
                disabled={!hasChanges}
              />
              <Button
                size="medium"
                appearance="secondary"
                label={text.save}
                icon="save"
                disabled={updateLoading || hasBlockingIssues || !hasChanges}
                loading={updateLoading}
                onClick={() => {
                  if (formState) {
                    if (!formState?.enabled) {
                      setShowModal(true);
                      return;
                    }

                    onSaveForm({ formState });
                  }
                }}
              />
            </JustificationContainer>
          </Toolbar>
        </JustificationContainer>
      )}
      <ControlHUDContainer
        direction="column"
        align="end"
        isFullScreen={isFullScreen}
      >
        <ControlHUD
          isFullScreen={isFullScreen}
          onFullScreen={toggleFullScreen}
          infoLink="https://help.dathuis.nl/nl/articles/9653252-bouwen-van-formulieren"
        />
      </ControlHUDContainer>
      <AsideContainer isFullScreen={isFullScreen} />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: 0 ${theme.space('l')};
    width: 100%;

    ${REACT_FLOW_MINI_MAP} {
      background: ${theme.color('primary', 'light')};
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: ${theme.space('m')};
    }
  `,
);
const ControlHUDContainer = styled(JustificationContainer)<{
  isFullScreen: boolean;
}>(
  ({ theme, isFullScreen }) => css`
    position: absolute;
    right: ${theme.space('xl')};
    /* Prioritize over the canvas */
    z-index: 1;
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};

    ${REACT_FLOW_MINI_MAP} {
      background: ${theme.color('primary', 'light')};
      position: relative;
      right: 0;
      bottom: 0;
      margin: 0;
      margin-top: ${theme.space('m')};
      border-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

const BREADCRUMB_MOBILE_HEIGHT = '50px';
const BREADCRUMB_HEIGHT = '72px';
const BreadcrumbContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    height: ${BREADCRUMB_HEIGHT};

    ${theme.mq.lessThan('tabletLandscape')`
        height: ${BREADCRUMB_MOBILE_HEIGHT};
      `}
  `};
`;

export default ControlComponents;
