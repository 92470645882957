import React, { useState } from 'react';

import { ListItemForDeletion } from '~/components/bad/Modals/components/ListItemCard';
import { MigrationItem } from '~/components/bad/Modals/components/ListItemCard';

import Modal from './Modal';
import BottomButtonRow from './BottomButtonRow';
import TopSectionContainer from './components/TopSectionContainer';
import ListItemCard from './components/ListItemCard';
import Button from '~/components/atom/Button';
import {
  ModalContainer,
  ListContainer,
  SubHeaderContainer,
  FromContainer,
  ToContainer,
  ErrorMessageContainer,
} from './components/deleteModal.style';
import { convertBackendErrorMessage, getErrorMessageForModal } from './util';
import Catalog from '~/Catalog';
import TEST_ID from './DeleteUserFromOfficeModal.testid';
import { DELETE_ENTITY_TYPE, DELETION_TYPE } from './util/constants';
import { Highlight } from '~/components/atom/Typography';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useDeleteUserFromOfficeMutation } from '~/graphql/types';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import useUserLookup from '~/hooks/useUserLookup';
import useSessionHydration from '~/hooks/useSessionHydration';
import hasValidOffice from './util/hasValidOffice';
import hasValidUser from './util/hasValidUser';
import TextButton from '~/components/atom/TextButton';

const text = {
  headerText: (
    <>
      Gebruiker verwijderen uit een <Highlight>vestiging</Highlight>
    </>
  ),
  cancelButtonLabel: 'Annuleren',
  successButtonLabel: 'Toewijzen en verwijderen',
  description:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker',
  mutationError:
    'Er is iets mis gegaan bij het toewijzen van contacten en openstaande taken. Probeer het later nog eens.',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** To refetch GetUser query */
  refetch?: () => Promise<any>;

  /** If passed from users/userId it is user.id otherwise me.id */
  userId: string;
};
const DeleteUserFromOfficeModal: React.FCC<Props> = ({
  list,
  onClose,
  onCancel,
  refetch,
  userId,
}) => {
  const [, refetchSessionHydration] = useSessionHydration();

  const account = useCurrentAccount();
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const user = useUser(userId);

  const userLookUpTable = useUserLookup();

  const [migrationValues, setMigrationValues] = useState<Array<MigrationItem>>(
    list.map(listItem => ({
      sourceUserId: userId,
      sourceOfficeId: listItem.id,
      targetOfficeId: null,
      targetUserId: null,
      listItem,
    })),
  );

  const [deleteUserFromOffice, { error: mutationError }] =
    useDeleteUserFromOfficeMutation();

  const handleSubmit = async () => {
    const hasValidationError = migrationValues.some(
      item => !hasValidOffice(item) || !hasValidUser(item),
    );

    if (!hasValidationError) {
      for (const item of migrationValues) {
        const result = await deleteUserFromOffice({
          variables: {
            accountId: account.id,
            userId: userId,
            officeId: item.sourceOfficeId,
            transferResourcesTo: {
              officeId: item.targetOfficeId,
              userId: item.targetUserId,
            },
          },
        });

        if (mutationError != null) {
          setErrorMessage(getErrorMessageForModal(mutationError));
          return;
        }

        if (result == null || result.data == null) {
          setErrorMessage(Catalog.genericUnknownErrorMessage);
          return;
        }

        const { error, success } = result.data.deleteUserFromOffice;

        if (error == null && success === false) {
          setErrorMessage(Catalog.genericUnknownErrorMessage);
          return;
        }

        if (error != null) {
          const { lockedByUserId, reason } = error;
          const userLockedByUserName =
            userLookUpTable && userLookUpTable[lockedByUserId];
          const lockedByUserName = getUserName(userLockedByUserName) || '';
          const deletingUserName = getUserName(user);

          const errorMessage = convertBackendErrorMessage(
            lockedByUserName,
            deletingUserName ? deletingUserName : '',
            reason,
          );

          setErrorMessage(errorMessage ? errorMessage : null);
          return;
        } else if (success) {
          setErrorMessage(null);
        }
      }

      if (refetch) await refetch();

      await refetchSessionHydration();
      onClose();
    } else {
      setShowValidation(true);
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      small
    >
      <ModalContainer data-testid={TEST_ID.CONTAINER}>
        <TopSectionContainer
          headerText={text.headerText}
          withBorder
          descriptionText={text.description}
        />

        <ListContainer>
          <SubHeaderContainer>
            <FromContainer>
              <span>Taken en contacten</span>
            </FromContainer>
            <ToContainer>
              <span>Toewijzen</span>
            </ToContainer>
          </SubHeaderContainer>

          {migrationValues.map((migrationValue, idx) => (
            <ListItemCard
              key={migrationValue.sourceOfficeId}
              item={migrationValue.listItem}
              isLastItem={
                idx === list.length - 1 || migrationValues.length === 1
              }
              migrationValue={migrationValue}
              setMigrationValue={data => {
                setMigrationValues(
                  migrationValues.map(item =>
                    item.sourceOfficeId === migrationValue.sourceOfficeId
                      ? data
                      : item,
                  ),
                );
              }}
              showValidation={showValidation}
              entityId={userId}
              entityType={DELETE_ENTITY_TYPE.USER}
              deletionType={DELETION_TYPE.DELETE_USER_FROM_OFFICE}
            />
          ))}

          {errorMessage && (
            <ErrorMessageContainer data-testid={TEST_ID.ERROR_MESSAGE}>
              {errorMessage}
            </ErrorMessageContainer>
          )}
        </ListContainer>

        <BottomButtonRow>
          <TextButton
            size="medium"
            data-testid={TEST_ID.CANCEL}
            appearance="danger"
            onClick={onCancel}
            label={text.cancelButtonLabel}
          />
          <Button
            size="medium"
            data-testid={TEST_ID.SUCCESS}
            appearance="danger"
            onClick={handleSubmit}
            label={text.successButtonLabel}
          />
        </BottomButtonRow>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteUserFromOfficeModal;
