import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { Theme } from '~/theme';

import { measureTextWidth } from '~/util/canvasDrawers';
import useHover from '~/components/bad/util/useHover';

import TEST_ID from './index.testid';
import Tooltip from '~/components/molecule/Tooltip';

type Props = {
  /** The total width this component is allowed to take up */
  width: number;
  tagList: Array<string>;
};
const TagColumnCell: React.FCC<Props> = ({ tagList, width }) => {
  const theme = useTheme();
  const [showTooltip, tooltipProps] = useHover();

  const tagsToShow: Array<string> = [];
  const tagsToShowInTooltip: Array<string> = [];

  /** Always make sure we have enough space to put the extra tags label */
  const extraTagsLabelWidth =
    measureTextWidth(`+${tagList.length}`, getFontSize(theme)) +
    getMarginSize(theme);

  let currentWidth = extraTagsLabelWidth;
  for (const tag of tagList) {
    const tagWidth =
      measureTextWidth(tag, getFontSize(theme)) + 2 * getPaddingSize(theme);

    if (currentWidth !== extraTagsLabelWidth) {
      // is not the first, so add margin
      currentWidth += getMarginSize(theme);
    }
    currentWidth += tagWidth;

    if (currentWidth > width) {
      tagsToShowInTooltip.push(tag);
    } else {
      tagsToShow.push(tag);
    }
  }

  let tagCounterComponent;
  if (tagList.length > tagsToShow.length) {
    tagCounterComponent = (
      <ExtraTagsCounter data-testid={TEST_ID.EXTRA_TAG_COUNTER}>
        +{tagList.length - tagsToShow.length}
      </ExtraTagsCounter>
    );
  }

  let tooltipComponent;
  if (showTooltip && tagsToShowInTooltip.length > 0) {
    tooltipComponent = (
      <StyledTooltip padding={['xxs']}>
        {tagsToShowInTooltip.join(', ')}
      </StyledTooltip>
    );
  }

  return (
    <Container {...tooltipProps}>
      <TagCellContainer width={width}>
        {tagsToShow.map((tag, idx) => (
          <TagElement key={`${idx}-${tag}`}>{tag}</TagElement>
        ))}
        {tagCounterComponent}
      </TagCellContainer>
      {tooltipComponent}
    </Container>
  );
};

const getFontSize = (theme: Theme): number =>
  theme.remToPxRaw(theme.fontSize('s'));
const getMarginSize = (theme: Theme): number =>
  theme.remToPxRaw(theme.space('xxs'));
const getPaddingSize = (theme: Theme): number =>
  theme.remToPxRaw(theme.space('xxs'));

type TagCellContainerProps = {
  width: number;
};
const TagCellContainer = styled.div<TagCellContainerProps>`
  white-space: nowrap;
  overflow-x: hidden;

  ${({ width, theme }) => css`
    max-width: ${width}px;
    font-size: ${getFontSize(theme)}px;

    > :not(:first-child) {
      margin-left: ${getMarginSize(theme)}px;
    }
  `}
`;

const Container = styled.div<{}>`
  display: inline-block;
  width: 100%;
`;

const ExtraTagsCounter = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('text', 'light')};
    margin-left: ${getMarginSize(theme)}px;
  `,
);

const TagElement = styled.div<{}>(
  ({ theme }) => css`
    display: inline-block;
    color: ${theme.color('primary')};
    background-color: ${theme.color('secondary', 'light')};
    /** 20px according to the figma design for the Tag element */
    border-radius: 20px;

    padding: ${theme.space('xxs')} ${getPaddingSize(theme)}px;
  `,
);

const StyledTooltip = styled(Tooltip)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    max-width: 300px;
    text-align: center;
  `,
);

export default TagColumnCell;
