/**
 *
 * A Hook to help use the Confirm modal with a specific payload. It will give the user:
 *  [0] - the component to render. Always show it, if it should be visible is handled by the hook
 *  [1] - the function to show the modal
 *  [2] - a function to update what happens on confirm
 *
 * It expects:
 *  labels - object that determines the label of the modal
 *  onConfirm? - what to do if the user clicks the confirm button
 *  onCancel? - what to do if the user clicks the cancel button
 *  onSecondaryClick? - Replaces the cancel button's functionality. If it is not passed, onCancel is called
 *  dataTestId? - test id to show
 */

import React, { useState } from 'react';
import { Body } from '~/components/atom/Typography';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import { Props as ButtonProps } from '~/components/atom/Button';
import type { ComponentLevel } from '~/styles/constants';

export type ConfirmFunction = () => any;
export type CancelFunction = () => any;
export type SecondaryFunction = () => any;

type ReturnProps = {
  setShowModal: (show: boolean) => void;
  modal: React.ReactNode;
};

export type ModalLabels = {
  title: string;
  message: string;
  buttonCancelTitle?: string;
};

type Args = {
  dataTestId?: string;

  /** Determines the color and icons of the modal */
  level?: ComponentLevel;

  /** Buttons to display on the right side of the modal */
  buttons: Array<ButtonProps>;

  /** Labels for the modal */
  labels: ModalLabels;

  /** Used to remove the cancel button from the modal */
  hideCancel?: boolean;

  /** Extra callback in case you want to add additional functionality on cancel and close */
  onCancel?: CancelFunction;
};

const useConfirmModal = ({
  labels,
  buttons,
  hideCancel = false,
  level = 'warning',
  dataTestId,
  onCancel,
}: Args): ReturnProps => {
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    if (onCancel) onCancel();
    setShowModal(false);
  };

  return {
    setShowModal: (show: boolean) => setShowModal(show),
    modal: showModal ? (
      <Overlay
        onClose={onClose}
        root="confirm-modal"
        dataTestId={dataTestId || 'confirm-modal'}
      >
        <Dialog
          onClose={onClose}
          header={labels.title}
          body={<Body>{labels.message}</Body>}
          buttons={buttons.map(button => ({
            ...button,
            onClick: (...rest) => {
              if (button.onClick) button.onClick(...rest);
              setShowModal(false);
            },
          }))}
          hideCancel={hideCancel}
          cancelLabel={labels.buttonCancelTitle}
          level={level}
        />
      </Overlay>
    ) : null,
  };
};

export default useConfirmModal;
