import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { map, pipe, prop, sortBy, compose, toLower } from 'ramda';
import { useTransition } from 'react-spring';

import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import {
  ExtractedApp_ValuationReport,
  ExtractedApp_PremiumValuationReport,
} from '~/graphql/types.client';

import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import FullPageInformation from '~/components/template/FullPageInformation';
import OverviewListHeader, {
  Props as OverviewListHeaderProps,
} from '~/components/molecule/OverviewListHeader';
import OverviewListHeader_TEST_ID from '~/components/molecule/OverviewListHeader/index.testid';
import { UnalignedButton } from '~/components/atom/Button';
import { ApolloError } from '@apollo/client';
import useIsMounted from '~/hooks/useIsMounted';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import TEST_ID from './index.testid';
import useLimit from '~/hooks/useLimit';
import ValueReportCard from '../ValueReportCard';
import { ReportListItem } from '../../types';
import useDeleteApp from '../../hooks/useDeleteApp';
import AddPremiumValueReportSidebar from '~/components/page/Apps/PremiumValueReport/components/AddPremiumValueReportSidebar';
import Loading from '~/components/atom/Loading';
import Alerts from '~/components/bad/Alerts';
import {
  useGetAppValuationReportsQuery,
  useInsertAppValuationReportMutation,
} from '~/graphql/types';
import useConfirmModal from '~/hooks/useConfirmModal';
import useErrorReporter from '~/hooks/useErrorReporter';
import createPageTitle from '~/util/createPageTitle';

const GENERAL_OFFICE = 'general_office';

const text = {
  pageTitle: 'Waarderapporten',
  title: 'Waarderapporten',
  mainAccount: 'Hoofdaccount',
  newReport: 'Nieuw waarderapport',
  addNewReport: 'Nieuw waarderapport',
  addNewPremiumReport: 'Bestaand waarderapport koppelen',
  errorTitle: 'Geen data gevonden',
  errorExplanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
  modalDescription: (reportName?: string | null): string =>
    `Weet je het zeker? "${reportName ?? 'Waarderapport'}" wordt verwijderd.`,
  modalTitle: 'Waarderapport verwijderen',
  noValueReports: 'Je hebt nog geen waarderapporten',
  confirm: 'Ok',
};

type Props = RouteComponentProps;

const Container = styled.div<{}>`
  flex-direction: row;
`;

const TRAIL_ANIMATION_IN_MS = 150;

const ValueReportCardsContainer = styled.div<{ style: any }>`
  display: flex;
`;
type ValueReportCardsProps = {
  reports: Array<ReportListItem>;
  onDelete: (report: ReportListItem) => void;
  loading: boolean;
};
const ValueReportCards = ({
  reports,
  onDelete,
  loading,
}: ValueReportCardsProps) => {
  const transitions = useTransition(reports, {
    from: { opacity: 0, transform: 'translate3d(0,-50px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50px,0)' },
    trail: TRAIL_ANIMATION_IN_MS,
    keys: report => report.id,
  });

  return (
    <>
      {transitions((style, item, { key }) => {
        const report = item;
        const { canCopy, canLiveView, cornerTagLabel, onCopy } =
          report.cardOptions;
        return (
          report && (
            <ValueReportCardsContainer style={style} key={key}>
              <ValueReportCard
                canCopy={canCopy}
                canLiveView={canLiveView}
                cornerTagLabel={cornerTagLabel}
                isBlocked={false}
                loading={loading}
                editReport={() => void navigate(report.detailsRoute)}
                copyReport={() => onCopy(report)}
                deleteReport={() => onDelete(report)}
                {...report}
              />
            </ValueReportCardsContainer>
          )
        );
      })}
    </>
  );
};

const sortByNameCaseInsensitive = sortBy<ReportListItem>(
  compose(toLower, prop('name')),
);

const updateShapeValuationReport = (
  valuationReportCardOptions: ReportListItem['cardOptions'],
) =>
  map((item: ExtractedApp_ValuationReport): ReportListItem => {
    const image = item?.general?.backgroundImage;
    const bgColor = item?.general?.backgroundColor;

    return {
      __typename: item.__typename,
      id: item.id,
      img: image ?? null,
      name: item.name,
      officeId: item.officeId || GENERAL_OFFICE,
      backgroundColor: bgColor ?? '#2676a5',
      route: item.route,
      detailsRoute: `/-/apps/value-report/${item.id}`,
      cardOptions: valuationReportCardOptions,
    };
  });

const updateShapePremiumValuationReport = (
  premiumValuationReportCardOptions: ReportListItem['cardOptions'],
) =>
  map(
    (item: ExtractedApp_PremiumValuationReport): ReportListItem => ({
      __typename: item.__typename,
      id: item.id,
      img: null,
      name: item.name,
      officeId: GENERAL_OFFICE,
      backgroundColor: '#2676a5',
      route: undefined,
      detailsRoute: `/-/apps/premium-value-report/${item.id}`,
      cardOptions: premiumValuationReportCardOptions,
    }),
  );

type ContainerContentProps = {
  onError: (
    error: ApolloError | React.SetStateAction<string | null | undefined>,
  ) => void;
};
const ContainerContent = ({ onError }: ContainerContentProps) => {
  const isMounted = useIsMounted();
  const errorReporter = useErrorReporter();
  const reportsLimit = useLimit('root.app.AppStatus.ValuationReport.total');

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [reportToDelete, setReportToDelete] = useState<
    ExtractedApp_PremiumValuationReport | ExtractedApp_ValuationReport | null
  >(null);

  const account = useCurrentAccount();

  const baseVariables = useMemo(
    () => ({
      accountId: account.id,
    }),
    [account],
  );

  const [deleteApp, { loading: deleteLoading, error: deleteError }] =
    useDeleteApp({
      reportToDelete,
      onError,
    });

  const handleValuationReportDelete = useCallback(report => {
    setShowModal(true);
    setReportToDelete(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: getAppValuationReportsData,
    networkStatus: getAppValuationReportsNetworkStatus,
    loading: getAppValuationReportsLoading,
    error: getAppValuationReportsError,
    refetch: getAppValuationReportsRefetch,
  } = useGetAppValuationReportsQuery({
    variables: baseVariables,
  });

  const [
    insertAppValuationReport,
    {
      loading: insertAppValuationReportLoading,
      error: insertAppValuationReportError,
    },
  ] = useInsertAppValuationReportMutation({
    onCompleted: data =>
      isMounted() &&
      navigate(`/-/apps/value-report/${data.insertAppValuationReport.id}`),
    onError,
  });

  const createReport = useCallback(
    (
      reportName: string | null | undefined,
      copyReportId: string | null | undefined,
      officeId: string,
    ) => {
      const copyOfficeId = officeId === GENERAL_OFFICE ? null : officeId;
      return insertAppValuationReport({
        variables: {
          ...baseVariables,
          name: reportName || text.newReport,
          officeId: copyOfficeId,
          copyFromAppValuationReportId: copyReportId || null,
        },
      });
    },
    // We ignore the insertAppValuationReport on purpose to prevent unnecessary rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseVariables],
  );

  const { setShowModal, modal } = useConfirmModal({
    buttons: [
      {
        label: text.confirm,
        loading: deleteLoading,
        onClick: async () => {
          if (reportToDelete === null) {
            return errorReporter.captureException(
              new Error('reportToDelete is null in ValueReportList'),
            );
          }

          await deleteApp({
            variables: {
              accountId: account.id,
              id: reportToDelete.id,
            },
          });

          await getAppValuationReportsRefetch();
        },
      },
    ],
    labels: {
      title: text.modalTitle,
      message: text.modalDescription(reportToDelete?.name),
    },
  });

  const [appValuationReportsByName, setAppValuationReportsByName] = useState<
    Array<ReportListItem>
  >([]);

  const [
    premiumAppValuationReportsByName,
    setPremiumAppValuationReportsByName,
  ] = useState<Array<ReportListItem>>([]);

  useEffect(() => {
    const valuationReportCardOptions = {
      canLiveView: true,
      canCopy: reportsLimit.limit > appValuationReportsByName.length,
      onCopy: report =>
        createReport(
          `${report.name} gekopieerd`,
          report.id,
          report.officeId ?? GENERAL_OFFICE,
        ),
    };

    const premiumValuationReportCardOptions = {
      canLiveView: false,
      cornerTagLabel: 'MAATWERK',
      canCopy: false,
      onCopy: () => {},
    };

    const prepareValuationReports = pipe(
      updateShapeValuationReport(valuationReportCardOptions),
      sortByNameCaseInsensitive,
    );
    const preparePremiumValuationReports = pipe(
      updateShapePremiumValuationReport(premiumValuationReportCardOptions),
      sortByNameCaseInsensitive,
    );

    if (getAppValuationReportsData == null) return;

    const isAppValuationReport = (
      report: (typeof getAppValuationReportsData.getAppValuationReports)[0],
    ): report is ExtractedApp_ValuationReport =>
      report?.__typename === 'App_ValuationReport';
    const isAppPremiumValuationReport = (
      report: (typeof getAppValuationReportsData.getAppValuationReports)[0],
    ): report is ExtractedApp_PremiumValuationReport =>
      report?.__typename === 'App_PremiumValuationReport';

    const appValuationReports =
      getAppValuationReportsData.getAppValuationReports.filter(
        isAppValuationReport,
      );
    const premiumAppValuationReports =
      getAppValuationReportsData.getAppValuationReports.filter(
        isAppPremiumValuationReport,
      );

    setAppValuationReportsByName(prepareValuationReports(appValuationReports));

    setPremiumAppValuationReportsByName(
      preparePremiumValuationReports(premiumAppValuationReports),
    );
  }, [
    account,
    reportsLimit.limit,
    appValuationReportsByName.length,
    createReport,
    getAppValuationReportsData,
  ]);

  if (
    isInitialLoadStatus(getAppValuationReportsNetworkStatus) ||
    getAppValuationReportsLoading
  )
    return <Loading />;
  if (
    !getAppValuationReportsData ||
    getAppValuationReportsError ||
    deleteError ||
    insertAppValuationReportError
  ) {
    return (
      <FullPageInformation
        title={text.errorTitle}
        explanation={text.errorExplanation}
      />
    );
  }

  let buttons: OverviewListHeaderProps['buttons'] = [];

  buttons = [
    {
      key: 'overview-header-add-waarderapport-button',
      node: (
        <UnalignedButton
          icon="plus"
          size="medium"
          onClick={() => createReport(null, null, GENERAL_OFFICE)}
          appearance="secondary"
          data-testid={OverviewListHeader_TEST_ID.OVERVIEW_HEADER_ADD_BUTTON}
          label={text.addNewReport}
        />
      ),
      shouldShowButton: reportsLimit.limit > appValuationReportsByName.length,
    },
    {
      key: 'overview-header-add-premium-waarderapport-button',
      node: (
        <UnalignedButton
          icon="plus"
          onClick={() => setShowSidebar(true)}
          appearance="secondary"
          size="medium"
          ghost
          data-testid={OverviewListHeader_TEST_ID.OVERVIEW_HEADER_IMPORT_BUTTON}
          label={text.addNewPremiumReport}
        />
      ),
      shouldShowButton: true,
    },
  ].filter(button => button.shouldShowButton);
  const reports = [
    ...premiumAppValuationReportsByName,
    ...appValuationReportsByName,
  ];
  return (
    <>
      <AddPremiumValueReportSidebar
        isShowing={showSidebar}
        hide={() => setShowSidebar(false)}
        onAdded={() => {
          setShowSidebar(false);
          return getAppValuationReportsRefetch();
        }}
      />
      <OverviewListHeader
        dataTestId="value-report-header"
        title={text.title}
        buttons={buttons}
      />
      {reports.length === 0 ? (
        <EmptyStateComponent
          illustration="box"
          header={text.noValueReports}
          description="Voeg een waarderapport toe aan je website. Hiermee kunnen bezoekers automatisch de waarde van hun woning opvragen. Dat levert jou nieuwe leads en klanten op!"
          buttonLabel="Voeg je eerste waarderapport toe"
          onButtonClick={() => createReport(null, null, GENERAL_OFFICE)}
          dataTestId={TEST_ID.EMPTY_STATE}
        />
      ) : (
        <Reports data-testid="report-row-item">
          <RowReports>
            <ValueReportCards
              loading={deleteLoading}
              reports={reports}
              onDelete={handleValuationReportDelete}
            />

            {insertAppValuationReportLoading && <Loading />}
          </RowReports>
        </Reports>
      )}

      {modal}
    </>
  );
};

const ValueReportList: React.FCC<Props> = () => {
  const [errorMsg, setErrorMsg] = useState<string | null | undefined>(null);

  return (
    <>
      <ContentContainerDefault>
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>
        <Container>
          {errorMsg && <Alerts.SimpleText type="error" text={errorMsg} />}
          <ContainerContent onError={setErrorMsg} />
        </Container>
      </ContentContainerDefault>
    </>
  );
};

const Reports = styled.div<{}>`
  display: flex;
  flex-direction: column;
`;

export const RowReports = styled.div<{}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export default ValueReportList;
