import React from 'react';

type State = {
  loading: boolean;
};

type Props = {};

export type WithDelayedLoading = {
  loading?: boolean;
  removeLoading: () => void;
  setLoading: () => void;
};

const withDelayedLoading = <ComponentProps extends Props>(
  WrappedComponent: React.ComponentType<ComponentProps>,
): React.ComponentType<ComponentProps> =>
  class extends React.Component<ComponentProps, State> {
    state = {
      loading: false,
    };

    buttonTimer: ReturnType<typeof setTimeout> | null = null;
    hasBeenMounted = true;

    componentWillUnmount() {
      this.hasBeenMounted = false;

      if (this.buttonTimer) clearTimeout(this.buttonTimer);
    }

    setLoading = () => {
      this.buttonTimer = setTimeout(() => {
        this.setState({ loading: true });
      }, 200);
    };

    removeLoading = () => {
      if (!this.hasBeenMounted) return;

      this.setState({ loading: false });
      if (this.buttonTimer) clearTimeout(this.buttonTimer);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          loading={this.state.loading}
          setLoading={this.setLoading}
          removeLoading={this.removeLoading}
        />
      );
    }
  };

export default withDelayedLoading;
