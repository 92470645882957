import React from 'react';
import { isEmpty, memoize } from 'underscore';
import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import Icon from '~/components/atom/Icon/index';
import { AccountIssue, AdminAccountInfoSortField } from '~/graphql/types';
import { SortToggleFunction } from '~/components/page/Automation/Flows/util/flowListColumns';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import formatToastMessage from '~/util/formatToastMessage';
import TEST_ID from './index.testid';
import { formattedDateWithMonth } from '~/util/date';
import { isNil } from 'ramda';
import JustificationContainer from '~/components/atom/JustificationContainer';

const accountListColumns = (
  sortToggle: SortToggleFunction,
  version,
  addToast,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.string({
    label: 'Naam',
    dataProperty: 'name',
    width: 200,
    dataTestId: TEST_ID.NAME,
    sorting: {
      onSort: sortDirection =>
        sortToggle(AdminAccountInfoSortField.Name, sortDirection),
    },
  }),
  TableHeaderCell.string({
    label: 'Account ID',
    dataProperty: 'id',
    dataTestId: TEST_ID.ACCOUNT_ID,
    width: 200,
    renderFunction: value => (
      <span
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          void copyTextToClipboard(value).then(() => {
            addToast([formatToastMessage('Gekopieerd', 'success')]);
          });
        }}
      >
        {value}
      </span>
    ),
  }),
  TableHeaderCell.string({
    label: 'Status',
    dataProperty: 'status',
    dataTestId: TEST_ID.STATUS,
    width: 50,
  }),
  TableHeaderCell.date({
    label: 'Annuleringsdatum',
    dataProperty: 'cancellationDate',
    width: 80,
    renderFunction: value => {
      if (isNil(value))
        return (
          <JustificationContainer width="100%" justification="center">
            <Icon name="minus" color="#BDCADE" strokeWidth={2} />
          </JustificationContainer>
        );
      return (
        <JustificationContainer width="100%" justification="center">
          {formattedDateWithMonth(new Date(value))}
        </JustificationContainer>
      );
    },
  }),
  TableHeaderCell.custom({
    label: 'Issues',
    dataProperty: 'issues',
    width: 50,
    renderFunction: (value: Array<AccountIssue>) => (
      <Icon
        name={!isEmpty(value) ? 'exclamation' : 'minus'}
        color={!isEmpty(value) ? '#C6020B' : '#BDCADE'}
        margin={[null, null, null, 'base']}
        strokeWidth={2}
      />
    ),
    sorting: {
      onSort: sortDirection =>
        sortToggle(AdminAccountInfoSortField.Issues, sortDirection),
    },
    version,
  }),
];

const memoizedAccountListColumns = memoize(
  accountListColumns,
  (_func, key) => key,
);

export default memoizedAccountListColumns;
