import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import { Option, OnChangeFunction } from '../..';
import useHover from '~/components/bad/util/useHover';
import Tooltip from '~/components/molecule/Tooltip';
import TEST_ID from './index.testid';

const text = {
  loading: 'Laden...',
};

export type Props = {
  options: Array<Option>;
  onChange: OnChangeFunction;
  selectedOptionIdx: number | null;
  loading?: boolean;
  dropdownWidth?: number;
  actuallySelected?: number | null;
  onResizeList: () => void;
  onClickOutside: () => void;
};

const DEFAULT_ITEM_LIST_HEIGHT = 40;
const DropdownList = ({
  options,
  onChange,
  selectedOptionIdx,
  loading,
  dropdownWidth,
  actuallySelected,
  listRef,
  ...rest
}) => {
  const theme = useTheme();

  const [showTooltip, tooltipProps] = useHover();

  useEffect(() => {
    if (listRef && listRef.current !== null) {
      const listItemHeight =
        listRef.current.children.length > 0
          ? listRef.current.children[0].clientHeight
          : DEFAULT_ITEM_LIST_HEIGHT;

      let scrollIndex = selectedOptionIdx ? selectedOptionIdx : 0;
      scrollIndex = scrollIndex > 10 ? scrollIndex - 3 : scrollIndex;
      listRef.current.scrollTop = listItemHeight * scrollIndex;
    }
  }, [listRef, selectedOptionIdx]);

  const optionsList = options.map((option, index) => {
    const { styleOptions, type, key, label, icon, tooltipMessage } = option;
    const isSelected = index === actuallySelected;
    const isHovered = !isSelected && selectedOptionIdx === index;

    const shouldShowLineAbove = !!(styleOptions && styleOptions.lineAbove);
    const disabled = type === 'DISABLED';

    return (
      <Item
        isHovered={isHovered}
        key={key}
        isSelected={isSelected}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          !disabled && onChange({ option, selectedOptionIdx: index });
        }}
        disabled={disabled}
        $error={type === 'DANGER'}
        lineAbove={shouldShowLineAbove}
        data-objectid={key}
        data-selected={isSelected.toString()}
        data-testid={TEST_ID.LIST_ITEM}
        {...(tooltipMessage ? tooltipProps : {})}
      >
        {icon ? (
          <JustificationContainer align="center">
            <Icon
              name={icon.name}
              margin={[null, 'xxs', null, null]}
              color={
                icon.color
                  ? theme.color(icon.color.group, icon.color.variant)
                  : undefined
              }
            />
            {label}
          </JustificationContainer>
        ) : (
          label
        )}

        {showTooltip && tooltipMessage && (
          <Tooltip padding={['xxs']}>{tooltipMessage}</Tooltip>
        )}
      </Item>
    );
  });

  if (loading) {
    return (
      <ItemList ref={listRef} $width={dropdownWidth}>
        <LoadingContainer width="250px" padding={['s', 'base']} gap="base">
          <Icon name="spinner" /> {text.loading}
        </LoadingContainer>
      </ItemList>
    );
  }

  return (
    <ItemList
      {...rest}
      data-testid="dropdown-list"
      $width={dropdownWidth}
      ref={listRef}
    >
      {optionsList}
    </ItemList>
  );
};

const LoadingContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    color: ${theme.color('tertiary')};
  `,
);

const ItemList = styled.ul<{ $width: number }>(
  ({ theme, $width }) => css`
    width: 100%;
    min-width: ${$width ? `${$width}px` : 'max-content'};
    user-select: none;
    max-height: 50vh;

    /** Minus EXTRA_SPACE on both sides */
    max-width: calc(100vw - 30px);
    display: inline-block;

    background: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('s')};
    border-radius: ${theme.getTokens().border.radius.base};

    padding: 0;
    margin: 0;

    overflow-y: auto;
    list-style-type: none;
    cursor: pointer;

    ${scrollBarStyles}

    /** Allow max-width to be applied by the browser by removing min-width */
    ${theme.mq.lessThan('mobile')`
      min-width: unset;
    `}

    ${theme.mq.greaterThan('tablet')`
      max-width: 40vw;
      overflow-wrap: break-word;
    `}
  `,
);

const Item = styled.li<{
  isSelected: boolean;
  disabled?: boolean;
  $error?: boolean;
  lineAbove?: boolean;
  isHovered?: boolean;
}>(({ theme, isSelected, $error, disabled, lineAbove, isHovered }) => {
  const color = disabled
    ? theme.color('tertiary', 'dark')
    : $error
      ? theme.color('danger')
      : isHovered
        ? theme.color('primary', 'light')
        : isSelected
          ? theme.color('primary')
          : theme.color('text');

  const hoverColor = !disabled && !$error && theme.color('primary', 'light');

  return css`
    width: 100%;
    white-space: normal;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    padding: ${theme.space('s')} ${theme.space('base')};

    background: ${isHovered && !disabled
      ? theme.color('white', 'dark')
      : theme.color('white')};
    color: ${color};
    text-shadow: ${isSelected
      ? `0 0 0.65px ${color}, 0 0 0.65px ${color}`
      : 'none'};
    transition:
      background-color 0.3s,
      color 0.3s,
      text-shadow 0.3s;

    ${lineAbove &&
    css`
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px;
        height: 1px;
        width: calc(100% - 12px);
        border-top: 1px solid ${theme.color('tertiary', 'light')};
      }
    `}

    &:hover {
      background: ${!disabled && theme.color('white', 'dark')};
      /* Instead of using font-weight, we use text-shadow to stop an item from jumping when it is hovered over. */
      text-shadow:
        0 0 0.65px ${hoverColor},
        0 0 0.65px ${hoverColor};
    }
  `;
});

export default DropdownList;
