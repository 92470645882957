import React from 'react';
import styled, { css } from 'styled-components';
import { SectionHelpButton } from '~/components/atom/AnimatedBlock';
import { Heading3 } from '~/components/atom/Typography';

import CheckboxInputGroup from '~/components/bad/Inputs/CheckboxInputGroup';

export type Props = {};

const text = {
  notificationHeader: 'E-mail notificaties',
  subtitle: 'Type notificaties',
  dailyDigest: 'Algemeen',
  tasks: 'Taken',
  valueReport: 'Waarderapport',
  bbWaardecheck: 'Brainbay Waardecheck',
  vboWaardecheck: 'Matrixian Waardecheck',
  integrationHeader: 'E-mailintegratie',
  contact: 'Contacten',
  app: 'Apps',
};

const dailyDigestOptions = [
  { label: 'Dagelijks overzicht van nieuwe activiteiten', name: 'dailyDigest' },
];

const taskOptions = [
  {
    label: 'Taken die zijn toegewezen aan mij',
    name: 'taskGotAssignedToMe',
  },
  {
    label: 'Taken die zijn toegewezen aan mijn vestiging(en)',
    name: 'taskGotAssignedToMyOffices',
  },
  {
    label:
      'Nieuwe taken die niet zijn toegewezen aan een vestiging en gebruiker',
    name: 'taskNewUnassigned',
  },
];

const appOption = [
  {
    label: 'Activiteiten die plaatsvinden in apps',
    name: 'app',
  },
];

const EmailNotificationsForm: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <SectionBody data-testid={dataTestId} {...rest}>
    <Heading3 size="m" margin={[null]}>
      {text.notificationHeader}
      <SectionHelpButton
        link={{
          link: 'https://help.dathuis.nl/nl/articles/3939508-e-mail-notificaties-uitgelegd',
        }}
      />
    </Heading3>

    <CheckboxInputGroup
      $key="daily-digest-checkbox-group"
      title={text.dailyDigest}
      options={dailyDigestOptions}
    />
    <CheckboxInputGroup
      $key="task-checkbox-group"
      title={text.tasks}
      options={taskOptions}
    />
    <CheckboxInputGroup
      $key="app-checkbox-group"
      title={text.app}
      options={appOption}
    />
  </SectionBody>
);

const SectionBody = styled.div<{}>`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('m')};
  `};
`;

export default EmailNotificationsForm;
