import React from 'react';
import { EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import Button from '~/components/atom/Button';
import { menuState } from '../../../../../state';
import { useSetRecoilState } from 'recoil';
import AnimatedPath from '~/components/page/Automation/v2/components/Builder/components/edgeTypes/components/AnimatedPath';

export const EDGE_BORDER_RADIUS = 20;

export type EdgeData = {
  action: ClientFlowAction;
  parentNode: ClientFlowAction;
  id: string;
  parentId: string;
};

export type Props = EdgeProps<EdgeData> & {};

const AddNodeEdge: React.FCC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    // data,
    source,
    target,
    markerEnd,
  }) => {
    const setMenuState = useSetRecoilState(menuState);
    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return (
      <>
        <AnimatedPath
          id={id}
          style={{
            ...style,
            strokeWidth: 1.5,
            pointerEvents: 'none',
            transition: 'opacity 0.5s ease-out',
            transitionDelay: '2s',
          }}
          className="react-flow__edge-path"
          d={`${edgePath}`}
          markerEnd={markerEnd}
        />

        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            <Button
              icon="plus"
              onClick={() => {
                setMenuState({
                  edgeId: id,
                  openedOnEdge: true,
                  targetX: targetX - 30,
                  targetY: targetY - 5,
                  sourceId: source,
                  targetId: target,
                });
              }}
            />
          </div>
        </EdgeLabelRenderer>
      </>
    );
  },
);

export default AddNodeEdge;
