import React from 'react';
import styled, { css } from 'styled-components';
import { Link as NavLink } from '~/components/molecule/Link';

import { OfficeInList } from '~/components/page/Settings/Profile/components/FormContainer';
import { UserStatus } from '~/graphql/types';

import { Field, EmailField } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorModal from '~/hooks/useErrorModal';
import RadioButton from '~/components/molecule/RadioButton';
import useCurrentUser from '~/hooks/useCurrentUser';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import Button from '~/components/atom/Button';
import { Heading4, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';

type Props = {
  officesList: Array<OfficeInList>;
  readOnly: boolean | null | undefined;
  onMainOfficeUpdate: (name: string, value: string) => any;
  showDeleteModal: (arg0: string) => void;
  mayEdit: boolean;

  /** users/userId needs this prop*/
  userId?: string;
};

const StyledNavLink = styled(NavLink)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};

    &:hover {
      color: ${theme.color('primary')};
    }
  `};
`;
const text = {
  headoffice: Catalog.mainOfficeLabel,
  office: Catalog.officeLabel,
  phone: Catalog.phoneLabel,
  email: Catalog.emailLabel,
  role: Catalog.roleLabel,
  deleteErrorTitle: 'Verwijderen uit vestiging niet mogelijk',
  deleteErrorMessage: (
    <>
      Een gebruiker dient aan tenminste één vestiging gekoppeld te zijn. Ga naar{' '}
      <StyledNavLink to={'/-/settings/users'}>Gebruikers</StyledNavLink> om deze
      gebruiker te wijzigen of te verwijderen.
    </>
  ),
};
const FormFields: React.FCC<Props> = ({
  officesList,
  readOnly,
  onMainOfficeUpdate,
  showDeleteModal,
  mayEdit,
}) => {
  const { update: mayUpdate } = useUserRights({
    category: 'Users',
  });

  const viewingModeProps = useViewingModeProps();

  const me = useCurrentUser();
  const numberOfOffices = officesList.length;

  if (me == null) {
    throw new Error(
      `${cleanedFilename(__filename)} | Should not occur | User is null`,
    );
  }

  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });

  return (
    <>
      <JustificationContainer width="100%" direction="column" gap="base">
        <EmailField name="email">
          {({ input, meta: { error, touched } }) => (
            <Input
              width="100%"
              size="large"
              label={{ text: text.email }}
              externalErrors={
                FormUtils.showError(error, touched)
                  ? [FormUtils.showError(error, touched)]
                  : []
              }
              disabled
              {...input}
              dataTestId="user-email-input"
              {...viewingModeProps}
            />
          )}
        </EmailField>

        <Field name="phone">
          {({ input, meta: { error, touched, submitting } }) => (
            <Input
              width="100%"
              size="large"
              label={{ text: text.phone }}
              type="tel"
              externalErrors={
                FormUtils.showError(error, touched)
                  ? [FormUtils.showError(error, touched)]
                  : []
              }
              disabled={submitting || readOnly}
              {...input}
              dataTestId="user-phone-input"
              {...viewingModeProps}
            />
          )}
        </Field>
      </JustificationContainer>

      <Heading4 variant={Variant.primary} margin={['base', null]}>
        {text.office}
      </Heading4>

      {officesList.map((office, index) => {
        const isDeleting = office.relation?.status === UserStatus.Deleting;
        const fieldIndex = index + 1;
        const officeId = office.id;

        return (
          <JustificationContainer
            width="100%"
            key={index}
            data-testid="office-list-item"
            data-objectid={`office-item-${officeId}`}
            gap="base"
            align="center"
            direction="column"
          >
            <JustificationContainer width="100%" gap="base">
              <Field name={`officeName-${fieldIndex}`}>
                {({ input, meta: { error, touched } }) => (
                  <Input
                    width="100%"
                    size="large"
                    label={{ text: text.office }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled
                    {...input}
                    dataTestId={`office-name-${officeId}-input`}
                  />
                )}
              </Field>
              <Field name={`officeRole-${fieldIndex}`}>
                {({ input, meta: { error, touched } }) => (
                  <Input
                    width="100%"
                    size="large"
                    label={{ text: text.role }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled
                    {...input}
                  />
                )}
              </Field>
            </JustificationContainer>
            <JustificationContainer
              width="100%"
              align="center"
              justification="space-between"
            >
              <Field name="selectedMainOfficeId">
                {({ input }) => (
                  <RadioButton
                    margin={[null]}
                    label={index === 0 ? text.headoffice : undefined}
                    onChange={e => {
                      const { value } = e.target;
                      if (value === 'on') {
                        onMainOfficeUpdate('selectedMainOfficeId', officeId);
                      }
                    }}
                    checked={input.value === officeId}
                    disabled={readOnly || !mayUpdate}
                    dataTestId={`mainOffice-${officeId}`}
                    dataObjectId={officeId}
                  />
                )}
              </Field>

              {officesList.length > 1 && (
                <Button
                  appearance="danger"
                  icon="trashcan"
                  ghost
                  dataTestId={`delete-user-button-${officeId}`}
                  onClick={() => {
                    numberOfOffices === 1
                      ? showErrorModal()
                      : showDeleteModal(officeId);
                  }}
                  disabled={isDeleting || !mayEdit}
                  loading={isDeleting}
                  size="medium"
                />
              )}
            </JustificationContainer>
          </JustificationContainer>
        );
      })}
      {errorModal}
    </>
  );
};

export default FormFields;
