import React, { useCallback, useEffect, useState } from 'react';
import { type RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import {
  AppValuationReport_Update,
  useGetAppValuationReportQuery,
  useUpdateAppValuationReportMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import MasterDetail from '~/components/template/MasterDetail';

import General from './pages/General';
import Design from './pages/Design';
import Address from './pages/Address';
import LivingArea from './pages/LivingArea';
import HouseType from './pages/HouseType';
import Swiping from './pages/Swiping';
import RequestUserDetails from './pages/RequestUserDetails';
import FinalisedReport from './pages/FinalisedReport';
import Privacy from './pages/Privacy';
import Analytics from './pages/Analytics';
import convertQueryToUpdate, { UpdateData } from './utils/convertQueryToUpdate';
import { navBarItems } from './constants';
import FooterSaveBar from '~/components/bad/SaveBar/FooterSaveBar';
import findDifference from '~/util/findDifference';
import formatToastMessage from '~/util/formatToastMessage';
import useErrorReporter from '~/hooks/useErrorReporter';
import useAddToast from '~/hooks/useAddToast';
import objectDiff from '~/util/objectDiff';
import { isEmpty, mergeDeepRight } from 'ramda';
import Div from '~/components/atom/Div';
import EditableText from '~/components/organism/EditableText';
import ErrorDescription from './components/ErrorDescription';
import TEST_ID from './index.testid';
import { defaultErrorsByPath, defaultValueReportImageStash } from './state';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import createPageTitle from '~/util/createPageTitle';

const text = {
  defaultPageTitle: 'Waarderapport',
  updateSuccess: 'Wijzigigen zijn opgeslagen.',
  updateError:
    'Wijzigingen kunnen niet worden opgeslagen. Probeer het opnieuw. Als het blijft gebeuren, neem contact met ons op via de chat rechts onderin.',
};

export type Props = {
  dataTestId?: string;
  reportId: string;
  uri?: string;
};

const ValueReportDetails: React.FCC<Props> = ({ reportId, uri, ...rest }) => {
  const reporter = useErrorReporter();
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();

  const [updated, setUpdated] = useState<UpdateData | null>(null);
  const [original, setOriginal] = useState<UpdateData | null>(null);

  const errorsByPath = useRecoilValue(defaultErrorsByPath);
  const resetErrors = useResetRecoilState(defaultErrorsByPath);
  const resetImageStash = useResetRecoilState(defaultValueReportImageStash);

  const {
    data,
    loading: queryLoading,
    error,
    refetch,
  } = useGetAppValuationReportQuery({
    variables: { accountId, id: reportId },
  });

  const [updateAppValuationReport, { loading: mutationLoading }] =
    useUpdateAppValuationReportMutation();

  useEffect(() => {
    if (!queryLoading && data) {
      const inputFormatted = convertQueryToUpdate(data);
      setUpdated(inputFormatted);
      setOriginal(inputFormatted);
    }
  }, [data, queryLoading]);

  const updateReport = async (update: AppValuationReport_Update) => {
    const { data, errors } = await updateAppValuationReport({
      variables: {
        accountId,
        id: reportId,
        update,
      },
    });

    if (errors || !data) {
      reporter.captureException(
        new Error(
          `No data or errors from updateAppValuationReport ${JSON.stringify(
            errors,
          )}}`,
        ),
        'debug',
      );
      addToast([formatToastMessage(text.updateError, 'danger')]);
      return;
    }

    if (data) {
      const inputFormatted = convertQueryToUpdate(data);

      setUpdated(inputFormatted);
      setOriginal(inputFormatted);

      addToast([formatToastMessage(text.updateSuccess, 'success')]);
      void refetch();
    }

    return;
  };

  const onSave = async () => {
    if (!updated || !original) return;

    const updatedFields = objectDiff(original, updated, true);

    if (!updatedFields || isEmpty(updatedFields)) return;

    await updateReport(updatedFields);
  };

  const onCancel = useCallback(() => {
    setUpdated(original);
    resetErrors();
    resetImageStash();
  }, [original, resetErrors, resetImageStash]);

  const onUpdateData = (
    category: keyof AppValuationReport_Update,
    value: any,
  ) => {
    setUpdated(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, { [category]: value }) as any as typeof prev;
    });
  };

  const convertedData = data ? convertQueryToUpdate(data) : null;

  const changeCount =
    original != null && updated != null
      ? findDifference(updated, original).differenceCount
      : 0;

  const totalErrorCount = Object.values(errorsByPath).filter(a => a).length;

  useEffect(() => {
    if (changeCount === 0 && totalErrorCount > 0) resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount, totalErrorCount]);

  useEffect(
    () => () => {
      resetErrors();
      resetImageStash();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (queryLoading) {
    return (
      <Container>
        <DatHuisLoading />
      </Container>
    );
  }

  if (error || !convertedData || !uri || !updated) {
    return (
      <Container>
        <AppErrorScreen />
      </Container>
    );
  }

  return (
    <Container appTitle={convertedData.name}>
      <Div data-testid={TEST_ID.CONTAINER} {...rest}>
        <EditableText
          onSave={async value => updateReport({ name: value })}
          text={convertedData.name}
          size="xl"
          iconSize="m"
          as="h2"
          margin={[null, null, 'base']}
          dataTestId="editable-name-text"
        />

        <MasterDetail
          basePath={uri}
          issues={{
            '': errorsByPath['/'] ? 1 : 0,
            '/design': errorsByPath['/design'] ? 1 : 0,
          }}
          navbar={navBarItems}
        >
          <General
            reportId={reportId}
            path="/"
            data={convertedData.route}
            updated={updated.route}
            loading={queryLoading}
            onUpdate={value => onUpdateData('route', value)}
          />
          <Design
            path="/design"
            updated={updated.general}
            loading={queryLoading}
            onUpdate={value => onUpdateData('general', value)}
          />
          <Address
            path="/address"
            updated={updated.address}
            loading={queryLoading}
            onUpdate={value => onUpdateData('address', value)}
          />
          <LivingArea
            path="/living-area"
            updated={updated.livingArea}
            loading={queryLoading}
            onUpdate={value => onUpdateData('livingArea', value)}
          />
          <HouseType
            path="/house-type"
            updated={updated.houseType}
            loading={queryLoading}
            onUpdate={value => onUpdateData('houseType', value)}
          />
          <Swiping
            path="/comparable-objects"
            updated={updated.comparableProperties}
            loading={queryLoading}
            onUpdate={value => onUpdateData('comparableProperties', value)}
          />
          <RequestUserDetails
            path="/request-user-details"
            updated={updated.userData}
            loading={queryLoading}
            onUpdate={value => onUpdateData('userData', value)}
          />
          <FinalisedReport
            path="/finalised-report"
            updated={updated.report}
            loading={queryLoading}
            onUpdate={value => onUpdateData('report', value)}
          />
          <Privacy
            path="/privacy"
            updated={updated.general}
            loading={queryLoading}
            onUpdate={value => onUpdateData('general', value)}
          />
          <Analytics
            path="/analytics"
            updated={updated.general}
            loading={queryLoading}
            onUpdate={value => onUpdateData('general', value)}
          />
        </MasterDetail>

        {changeCount !== 0 && (
          <FooterSaveBar
            saveErrorMessage={
              totalErrorCount > 0 ? (
                <ErrorDescription
                  totalErrorCount={totalErrorCount}
                  errorsByPath={errorsByPath}
                  reportId={reportId}
                />
              ) : undefined
            }
            numberOfChanges={changeCount}
            onSave={onSave}
            onCancel={onCancel}
            disableSave={
              queryLoading || mutationLoading || totalErrorCount !== 0
            }
          />
        )}
      </Div>
    </Container>
  );
};

const Container: React.FCC<{ appTitle?: string }> = ({
  children,
  appTitle,
}) => (
  <>
    <MetaTags>
      <title>{createPageTitle(appTitle || text.defaultPageTitle)}</title>
    </MetaTags>
    <ContentContainerDefault
      breadcrumbs={[
        {
          to: '/-/apps/value-report',
          label: 'Waarderapporten',
        },
        ...(appTitle ? [{ label: appTitle }] : []),
      ]}
    >
      {children}
    </ContentContainerDefault>
  </>
);

const WithReportId: React.FC<RouteComponentProps<{ reportId: string }>> = ({
  reportId,
  uri,
}) => {
  if (!reportId) return <AppErrorScreen />;

  return <ValueReportDetails reportId={reportId} uri={uri} />;
};

export default WithReportId;
