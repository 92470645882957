import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlowV2_Action_Contact_AssignFragment } from '~/graphql/types';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import Dropdown, { SelectedOptionOf } from '~/components/molecule/Dropdown';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import useEntityValidationForInputs from '../../../Builder/hooks/useEntityValidationForInputs';
import FieldLabel from '../../../FieldLabel';
import { Checkbox } from '~/components/bad/Inputs/Checkbox';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import TipBanner from '~/components/organism/TipBanner';
import Link from '~/components/molecule/Link';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Contact_AssignFragment;
};

type State = Omit<
  FlowV2_Action_Contact_AssignFragment,
  | 'id'
  | 'accountId'
  | 'parentIds'
  | 'flowBlueprintId'
  | '_v'
  | '__typename'
  | 'actionType'
>;

const text = {
  userLabel: 'Selecteer gebruiker',
  officeLabel: 'Selecteer vestiging',
  checkboxName: 'overwriteAssignee',
  checkboxLabel: 'Huidige eigenaar van het contact overschrijven',
  officeDropdownLabel: 'Selecteer vestiging',
  userDropdownLabel: 'Selecteer gebruiker',
  assignToWorkingArea: 'Toewijzen op basis van mijn werkgebieden',

  workingAreaTipHeader: 'Hoe verloopt toewijzen buiten mijn werkgebieden?',
  workingAreaTipText: (
    <>
      Geef hieronder aan welke vestiging en gebruiker de contacten toegewezen
      krijgen die buiten de werkgebieden van je vestiging(en) vallen.
      Werkgebieden kan je vastleggen in de vestigingsinstellingen.
      <br />
      <JustificationContainer margin={['xxs', null, null, null]}>
        <Link to="/-/settings/offices" target="_blank">
          Vestigingen bekijken
        </Link>
      </JustificationContainer>
    </>
  ),
};

const AssignContact: React.FCC<Props> = ({ action, onChange }) => {
  const initials = {
    office: action.office,
    user: action.user,
    overwriteAssignee: action.overwriteAssignee,
  };

  const [actionDetails, setActionDetails] = useState<State>(initials);

  const { office, user, overwriteAssignee } = actionDetails;

  const officeOptions = useOfficeOptions();

  const userOptions = useUserOptions({
    withAllUsersOption: true,
    officeId: office,
  });

  const onCheckboxClick = () => {
    setActionDetails(prev => ({
      ...prev,
      overwriteAssignee: !prev.overwriteAssignee,
    }));
  };

  const onChooseOffice = (option: SelectedOptionOf<any>) => {
    setActionDetails(prev => ({
      ...prev,
      office: option.option.payload?.id,
    }));
  };

  const onChooseUser = (option: SelectedOptionOf<any>) => {
    setActionDetails(prev => ({
      ...prev,
      user: option.option.payload?.id,
    }));
  };

  const onSetByWorkingAreaChange = (value: boolean) => {
    setActionDetails(prev => ({
      ...prev,
      setByWorkingArea: value,
    }));
  };

  useEffect(() => {
    onChange({ ...action, ...actionDetails });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const { userError, officeError } = useEntityValidationForInputs({
    userOptions,
    userId: actionDetails.user,
    officeId: actionDetails.office,
  });

  return (
    <>
      <ToggleCheckbox
        containerProps={{
          align: 'center',
          width: '100%',
          justification: 'space-between',
          margin: ['base', null],
        }}
        label={text.assignToWorkingArea}
        value={action.setByWorkingArea ?? false}
        onChange={() => {
          onSetByWorkingAreaChange(!(action.setByWorkingArea ?? false));
        }}
      />

      {action.setByWorkingArea === true && (
        <TipBanner
          id="working_area_tip"
          headerText={text.workingAreaTipHeader}
          dismissible={false}
        >
          {text.workingAreaTipText}
        </TipBanner>
      )}
      <JustificationContainer
        width="100%"
        direction="column"
        gap="base"
        margin={[null, null, 'base', null]}
      >
        <JustificationContainer width="100%" align="center">
          <FieldLabel>{text.officeLabel}</FieldLabel>
          <Dropdown
            dataTestId={TEST_ID.OFFICE_DROPDOWN}
            label={text.officeDropdownLabel}
            options={officeOptions}
            onChange={onChooseOffice}
            selectedOptionIdx={officeOptions.findIndex(
              selectedOffice => selectedOffice.payload?.id === office,
            )}
            error={officeError}
          />
        </JustificationContainer>
        <JustificationContainer width="100%" align="center">
          <FieldLabel>{text.userLabel}</FieldLabel>
          <Dropdown
            dataTestId={TEST_ID.USER_DROPDOWN}
            label={text.userDropdownLabel}
            options={userOptions}
            onChange={onChooseUser}
            selectedOptionIdx={
              userOptions.findIndex(selectedUser =>
                user === null
                  ? selectedUser.payload === user
                  : selectedUser.payload?.id === user,
              ) ?? null
            }
            error={userError}
          />
        </JustificationContainer>
      </JustificationContainer>
      <StyledCheckbox
        dataTestId={TEST_ID.OVERWRITE_ASSIGNEE}
        value={overwriteAssignee ?? true}
        onChange={onCheckboxClick}
        label={text.checkboxLabel}
        name={text.checkboxName}
      />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.space('base')};
  `,
);

export default AssignContact;
