import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import DefaultTextEditor from '~/components/organism/DefaultTextEditor';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import useEditorStates from '~/components/organism/PluginsEditor/hooks/useEditorStates';
import serializeAllElements from '~/components/organism/PluginsEditor/utils/serialize';
import convertHtmlToSlateFragment from '~/components/organism/PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import getImagesAsAttachmentInputs from '~/components/organism/PluginsEditor/utils/signatures/getImagesAsAttachmentInputs';
import { Signature, useUpsertSignatureMutation } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useDebounce from '~/hooks/useDebounce';
import useErrorReporter from '~/hooks/useErrorReporter';
import useWizardStep from '~/hooks/useWizardStep';
import { id } from '../..';
import { WizardStep } from '~/components/organism/Wizard/context/WizardContext';
import { Heading3 } from '~/components/atom/Typography';
import { SIGNATURE_EDITOR_BUTTONS } from '~/components/organism/SignatureContainer/constants';

export type Props = {
  /** Account signature */
  signature: Signature;

  /** Current step */
  step: WizardStep;
};

const text = {
  header: 'Handtekening account',
  mutationError:
    'Wijzigingen kunnen niet worden opgeslagen. Probeer het opnieuw. Als het blijft gebeuren, neem contact met ons op of sla deze stap over.',
};

const UpdateSignatureContainer: React.FCC<Props> = ({ signature, step }) => {
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();

  const html = signature.html || '';
  const convertedHtml = useMemo(
    () =>
      convertHtmlToSlateFragment({
        html,
        attachments: signature.attachments || [],
        customElements: [ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE],
      }),
    [html, signature.attachments],
  );
  const { value, onChange } = useEditorStates({
    initialValue: convertedHtml,
  });

  const updatedHtml = useMemo(
    () =>
      serializeAllElements({
        fragment: value,
        customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE],
      }),
    [value],
  );

  const debouncedHtml = useDebounce(updatedHtml, 500);

  const [upsertSignature] = useUpsertSignatureMutation();

  const onBeforeNext = useCallback(
    async outputMap => {
      const updatedAttachments = getImagesAsAttachmentInputs(value);

      const result = await upsertSignature({
        variables: {
          accountId,
          html: debouncedHtml || null,
          attachments: updatedAttachments,
        },
      });

      if (result.errors || !result.data) {
        errorReporter.captureException(
          new Error(JSON.stringify(result.errors || 'No Data')),
          'debug',
        );

        // Caught in the catch block of onBeforeNext. Shows a toast message and persists on the current step
        throw new Error(text.mutationError);
      }

      return outputMap;
    },
    [errorReporter, debouncedHtml, upsertSignature, value, accountId],
  );

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    api.free({
      type: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Heading3 size="base">{text.header}</Heading3>
      <EditorContainer>
        <DefaultTextEditor
          value={value}
          onChange={onChange}
          toolbarButtons={SIGNATURE_EDITOR_BUTTONS}
        />
      </EditorContainer>
    </>
  );
};
const EditorContainer = styled.div<{}>(
  ({ theme }) => css`
    width: 85%;
    border: 1px solid ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius};
    padding: ${theme.space('base')};
  `,
);

export default UpdateSignatureContainer;
