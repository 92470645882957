import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import Button from '~/components/atom/Button';
import CopyCode from '~/components/atom/CopyCode';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Loading from '~/components/atom/Loading';
import { Body, Heading2 } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { useGetFormQuery, useShareMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import hasValue from '~/util/hasValue';

export type Props = {
  onClose: () => void;
} & RouteComponentProps<{ id: string }>;

const getValidToken = (token: string | null | undefined) =>
  hasValue(token) && token !== '-' ? token : null;

const text = {
  header: 'Deel dit formulier',
  tipHeader: 'Hulp nodig?',
  tipBody: (
    <>
      Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link
        to="https://help.dathuis.nl/nl/articles/9984152-delen-van-formulieren"
        target="_blank"
      >
        onze kennisbank
      </Link>{' '}
      of <ChatLink linkText="start een chat met ons." />
    </>
  ),
  description: (
    <>
      Deel dit formulier. Zolang dit formulier wordt gedeeld, kan deze met de
      token worden geimporteerd in een ander account.
    </>
  ),
  shareLabel: 'Formulier delen',
  errorSharingForm:
    'Er is iets fout gegaan tijdens het delen van het formulier, probeer het later opnieuw',

  copyLabel: 'Token kopiëren',

  additionalCloseButton: 'Sluiten',
};

const ShareFormSettings: React.FCC<Props> = ({
  dataTestId,
  id,
  onClose,
  ...rest
}) => {
  const account = useCurrentAccount();
  const { data, loading, updateQuery } = useGetFormQuery({
    variables: {
      accountId: account.id,
      formBuilderId: id as string,
    },
  });
  const [shareForm, { loading: shareLoading, error }] = useShareMutation({});
  const [shared, setShared] = useState(false);
  const addToast = useAddToast();

  useEffect(() => {
    if (data?.getForm) {
      setShared(!isNil(data.getForm.share));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      addToast([formatToastMessage(text.errorSharingForm, 'danger')]);
    }
  }, [addToast, error]);

  const onShare = async () => {
    const { data } = await shareForm({
      variables: {
        accountId: account.id,
        formBuilderId: id,
        active: !shared,
      },
    });

    if (!shareLoading && data) {
      const updatedToken = data.share.token;
      updateQuery(prevResult => ({
        ...prevResult,
        getForm: {
          ...prevResult.getForm,
          share:
            getValidToken(updatedToken) !== null
              ? {
                  __typename: 'ShareToken',
                  token: updatedToken,
                }
              : null,
        },
      }));
    }
  };

  if (loading) {
    return (
      <Overlay onClose={onClose}>
        <Modal>
          <Loading />
        </Modal>
      </Overlay>
    );
  }

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth="800px">
        <JustificationContainer
          direction="column"
          padding={['xl']}
          width="100%"
          data-testid={dataTestId}
          {...rest}
        >
          <JustificationContainer>
            <Heading2>{text.header}</Heading2>
          </JustificationContainer>
          <Body>{text.description}</Body>
          <TipBanner
            id="share-form-tip"
            headerText={text.tipHeader}
            dismissible={false}
            width="100%"
          >
            <Body size="s">{text.tipBody}</Body>
          </TipBanner>

          <JustificationContainer width="100%" direction="column" gap="m">
            <ToggleCheckbox
              label={text.shareLabel}
              containerProps={{
                direction: 'column',
                gap: 'xxxs',
              }}
              value={shared}
              onChange={onShare}
              loading={shareLoading}
            />
            {data?.getForm.share && (
              <CopyCode
                code={data?.getForm.share.token}
                width="100%"
                copyLabel={text.copyLabel}
              />
            )}
          </JustificationContainer>
          <JustificationContainer justification="end" width="100%">
            <Button
              label={text.additionalCloseButton}
              appearance="secondary"
              onClick={onClose}
              icon="check"
            />
          </JustificationContainer>
        </JustificationContainer>
      </Modal>
    </Overlay>
  );
};

export default ShareFormSettings;
