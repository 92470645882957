import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import { isNil } from 'ramda';
import { PlanOptionFragment, BillingCycle } from '~/graphql/types';

import TEST_ID from './index.testid';

import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import { asPriceString } from '~/util/money';
import cleanedFilename from '~/util/cleanedFilename';
import TextButton from '~/components/atom/TextButton';
import BottomSectionContainer from '../components/BottomSectionContainer';
import CouponInput from '../components/CouponInput';
import MidSectionContainer from '../components/MidSectionContainer';
import TopSectionContainer from '../components/TopSectionContainer';
import { animated, useSpring } from 'react-spring';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Coupon } from '~/components/page/Settings/Subscription/utils/convertToCoupon';
import { Body } from '~/components/atom/Typography';

const text = {
  content: 'Content',
  header: 'Facturatie',
  note: '* Alle bedragen zijn excl. BTW',
  nextStep: {
    requiresBilling: 'Ga naar betalen',
    noBillingRequired: 'Aan de slag!',
  },
  invalidCode: 'Ongeldige kortingscode',
  totalPrice: 'Totaal gefactureerd ',
  couponLabel: 'Kortingscode',
};

export const billingCycleOptions = [
  { value: BillingCycle.Monthly, label: 'Maandelijks gefactureerd' },
  { value: BillingCycle.Yearly, label: 'Jaarlijks gefactureerd' },
];

type Props = {
  selectedPlan: PlanOptionFragment;

  initialSelectedBillingCycle: BillingCycle;
  initialSelectedCoupon: Coupon | null;
  initialCouponCode?: string | null;

  onGoBack: (billingCycle: BillingCycle, coupon: Coupon | null) => void;
  onContinue: (billingCycle: BillingCycle, coupon: Coupon | null) => void;
  loading: boolean;
};
const PlanOverviewStep: React.FC<Props> = ({
  selectedPlan,
  onGoBack,
  onContinue,

  initialSelectedCoupon,
  initialCouponCode,
  initialSelectedBillingCycle,

  loading,
}) => {
  const [couponInfo, setCouponInfo] = useState<Coupon | null>(
    initialSelectedCoupon,
  );
  const [showCouponInput, setShowCouponInput] = useState<boolean>(
    !isNil(initialSelectedCoupon) || !isNil(initialCouponCode),
  );

  const styles = useSpring({
    transformOrigin: 'left',
    scale: showCouponInput ? 1 : 0,
    config: {
      tension: 300,
    },
  });
  const [billingCycle, setBillingCycle] = useState(initialSelectedBillingCycle);

  const selectedBillingCycleOption = billingCycleOptions.find(
    option => option.value === billingCycle,
  );

  if (selectedBillingCycleOption == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | selectedBillingCycleOption == null`,
    );
  }
  const labelString = `${selectedPlan.name} ${selectedBillingCycleOption.label}`;

  const price = selectedPlan.price[billingCycle] ?? 0;
  const totalPrice = billingCycle === BillingCycle.Monthly ? price : price * 12;

  let accentComponent: ReactElement | null = null;
  if (billingCycle === BillingCycle.Yearly) {
    accentComponent = (
      <AccentLabel>
        &euro; {(selectedPlan.price.Monthly ?? 0) / 100}
      </AccentLabel>
    );
  }

  const dropdownOptions = (() => {
    const options: Array<OptionOf<BillingCycle>> = [];

    if (!isNil(selectedPlan.price.Yearly)) {
      options.push({
        label: `Jaarlijks - ${asPriceString(
          selectedPlan.price.Yearly,
          true,
        )} per maand`,
        key: BillingCycle.Yearly,
        payload: BillingCycle.Yearly,
      });
    }
    if (!isNil(selectedPlan.price.Monthly)) {
      options.push({
        label: `Maandelijks - ${asPriceString(
          selectedPlan.price.Monthly,
          true,
        )} per maand`,
        key: BillingCycle.Monthly,
        payload: BillingCycle.Monthly,
      });
    }

    return options;
  })();

  return (
    <Container
      align="center"
      direction="column"
      data-testid={TEST_ID.CONTAINER}
    >
      <TopSectionContainer headerText={text.header} withBorder />
      <MidSectionContainer>
        <ContentContainer>
          <StyledRowContainer>
            <Dropdown
              options={dropdownOptions}
              disabled={loading}
              appearance="borderless"
              onChange={({ option }) => {
                setBillingCycle(option.payload);
              }}
              label={
                <DropdownLabel
                  data-testid={TEST_ID.PLAN_CYCLE_LABEL}
                  data-selectedplan={selectedPlan.id}
                >
                  {labelString}
                </DropdownLabel>
              }
              selectedOptionIdx={dropdownOptions.findIndex(
                option => option.payload === billingCycle,
              )}
              dataTestId={TEST_ID.BILLING_CYCLE_DROPDOWN}
            />

            <PriceContainer>
              {accentComponent}
              <Body fontWeight="medium">{asPriceString(price)}</Body>
            </PriceContainer>
          </StyledRowContainer>

          {showCouponInput ? (
            <animated.div style={styles}>
              <CouponInput
                amount={totalPrice}
                initialCouponCode={initialCouponCode}
                couponInfo={couponInfo}
                setCouponInfo={setCouponInfo}
              />
            </animated.div>
          ) : (
            <StyledButton
              dataTestId={TEST_ID.SHOW_COUPON_BUTTON}
              label={text.couponLabel}
              icon="plus"
              margin={['s', null, null, null]}
              onClick={() => setShowCouponInput(true)}
            />
          )}

          <TotalPriceContainer>
            <Body fontWeight="medium">{text.totalPrice}</Body>
            <Body fontWeight="medium" data-testid={TEST_ID.TOTAL_PRICE}>
              {asPriceString(
                totalPrice -
                  (couponInfo != null
                    ? couponInfo.calculateOff(totalPrice)
                    : 0),
              )}
            </Body>
          </TotalPriceContainer>
        </ContentContainer>
      </MidSectionContainer>
      <NoteContainer>{text.note}</NoteContainer>
      <BottomSectionContainer
        nextStepButton={
          selectedPlan.requiresBilling
            ? text.nextStep.requiresBilling
            : text.nextStep.noBillingRequired
        }
        onContinue={() => {
          onContinue(billingCycle, couponInfo);
        }}
        onGoBack={() => {
          onGoBack(billingCycle, couponInfo);
        }}
        loading={loading}
      />
    </Container>
  );
};

const AccentLabel = styled.span<{}>`
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color('accent')};
    font-size: ${theme.fontSize('base')};
    margin-right: ${theme.space('xxs')};

    &:after {
      position: absolute;
      display: inline-block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${theme.color('accent')};
      bottom: 50%;
      left: 0;
      transform: rotate(-15deg);
    }
  `};
`;

const StyledButton = styled(TextButton)<{}>`
  padding-left: 0;
`;

const Container = styled(JustificationContainer)<{}>`
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
`;

const ContentContainer = styled.div<{}>`
  height: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('xl')} 0 ${theme.space('xxxl')} 0;
  `};
`;

const DropdownLabel = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('m')};
    padding-right: ${theme.space('xxs')};
    font-weight: ${theme.fontWeight('medium')};
  `,
);

const PriceContainer = styled.div<{}>`
  width: 200px;
  text-align: end;
`;

const RowContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const TotalPriceContainer = styled(RowContainer)<{}>`
  ${({ theme }) => css`
    padding-top: ${theme.space('m')};
  `};
`;

const StyledRowContainer = styled(RowContainer)<{}>`
  ${({ theme }) => css`
    padding-bottom: ${theme.space('xxxl')};
    border-bottom: 1px solid ${theme.color('tertiary')};
    margin-top: ${theme.space('m')};
  `};
`;

const NoteContainer = styled.div<{}>`
  align-self: flex-start;

  ${({ theme }) => css`
    padding: ${theme.space('l')} ${theme.space('xxxxl')};
    margin-top: ${theme.space('xxl')};
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text', 'light')};
  `};
`;

export default PlanOverviewStep;
