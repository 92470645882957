import { type RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Canvas from './components/Canvas';
import withReactFlowProvider from '~/hocs/withReactFlowProvider';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formState as formStateAtom, initialFormAtom } from './state';
import InteractionHandler from './components/InteractionHandler';
import Loading from '~/components/atom/Loading';
import { useGetFormQuery, type GetFormQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useResetFormBuilder from './hooks/useResetFormBuilder';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ControlComponents from './components/ControlComponents';
import NotFound from '~/components/page/404';
import { REACT_FLOW_PANE } from '~/components/page/Automation/v2/components/Builder/constants/reactFlowLayers';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import { changesState } from './state/changes';
import { autoGeneratedEvent } from './state/autoGeneratedEvent';
import createPageTitle from '~/util/createPageTitle';

const text = {
  title: 'Formulieren',
  emptyStateHeader: 'Je hebt nog geen formulieren',
  emptyStateDescription:
    'Verzamel informatie op een manier die het beste werkt voor jou',
  addFormLabel: 'Nieuw formulier',
};

export const LINK_PREFIX = '/-/forms';

type Props = {
  id: string;
  data: GetFormQuery['getForm'];
};

const FormBuilderPage: React.FCC<Props> = ({ data, id }) => {
  const setInitialForm = useSetRecoilState(initialFormAtom);
  const setAutoGeneratedEvent = useSetRecoilState(autoGeneratedEvent);
  const [formState, setFormState] = useRecoilState(formStateAtom);

  const handle = useFullScreenHandle();
  const toggleFullScreen = () => {
    if (handle.active) {
      return handle.exit();
    }
    return handle.enter();
  };
  const resetAllStates = useResetFormBuilder();

  const changes = useRecoilValue(changesState);

  useEffect(() => {
    if (formState === null) {
      setFormState(data);
      setInitialForm(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, data]);

  useEffect(
    () => () => {
      resetAllStates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    // Update mappings and event when nodes are added or deleted
    setAutoGeneratedEvent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.nodes.length]);

  const hasChanges = changes?.hasChanges || false;
  const isFullScreen = handle.active;

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(text.title)}</title>
      </MetaTags>
      <Container isFullScreen={isFullScreen}>
        <FullScreen className="fullscreen" handle={handle}>
          <div id="fullscreen-modal" key="fullscreen" />

          <ControlComponents
            id={id}
            formState={formState}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
            hasChanges={hasChanges}
          />
          {formState === null ? (
            <Loading />
          ) : (
            <Canvas nodes={formState?.nodes ?? []} />
          )}
          <InteractionHandler />
        </FullScreen>
      </Container>
    </>
  );
};

const FormBuilderDataLoader: React.FC<
  RouteComponentProps<{ id: string }>
> = props => {
  const account = useCurrentAccount();
  const { data, loading } = useGetFormQuery({
    variables: {
      accountId: account.id,
      formBuilderId: props.id as string,
    },
  });

  if (!props.id) return <NotFound />;
  if (loading) return <Loading />;

  if (!data) return <AppErrorScreen />;

  return <FormBuilderPage id={props.id} data={data.getForm} />;
};

const Container = styled.div<{ isFullScreen: boolean }>(
  ({ theme, isFullScreen }) => css`
    position: relative;

    .fullscreen {
      background: ${isFullScreen && theme.color('white', 'dark')};
      height: 100%;
    }

    ${REACT_FLOW_PANE} {
      cursor: grab;
    }

    .react-flow__attribution {
      display: none;
    }
  `,
);

export default withReactFlowProvider(FormBuilderDataLoader);
