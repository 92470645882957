import React from 'react';

import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import TEST_ID from './index.testid';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import { SortDirection } from '~/graphql/types';
import EnabledStatusIndicator from '~/components/atom/EnabledStatusIndicator';
import { useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';
import { navigate } from '@gatsbyjs/reach-router';

export type SortToggleFunction = (
  sortField: Array<string>,
  sortDirection: SortDirection | null,
) => void;

const formListColumns = (
  sortToggle: SortToggleFunction,
  _version: string,
): Array<TableHeaderDefinitionCellType<any>> => {
  const columns = [
    TableHeaderCell.string({
      label: 'Naam',
      dataProperty: 'name',
      width: 100,
      dataTestId: TEST_ID.NAME,
      sorting: {
        onSort: sortDirection => sortToggle(['name'], sortDirection),
      },
    }),

    TableHeaderCell.string({
      label: 'Description',
      dataProperty: 'formDescription',
      width: 200,
      dataTestId: TEST_ID.DESCRIPTION,
      sorting: {
        onSort: sortDirection => sortToggle(['formDescription'], sortDirection),
      },
    }),

    TableHeaderCell.custom({
      label: 'Gedeeld',
      dataProperty: 'shared',
      width: 50,
      sorting: {
        onSort: sortDirection =>
          sortToggle(['shared', 'enabled'], sortDirection),
      },
      dataTestId: TEST_ID.SHARED_COLUMN,
      renderFunction: (value: { enabled: boolean; id: string }) => (
        <ShareIndicator
          shared={value.enabled}
          onClick={() => navigate(`/-/forms/sharing/${value.id}`)}
        />
      ),
    }),

    TableHeaderCell.boolean({
      label: 'Status',
      dataProperty: 'enabled',
      width: 50,
      sorting: {
        onSort: sortDirection => sortToggle(['enabled'], sortDirection),
      },
      renderFunction: value => <EnabledStatusIndicator value={value} />,
      dataTestId: TEST_ID.ENABLED,
    }),

    TableHeaderCell.action({
      dataTestId: TEST_ID.ACTION,
    }),
  ];

  return columns;
};

const ShareIndicator: React.FC<{ shared: boolean; onClick: () => void }> = ({
  shared,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <JustificationContainer
      width="100%"
      justification="start"
      align="center"
      gap="s"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
    >
      <Icon
        name="share"
        color={theme.color(shared ? 'secondary' : 'tertiary')}
        size="m"
      />
      <Label size="base" margin={[null]} textDecoration="underline">
        {shared ? 'Instellingen' : 'Delen'}
      </Label>
    </JustificationContainer>
  );
};

export default formListColumns;
