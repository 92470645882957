import { isEmpty, isNil } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ModalTemplate from '../../components/ModalTemplate';
import { AppSetupModalProps } from '../..';

import CustomMarkdown from '../../components/CustomMarkdown';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import FullWidthButton from '~/components/atom/FullWidthButton';
import TermsOfUse from '~/components/organism/TermsOfUse';
import SelectProduct from '../../components/SelectProduct';
import { Heading5 } from '~/components/atom/Typography';
import type { Option } from '~/components/molecule/Dropdown';
import AnimatedCheckbox from '~/components/molecule/AnimatedCheckbox';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  activate: 'Activeren',
  cancel: 'Annuleer',
  termsOfUseCheckbox: 'Ik ga akkoord met de gebruiksvoorwaarden',
};

enum SetupStep {
  Overview = 'overview',
  Terms = 'terms&conditions',
}

export type Props = AppSetupModalProps & {
  /** Disables all interaction */
  disabled: boolean;
};

const AppDisabled: React.FCC<Props> = ({
  app,
  disabled,
  onOAuthCall,
  onChangeAppState,
  onClose,
}) => {
  const [step, setStep] = useState<SetupStep>(SetupStep.Overview);
  const [selectedProduct, setSelectedProduct] = useState<Option | null>(
    'productOptions' in app ? app.productOptions[0] : null,
  );

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const { description, termsOfUse } = app;
  const activateApp = async () => {
    setLoading(true);

    if (app.setupFlow === 'oAuth' && onOAuthCall)
      return onOAuthCall({
        onError: () => setLoading(false),
      });

    const onChangeOptions =
      app.setupFlow === 'selectProduct' && !isNil(selectedProduct)
        ? { selectedProduct }
        : undefined;

    await onChangeAppState(onChangeOptions);

    onClose();

    setLoading(false);
  };

  const hasTerms = !isNil(termsOfUse) && !isEmpty(termsOfUse);
  if (step === SetupStep.Terms) {
    return (
      <ModalTemplate
        data={app}
        onClose={onClose}
        dataTestId={TEST_ID.CONTAINER}
        main={
          <>
            <TermsContainer>
              {app.setupFlow === 'selectProduct' && (
                <>
                  <Heading5 color={{ group: 'text' }}>Type rapport</Heading5>
                  <SelectProduct
                    options={app.productOptions}
                    selectedOption={selectedProduct}
                    onSelect={option => {
                      setSelectedProduct(() => option);
                    }}
                  />
                </>
              )}

              <TermsOfUse
                termsOfUseText={<CustomMarkdown value={termsOfUse} />}
                dataTestId={TEST_ID.TEXT_AREA}
              />
              <JustificationContainer
                width="100%"
                margin={['base', null, null, null]}
              >
                <AnimatedCheckbox
                  selectable
                  value={agreedToTerms}
                  onChange={() => setAgreedToTerms(prev => !prev)}
                  label={text.termsOfUseCheckbox}
                  dataTestId={TEST_ID.TERMS_CHECKBOX}
                />
              </JustificationContainer>
            </TermsContainer>
            <BottomBar>
              <Button
                label={text.cancel}
                onClick={() => setStep(SetupStep.Overview)}
                size="medium"
                ghost
              />
              <Button
                label={text.activate}
                onClick={activateApp}
                icon="arrow-down-circle"
                appearance="secondary"
                size="medium"
                disabled={
                  !agreedToTerms ||
                  (app.setupFlow === 'selectProduct' &&
                    isNil(selectedProduct)) ||
                  disabled ||
                  loading
                }
                loading={loading}
                dataTestId={TEST_ID.ACTIVATE_BUTTON}
              />
            </BottomBar>
          </>
        }
      />
    );
  }

  return (
    <ModalTemplate
      data={app}
      onClose={onClose}
      main={<CustomMarkdown value={description} />}
      aside={
        <FullWidthButton
          label={text.activate}
          onClick={() => {
            if (hasTerms) return setStep(SetupStep.Terms);
            return activateApp();
          }}
          icon="arrow-down-circle"
          appearance="secondary"
          size="medium"
          disabled={disabled || loading}
          loading={loading}
          dataTestId={TEST_ID.ACTIVATE_BUTTON}
        />
      }
    />
  );
};

const TermsContainer = styled.div<{}>`
  flex-grow: 2;
`;

const BottomBar = styled.div<{}>(
  () => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
);

export default AppDisabled;
