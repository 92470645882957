import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { IbanElement } from '@stripe/react-stripe-js';

import { Theme } from '~/theme';

import FloatingLabel from '~/components/atom/FloatingLabel';

import TEST_ID from './index.testid';

const text = {
  ibanLabel: 'IBAN',
  emptyField: 'Het opgegeven IBAN is onvolledig.',
};
type Props = {
  elements: any;
  currentLast4?: string | null;
  onChange?: () => void;
};
const IBANElement: React.FCC<Props> = ({
  elements,
  currentLast4,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const theme: Theme = useTheme();

  let label = '';
  if (errorMsg != null) {
    label = errorMsg;
  } else {
    if (!isFocused && currentLast4 != null && currentLast4.length === 4) {
      label = `**** **** **** **${currentLast4.substring(
        0,
        2,
      )} ${currentLast4.substring(2, 4)}`;
    } else {
      label = text.ibanLabel;
    }
  }

  /**
   * Testing if this floating label is making it difficult ?!
   */
  return (
    <Container>
      <FloatingLabel
        actAsPlaceholder={!isFocused && !errorMsg}
        error={errorMsg}
        dataTestId={TEST_ID.IBAN_FIELD_LABEL}
      >
        {label}
      </FloatingLabel>
      <IbanFieldContainer data-testid={TEST_ID.IBAN_FIELD}>
        <IbanElement
          id="iban-element"
          options={IBAN_ELEMENT_OPTIONS(theme, isFocused)}
          onChange={event => {
            if (event?.error != null) {
              setErrorMsg(event.error.message);
            } else {
              setErrorMsg(event.empty ? text.emptyField : null);
            }

            if (onChange) onChange();
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            const ibanElement = elements.getElement(IbanElement);

            setIsFocused(ibanElement._empty ? false : true);

            if (ibanElement._empty) {
              setErrorMsg(text.emptyField);
            }
          }}
        />
      </IbanFieldContainer>
    </Container>
  );
};

const IBAN_ELEMENT_OPTIONS = (theme: Theme, isFocused: boolean) => ({
  supportedCountries: ['SEPA'],
  style: {
    base: {
      color: theme.color('text'),
      fontFamily: 'inherit',
      fontSmoothing: 'antialiased',
      fontSize: theme.remToPx(theme.fontSize('base')),
      '::placeholder': {
        color: isFocused ? theme.color('text', 'light') : 'transparent',
      },
      ':-webkit-autofill': {
        color: theme.color('text'),
      },
      iconColor: isFocused ? theme.color('text', 'light') : 'transparent',
    },
    invalid: {
      color: theme.color('danger'),
      iconColor: theme.color('danger'),
      ':-webkit-autofill': {
        color: theme.color('danger'),
      },
    },
  },
  placeholderCountry: 'NL',
});

const Container = styled.div<{}>`
  width: 100%;
`;

const IbanFieldContainer = styled.div<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xs')};
  `,
);

export default IBANElement;
