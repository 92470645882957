import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import type { CommonNodeData } from '../../..';
import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH } from '../..';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { FormBuilder_ScreenNode } from '~/graphql/types';
import ScreenPreview from '../../../../InteractionHandler/components/EditNodeScreen/components/ScreenPreview';
import BrowserFrame from '~/components/molecule/BrowserFrame';
import CardMenu from '../../../../CardMenu';
import { isNil } from 'ramda';
import { interactionState } from '../../../../../state';
import { nodeById } from '../../../../../state/nodesAndEvents';
import { issuesByPath } from '../../../../../state/issues';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';
import { submitScreenState } from '../../../../../state/submitScreen';
import { SUBMIT_SCREEN_TITLE } from '../../../../InteractionHandler/components/EditNodeScreen/constants';

type NodeData = CommonNodeData & {
  value: number;
};

const ScreenNode: React.FC<NodeProps<NodeData>> = ({ isConnectable, id }) => {
  const node = useRecoilValue(nodeById(id)) as FormBuilder_ScreenNode;
  const [, setInteraction] = useRecoilState(interactionState);
  const issues = useRecoilValue(issuesByPath([id]));
  const submitScreen = useRecoilValue(submitScreenState);

  if (isNil(node)) return null;

  const nodeName = node.name;
  return (
    <>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      {submitScreen?.id === id && (
        <IconContainer align="center" gap="xs">
          <Label>{SUBMIT_SCREEN_TITLE}</Label>
          <Icon name="send" size="m" />
        </IconContainer>
      )}
      <CardMenu
        nodeId={node.id}
        nodeType="FormBuilder_ScreenNode"
        isSubmitScreenNode={!isNil(submitScreen) && submitScreen?.id === id}
      />
      <StyledBrowserFrame
        $hasError={issues.length !== 0}
        onClick={() => {
          setInteraction({
            interactionType: 'edit-node-screen',
            subjectId: id,
          });
        }}
        name={nodeName}
      >
        <PreviewContainer>
          <ScreenPreview
            node={node}
            width={`${DEFAULT_NODE_WIDTH * 2}px`}
            height={`${DEFAULT_NODE_HEIGHT * 2}px`}
          />
        </PreviewContainer>
      </StyledBrowserFrame>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
    </>
  );
};

const PreviewContainer = styled.div`
  pointer-events: none;

  iframe {
    transform: scale(0.5, 0.5) translate(-50%, -50%);
  }
`;

const StyledBrowserFrame = styled(BrowserFrame)<{ $hasError: boolean }>(
  ({ theme, $hasError }) => css`
    width: ${DEFAULT_NODE_WIDTH}px;
    height: calc(${DEFAULT_NODE_HEIGHT + 'px'});
    transition: outline 0.1s ease;
    cursor: pointer;
    outline: 2px solid transparent;

    ${$hasError
      ? css`
          box-shadow: ${theme.boxShadow('aroundDanger')};
          outline: 2px solid ${theme.color('danger')} !important;
        `
      : ''}

    &:hover {
      outline: 2px solid ${theme.color('primary')};
    }
  `,
);

const IconContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    position: absolute;
    top: -${theme.space('l')};
    right: 0;
  `,
);

export default ScreenNode;
