import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Tooltip from '~/components/molecule/Tooltip';
import useHover from '~/components/bad/util/useHover';

export type Props = {
  dataTestId?: string;
};

const text = {
  requiredField: 'Dit veld is verplicht',
};

const withRequiredMark = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  args?: P,
) => {
  // To show nice name in React Dev Tools
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithTooltip: React.FCC<Props & P> = ({
    dataTestId,
    ...rest
  }) => {
    const [showTooltip, tooltipProps] = useHover();
    return (
      <JustificationContainer
        justification="start"
        nonResponsive
        gap="xxxs"
        align="center"
      >
        <WrappedComponent dataTestId={dataTestId} {...args} {...(rest as P)} />
        <>
          <RequiredMark {...tooltipProps}>*</RequiredMark>
          {showTooltip && (
            <Tooltip padding={['xxs']}>{text.requiredField}</Tooltip>
          )}
        </>
      </JustificationContainer>
    );
  };

  ComponentWithTooltip.displayName = `withRequiredMark(${displayName})`;
  return ComponentWithTooltip;
};

const RequiredMark = styled.sup(
  ({ theme }) => css`
    color: ${theme.color('accent')};
    vertical-align: top;
    font-size: 1.5em;
    cursor: pointer;
    top: 0;
  `,
);

export default withRequiredMark;
