import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading2 } from '~/components/atom/Typography';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Input from '~/components/molecule/Input';
import Button from '~/components/atom/Button';
import { useImportFormMutation } from '~/graphql/types';
import TextButton from '~/components/atom/TextButton';
import Catalog from '~/Catalog';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import isValidToken from './utils/isValidToken';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Link from '~/components/molecule/Link';

export type Props = {
  onClose: () => void;
} & RouteComponentProps;

const text = {
  header: 'Importeer een gedeeld formulier',
  tipHeader: 'Hulp nodig?',
  tipBody: (
    <>
      Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link
        to="https://help.dathuis.nl/nl/articles/9984152-delen-van-formulieren"
        target="_blank"
      >
        onze kennisbank
      </Link>{' '}
      of <ChatLink linkText="start een chat met ons." />
    </>
  ),
  description: (
    <>
      Importeer een formulier. Gebruik de token van het formulier dat met jou is
      gedeeld.
    </>
  ),
  tokenLabel: 'Plak token',
  formDescriptionLabel: 'Omschrijving',
  formNameLabel: 'Naam',
  overwriteSettings: 'Zelf een naam en omschrijving van dit formulier opgeven',

  submitLabel: 'Formulier importeren',

  invalidToken: 'Deze token is niet geldig',
  formNotSharedError: 'Dit formulier lijkt niet gedeeld te zijn.',

  importSuccess: 'Formulier geïmporteerd!',
  goToFormLabel: 'Ga nu naar het formulier',
};

const ImportForm: React.FCC<Props> = ({ dataTestId, onClose, ...rest }) => {
  const account = useCurrentAccount();
  const [importData, setImportData] = useState<{
    name: string;
    description: string;
    token: string;
  }>({
    name: '',
    description: '',
    token: '',
  });
  const [overwriteExpanded, setOverwriteExpanded] = useState(false);
  const [importForm, { loading, error: importError }] = useImportFormMutation(
    {},
  );
  const [error, setError] = useState<null | string>(null);
  const addToast = useAddToast();

  const onSubmit = async () => {
    const { data } = await importForm({
      variables: {
        accountId: account.id,
        token: importData.token,
        overwrite: {
          name: importData.name === '' ? undefined : importData.name,
          description:
            importData.description === '' ? undefined : importData.description,
        },
      },
    });

    if (data && data.copyForm.id) {
      addToast([
        formatToastMessage(text.importSuccess, 'success', {
          label: text.goToFormLabel,
          to: `/-/forms/${data.copyForm.id}`,
        }),
      ]);
      return onClose();
    }
  };

  useEffect(() => {
    if (importError && importError.message.includes('Unauthorised')) {
      setError(text.formNotSharedError);
    } else if (importError) {
      setError(Catalog.genericUnknownErrorMessage);
    }
  }, [importError]);

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth="800px">
        <JustificationContainer
          direction="column"
          padding={['xl']}
          width="100%"
          data-testid={dataTestId}
          {...rest}
        >
          <JustificationContainer>
            <Heading2>{text.header}</Heading2>
          </JustificationContainer>
          <Body>{text.description}</Body>
          <TipBanner
            id="share-form-tip"
            headerText={text.tipHeader}
            dismissible={false}
          >
            <Body size="s">{text.tipBody}</Body>
          </TipBanner>
          <JustificationContainer
            direction="column"
            width="100%"
            gap="m"
            margin={[null, null, 'm', null]}
          >
            <Input
              width="100%"
              externalErrors={error ? [error] : []}
              label={{ text: text.tokenLabel }}
              validation={[
                val => (isValidToken(val) ? true : text.invalidToken),
              ]}
              placeholder="xxxxxx-xxxx-xxx-xxx-xxxxxxx"
              onChange={event => {
                setError(null);
                setImportData(prev => ({ ...prev, token: event.target.value }));
              }}
              value={importData.token}
            />
            <CollapsibleChevronBlock
              header={text.overwriteSettings}
              isOpen={overwriteExpanded}
              onToggle={() => {
                setOverwriteExpanded(prev => !prev);
                // if true it will be false on next update so we clear the fields
                if (overwriteExpanded === true) {
                  setImportData(prev => ({
                    ...prev,
                    name: '',
                    description: '',
                  }));
                }
              }}
            >
              <JustificationContainer width="100%" direction="column" gap="m">
                <Input
                  width="100%"
                  label={{ text: text.formNameLabel }}
                  onChange={event => {
                    setImportData(prev => ({
                      ...prev,
                      name: event.target.value,
                    }));
                  }}
                  value={importData.name}
                />
                <Input
                  width="100%"
                  label={{ text: text.formDescriptionLabel }}
                  onChange={event =>
                    setImportData(prev => ({
                      ...prev,
                      description: event.target.value,
                    }))
                  }
                  value={importData.description}
                />
              </JustificationContainer>
            </CollapsibleChevronBlock>
          </JustificationContainer>
          <JustificationContainer width="100%" justification="space-between">
            <TextButton
              label={Catalog.cancel}
              onClick={onClose}
              disabled={loading}
            />
            <Button
              label={text.submitLabel}
              onClick={onSubmit}
              size="medium"
              disabled={!isValidToken(importData.token)}
              loading={loading}
            />
          </JustificationContainer>
        </JustificationContainer>
      </Modal>
    </Overlay>
  );
};

export default ImportForm;
