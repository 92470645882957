import { isEmpty, omit } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';
import Loading from '~/components/atom/Loading';
import {
  OutputFieldColor,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { OutputMap } from '~/components/organism/WizardSteps';
import {
  App_ValuationReport,
  useGetAppValuationReportsQuery,
  useUpdateAppValuationReportMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import ColorInput from '~/components/molecule/ColorInput';

export const id = 'ValueReportColorSettings';
export const title = 'Waarderapport kleurinstellingen';

type ColorSettings = Pick<
  App_ValuationReport['general'],
  'accentColor' | 'backgroundColor' | 'headerTextColor' | 'mainColor'
>;

type ColorProperty = keyof ColorSettings;
type ColorPair = Record<ColorProperty, string>;

export type OutputType = {
  type: typeof id;
  accentColor: OutputFieldColor;
  backgroundColor: OutputFieldColor;
  headerTextColor: OutputFieldColor;
  mainColor: OutputFieldColor;
};

export const colorsMap: ColorPair = {
  accentColor: 'Accentkleur',
  backgroundColor: 'Achtergrondkleur',
  headerTextColor: 'Koptekstkleur',
  mainColor: 'Hoofdkleur',
};

export const colorProperties: Array<ColorProperty> = [
  'accentColor',
  'backgroundColor',
  'headerTextColor',
  'mainColor',
];

export const initialColors = {
  accentColor: '#2b4162',
  backgroundColor: '#75b8c8',
  headerTextColor: '#fff',
  mainColor: '#ee6055',
};

export const valueReportInitialColorSettingsState: OutputType = {
  type: id,
  accentColor: {
    category: 'Kleuren',
    type: 'color',
    value: initialColors.accentColor,
    label: 'Accentkleur',
  },
  mainColor: {
    category: 'Kleuren',
    type: 'color',
    value: initialColors.mainColor,
    label: 'Hoofdkleur',
  },
  backgroundColor: {
    category: 'Kleuren',
    type: 'color',
    value: initialColors.backgroundColor,
    label: 'Achtergrondkleur',
  },
  headerTextColor: {
    category: 'Kleuren',
    type: 'color',
    value: initialColors.headerTextColor,
    label: 'Koptekstkleur',
  },
};

const text = {
  successToastMessage: 'Kleurinstellingen succesvol opgeslagen',
  errorToastMessage:
    'Er is iets mis gegaan bij het opslaan, probeer het later nog eens',
  category: 'Kleuren',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const [currentOutput, setCurrentOutput] = useState(
    outputMap[id] as OutputType,
  );
  const getColorsInput = (output: OutputType): ColorSettings =>
    colorProperties.reduce(
      (acc, curr) => {
        if (curr in output && !isEmpty(output[curr])) {
          acc[curr] = output[curr].value;
        } else {
          acc[curr] = initialColors[curr];
        }

        return acc;
      },
      {
        accentColor: '',
        backgroundColor: '',
        headerTextColor: '',
        mainColor: '',
      },
    );

  const { data: getAppValuationReportsData, loading } =
    useGetAppValuationReportsQuery({
      variables: {
        accountId,
      },
    });
  const [updateAppValuationReport] = useUpdateAppValuationReportMutation();
  const firstAddedReport =
    getAppValuationReportsData?.getAppValuationReports[0];

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedColors = outputMap[id] as OutputType;

      if (firstAddedReport && 'general' in firstAddedReport) {
        const formattedReport = omit(
          ['__typename', 'id', 'accountId', 'officeId'],
          firstAddedReport,
        );

        const colorsInput = getColorsInput(updatedColors);
        const update = {
          ...formattedReport,
          general: {
            ...omit(['__typename', 'backgroundImage'], formattedReport.general),
            ...colorsInput,
          },
        };

        try {
          await updateAppValuationReport({
            variables: {
              accountId,
              id: firstAddedReport.id,
              update,
            },
          });
        } catch {
          throw new Error(text.genericErrorMessage);
        }
      }

      const output: OutputType = {
        ...updatedColors,
        accentColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.accentColor.value,
          label: colorsMap['accentColor'],
        },
        mainColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.mainColor.value,
          label: colorsMap['mainColor'],
        },
        backgroundColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.backgroundColor.value,
          label: colorsMap['backgroundColor'],
        },
        headerTextColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.headerTextColor.value,
          label: colorsMap['headerTextColor'],
        },
      };

      return output;
    },
    [accountId, firstAddedReport, updateAppValuationReport],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    // Step is free to go on mount
    api.free(currentOutput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput]);

  if (loading) {
    <LoadingContainer>
      <Loading />
    </LoadingContainer>;
  }

  return (
    <SettingsContainer>
      {colorProperties.map(property => (
        <ColorInput
          key={property}
          value={currentOutput[property].value}
          onChange={(value: string) => {
            setCurrentOutput(prevOutput => ({
              ...prevOutput,
              [property]: {
                category: text.category,
                type: 'color',
                value: value,
                label: colorsMap[property],
              },
            }));
          }}
          label={colorsMap[property]}
        />
      ))}
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.space('base')};
    grid-row-gap: ${theme.space('s')};
  `,
);

const LoadingContainer = styled.div<{}>`
  display: flex;
`;

export default {
  id,
  title,
};
