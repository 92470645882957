import React from 'react';
import styled, { css } from 'styled-components';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

type Props = {
  headerText: string | React.ReactNode;
  withBorder: boolean;
  descriptionText?: string | React.ReactNode;
};
const TopSectionContainer: React.FCC<Props> = ({
  children,
  headerText,
  descriptionText,
  withBorder,
}) => (
  <Container withBorder={withBorder}>
    <HeaderContainer>{headerText}</HeaderContainer>
    <DescriptionContainer>{descriptionText}</DescriptionContainer>
    {children}
  </Container>
);

type ContainerProps = {
  withBorder: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120px;

  ${({ theme, withBorder }) => {
    if (withBorder) {
      return css`
        border-bottom: 1px solid ${theme.color('tertiary')};
        padding: ${theme.space('l')};
      `;
    }

    return '';
  }};
`;

const HeaderContainer = styled.div<{}>`
  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    color: ${theme.color('primary')};
    font-size: ${FONT_SIZE_OF_18}px;
    margin-top: ${theme.space('s')};
  `};
`;

const DescriptionContainer = styled.div<{}>`
  text-align: center;
  ${({ theme }) => css`
    margin-top: ${theme.space('m')};
  `};
`;

export default TopSectionContainer;
