import { isNil } from 'ramda';

// Warnings
export const SNOOZED_WARNINGS = 'dhStashWarnings';

// Registration/Subscription
export const SIGNUP_PARAMS = 'signupParams';
export const TAPFILLIATE_REF = 'tapfilliateRef';
export const SIGNUP_ID = 'signupId';

// UTM management
export const UTM_STORAGE_PREFIX = 'dhUTMStorage_';

// Account
export const SELECTED_ACCOUNT_STORAGE_KEY = 'dhSelectedAccountId';
export const getSignatureKey = (accountId: string): string =>
  `dhDefaultSignature:${accountId}`;

// admin_Accounts
export const ACCOUNT_ID_BEFORE_IMPERSONATION = 'dhAccountIdBeforeImpersonation';

// Task list
export const TASK_LIST_FILTER_USER_ID = 'dhTaskListFilterUserId';
export const TASK_LIST_FILTER_OFFICE_ID = 'dhTaskListFilterOfficeId';
export const TASK_LIST_FILTER_LIST_TYPE = 'dhTaskListFilterType';

// Contact list
export const CONTACT_LIST_FILTER_USER_ID = 'dhContactListFilterUserId';
export const CONTACT_LIST_FILTER_OFFICE_ID = 'dhContactListFilterOfficeId';

// All filters
export const CONTACT_LIST_FILTERS = 'dhContactListFilters';
export const CONTACT_LIST_FILTERSV2 = 'dhContactListFiltersV2';

// Sort settings
export const CONTACT_SORT_SETTINGS = 'contactSortSettings';
export const FLOW_SORT_SETTINGS = 'flowSortSettings';

// Widget
export const WIDGET_PREVENT_DEFAULT_OPENING = 'widgetPreventDefaultOpening';

const NULL_VALUE = '@@null-value@@';
// undefined means it was not set, null is an actual value of null
export const getLocalStorageItem = (key: string): string | null | undefined => {
  const item = window.localStorage.getItem(key);

  if (item === NULL_VALUE) {
    return null;
  }

  if (item == null) {
    return undefined;
  }

  return item;
};

export const setLocalStorageItem = (key: string, value: string | null) => {
  window.localStorage.setItem(key, value || NULL_VALUE);
};

export const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
};

export const hasLocalStorageItem = (key: string): boolean => {
  const item = getLocalStorageItem(key);
  return !isNil(item);
};
