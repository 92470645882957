import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';

import {
  Address,
  Office_Update,
  Signature,
  useUpdateOfficeMutation,
} from '~/graphql/types';
import { ImgDefinition, Mailboxes } from '~/graphql/types.client.flow';
import type { ListItemForDeletion } from '~/components/bad/Modals/components/ListItemCard';

import OfficeDetailForm from './OfficeDetailForm';
import Catalog from '~/Catalog';
import SynchronisedMailboxes from '../../components/SynchronisedMailboxes';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import {
  ActionButtons,
  Header,
} from '~/components/page/Settings/Profile/Profile.style';
import DetailsActionList from '~/components/page/DetailsPage/DetailsActionList';
import DeleteOfficeModal from '~/components/bad/Modals/DeleteOfficeModal';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import SignatureContainer from '~/components/organism/SignatureContainer';
import formatToastMessage from '~/util/formatToastMessage';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import useAddToast from '~/hooks/useAddToast';
import usePermissions from '~/hooks/usePermissions';
import Validation from '~/util/Validation';
import TextButton from '~/components/atom/TextButton';
import ErrorModal from '~/components/template/ErrorModal';
import JustificationContainer from '~/components/atom/JustificationContainer';
import OfficeProfileHeader from '~/components/organism/ProfileHeader/components/OfficeProfileHeader';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import createPageTitle from '~/util/createPageTitle';

const text = {
  imageUploadError: Catalog.imageUploadErrorMessage,
  officeUpdateError: Catalog.genericUnknownErrorMessage,
  success: 'Wijzigingen opgeslagen',
  error: 'Er is iets mis gegaan bij het opslaan, probeer het later nog eens.',
  userList: 'Gebruikerslijst',
  officesListLink: 'Vestigingen',
  deleteOffice: 'Verwijder vestiging',
  errorModalTitle: 'Verwijderen niet mogelijk',
  errorModalMessage: 'Een account moet minimaal één vestiging hebben.',
  emailIntegration: 'E-mailintegratie',
  signature: 'Handtekening vestiging',
  mayEditExplanation:
    'Alleen accountbeheerders en vestigingbeheerders kunnen e-mails synchroniseren.',
  workingAreas: 'Werkgebieden',
  buttonLabel: 'Instellen',
  workingAreaHeader: 'Werkgebied definiëren',
  workingAreaDescription:
    'Stel je werkgebied in, om contacten automatisch aan deze vestiging toe te wijzen.',
};

export type UpdateOfficeType = {
  id: string;
  name: string;
  email: string | null;
  phone: string | null;
  img: ImgDefinition | null;
  address: Omit<Address, 'countryCode'> | null;
};
type Props = {
  accountId: string;
  office: UpdateOfficeType;
  mailboxes: Mailboxes;
  canEdit: boolean;

  /** List of users to delete from the office */
  officeUsersList: Array<ListItemForDeletion>;

  /** Number of offices in account */
  officesLength: number;
  /** Has defined at least one working area */
  hasWorkingAreas: boolean;
  mayDeleteOffice: boolean;
  signature: Signature | null;

  /** Refetch getOffice query and session hydration */
  refetch: () => Promise<any>;

  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
};

const UpdateOfficeComponent: React.FCC<Props> = ({
  office,
  canEdit,
  accountId,
  startPolling,
  stopPolling,
  mailboxes,
  officeUsersList,
  officesLength,
  hasWorkingAreas,
  mayDeleteOffice,
  signature,
  refetch,
}) => {
  const addToast = useAddToast();
  const hasEmailPermission = usePermissions(['root.email.office']);

  const [showDeleteOfficeModal, setShowDeleteOfficeModal] =
    useState<Array<ListItemForDeletion> | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const onImageUploadError = () =>
    addToast([formatToastMessage(text.imageUploadError, 'danger')]);

  const onOfficeUpdateError = () =>
    addToast([formatToastMessage(text.officeUpdateError, 'danger')]);

  const handleDeleteOfficeModalAction = (
    usersList: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteOfficeModal(usersList);
  };

  const { phone, email, img, id, address, name } = office;
  const { addition, city, houseNumber, postcode, street } = address || {};
  const filteredMailboxes = mailboxes.filter(
    mailbox =>
      mailbox.__typename === 'OfficeMailbox' ||
      mailbox.__typename === 'OfficeMailboxAlias',
  );

  const [updateOffice, { loading: mutationLoading }] = useUpdateOfficeMutation({
    onCompleted: () => {
      addToast([formatToastMessage(text.success, 'success')]);
      void refetch();
    },
    onError: () => onOfficeUpdateError(),
  });

  const updateOfficeName = (name: string) =>
    updateOffice({
      variables: {
        accountId,
        officeId: id,
        update: {
          name,
        },
      },
    });

  const onFormSubmit = (changedFields: $Object) => {
    const { street, postcode, city, houseNumber, addition, email, phone } =
      changedFields;

    const update: Office_Update = {
      // mutation only updates the defined fields so if we send null it resets the email field
      email: email ? email.toLocaleLowerCase() : undefined,
      phone,
      address: undefined,
    };

    if (
      Validation.String.isNonEmptyString(street) ||
      Validation.String.isNonEmptyString(postcode) ||
      Validation.String.isNonEmptyString(city) ||
      houseNumber != null ||
      addition !== undefined
    ) {
      update.address = {
        street,
        postcode,
        city,
        // can be set to null
        addition,
        houseNumber,
      };
    }

    return updateOffice({
      variables: {
        accountId,
        officeId: id,
        update,
      },
    });
  };

  return (
    <ContentContainerDefault
      data-testid="office-details-page"
      data-objectid={id}
      breadcrumbs={[
        {
          to: '/-/settings/offices',
          label: text.officesListLink,
        },
        { label: office.name },
      ]}
    >
      <MetaTags>
        <title>{createPageTitle(name)}</title>
      </MetaTags>

      <Header data-testid="office-details-header">
        <OfficeProfileHeader
          edit={canEdit}
          office={{
            id,
            img,
            name,
          }}
          onSuccess={refetch}
          onImageUploadError={onImageUploadError}
          updateName={updateOfficeName}
        />

        {mayDeleteOffice && (
          <ActionButtons>
            <DetailsActionList
              options={[
                {
                  label: text.deleteOffice,
                  onClick: () => {
                    if (officesLength === 1) {
                      setShowErrorModal(true);
                    } else {
                      handleDeleteOfficeModalAction(officeUsersList);
                    }
                  },
                  type: 'DELETE',
                  key: 'delete-office',
                },
              ]}
            />
          </ActionButtons>
        )}
      </Header>

      {!hasWorkingAreas && (
        <CallToActionBlock
          icon={{ name: 'map', background: 'accent' }}
          header={text.workingAreaHeader}
          description={text.workingAreaDescription}
          button={{
            label: text.buttonLabel,
            onClick: () => navigate(`/-/settings/offices/${id}/working-area`),
            appearance: 'secondary',
            icon: 'arrowRight',
          }}
          align="center"
          margin={[null, null, 'l', null]}
        />
      )}

      <JustificationContainer gap="m">
        <TextButton
          margin={[null, null, 's', null]}
          padding={[null]}
          icon="users"
          size="medium"
          label={text.userList}
          dataTestId="user-list-link"
          onClick={() => navigate(`/-/settings/offices/${id}/users`)}
        />

        <TextButton
          margin={[null, null, 's', null]}
          padding={[null]}
          icon="map"
          size="medium"
          label={text.workingAreas}
          dataTestId="working-areas-link"
          onClick={() => navigate(`/-/settings/offices/${id}/working-area`)}
        />
      </JustificationContainer>

      <JustificationContainer
        width="100%"
        wrap="wrap"
        justification="space-between"
      >
        <OfficeDetailsForm>
          <OfficeDetailForm
            loading={mutationLoading}
            isUserAdmin={canEdit}
            onSubmitChanges={onFormSubmit}
            initialValues={{
              email: email || null,
              phone: phone || null,
              street: street || null,
              houseNumber: houseNumber || null,
              addition: addition || null,
              postcode: postcode || null,
              city: city || null,
            }}
          />
        </OfficeDetailsForm>

        <div>
          {hasEmailPermission.allowed && (
            <>
              <AnimatedBlock title={text.emailIntegration}>
                <SynchronisedMailboxes
                  mailboxes={filteredMailboxes}
                  entityType={SynchroniseEntityType.Office}
                  entityId={id}
                  mayEdit={canEdit}
                  mayEditExplanation={text.mayEditExplanation}
                  refetch={refetch}
                  startPolling={startPolling}
                  stopPolling={stopPolling}
                />
              </AnimatedBlock>

              <SignatureContainer
                title={text.signature}
                signature={signature}
                entityTypeForMutation={{ officeId: id }}
                refetch={refetch}
                readOnly={!canEdit}
              />
            </>
          )}
        </div>
      </JustificationContainer>

      {showDeleteOfficeModal && (
        <DeleteOfficeModal
          list={showDeleteOfficeModal}
          onCancel={() => handleDeleteOfficeModalAction(null)}
          onClose={() => handleDeleteOfficeModalAction(null)}
          officeId={id}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          title={text.errorModalTitle}
          message={text.errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </ContentContainerDefault>
  );
};

const OFFICE_DETAILS_FORM_MAX_WIDTH = 540;

const OfficeDetailsForm = styled.section<{}>`
  width: 100%;
  max-width: ${OFFICE_DETAILS_FORM_MAX_WIDTH}px;
`;

export default UpdateOfficeComponent;
