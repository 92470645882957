import React, { useCallback, useEffect } from 'react';
import Dagre from '@dagrejs/dagre';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  type Node,
  type Edge,
  useNodes,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { isNil } from 'ramda';
import styled from 'styled-components';
import useNodeTypes, {
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
} from './nodeTypes';
import useEdgeTypes from './edgeTypes';
import AddNodeMenu from '../AddNodeMenu';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { interactionState, menuState as menuAtom } from '../../state';
import type { FormBuilder_NodeFragment } from '~/graphql/types';
import { nodesSelector } from '../../state/nodesAndEvents';
import MarkerDefinitions from '../MarkerDefinitions';
import useIsBasicForm from '../../hooks/useIsBasicForm';
import sortNodes from '../../utils/sortNodes';
import insertNode from '../../utils/insertNode';
import { submitScreenState } from '../../state/submitScreen';
import { START_NODE_ID, TRAILING_NODE_ID } from './constants';

export type CommonNodeData = {
  defaultNext?: string | null;
  isBounding?: boolean;
  hidden?: boolean;
};

const getEdgesForNodes = (nodes: Array<Node<CommonNodeData>>) => {
  const nodeMap = nodes.reduce(
    (acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    },
    {} as Record<string, Node<CommonNodeData>>,
  );

  const edges: Array<Edge> = [];

  nodes.forEach(node => {
    if (node.data.defaultNext) {
      const nextNode = nodeMap[node.data.defaultNext];

      const edge: Edge = {
        id: `${node.id}-${node.data.defaultNext}`,
        source: node.id,
        target: node.data.defaultNext,
        type:
          nextNode?.data?.isBounding || node.data.isBounding
            ? 'base'
            : 'addNode',
        hidden: node.data.hidden === true,
        markerEnd: { type: MarkerType.Arrow },
      };
      edges.push(edge);
    }
  });

  return edges;
};

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const SPACE_BETWEEN_NODES = 200;

export const getLayoutedElements = ({
  nodes,
  edges,
  isBasicForm,
}: {
  nodes: Array<Node<CommonNodeData>>;
  edges: Array<Edge>;
  isBasicForm: boolean;
}) => {
  g.setGraph({ rankdir: 'LR' });

  edges.forEach(edge => g.setEdge(edge.source, edge.target));
  nodes.forEach(node =>
    g.setNode(node.id, {
      ...node,
      width: DEFAULT_NODE_WIDTH,
      height: DEFAULT_NODE_HEIGHT,
    }),
  );

  Dagre.layout(g);

  const allTargetNodeIds = nodes
    .map(n => n.data.defaultNext)
    .filter(a => !isNil(a));
  const startNode = nodes.find(n => !allTargetNodeIds.includes(n.id));

  if (!startNode) {
    throw new Error('Start node does not exist');
  }

  let previousNodeX = 0;
  let previousNodeHorizontalSpan = 0;
  let prevNodeType: string | undefined = undefined;

  const layoutedNodes = nodes.map(node => {
    const nodeWidth = node.width ?? DEFAULT_NODE_WIDTH;
    const plusButtonWidth = startNode.width || 0;

    // Calculate the horizontal span based on the node type and whether it's a basic form
    const nodeHorizontalSpan =
      isBasicForm && node.type === 'eventNode'
        ? // Do not add the space for this node's width and extract the space for its edge
          // so that the next node takes its x axis position
          -SPACE_BETWEEN_NODES
        : nodeWidth;
    const nodeHeight = node.height ?? DEFAULT_NODE_HEIGHT;

    // Calculate the distance to the plus button for bounding nodes
    const DISTANCE_UNTIL_PLUS_BUTTON =
      SPACE_BETWEEN_NODES / 2 - plusButtonWidth / 2;

    // Adjust space between nodes for bounding nodes
    const spaceBetweenNodes =
      prevNodeType === 'boundingNode' || node.type === 'boundingNode'
        ? DISTANCE_UNTIL_PLUS_BUTTON
        : SPACE_BETWEEN_NODES;

    // Set the newX depending on the previous node's width and x position
    const newX =
      node.id === startNode.id
        ? 0
        : previousNodeX + previousNodeHorizontalSpan + spaceBetweenNodes;

    // Align it by the first node horizontally
    const newY = (startNode.height ?? DEFAULT_NODE_HEIGHT) / 2 - nodeHeight / 2;

    const x = isNaN(newX) ? 0 : newX;
    const y = isNaN(newY) ? 0 : newY;

    // Update previous node for the next iteration
    previousNodeX = newX;
    previousNodeHorizontalSpan = nodeHorizontalSpan;
    prevNodeType = node.type;

    return { ...node, position: { x, y }, x, y };
  });

  return {
    nodes: layoutedNodes,
    edges,
  };
};

const formatFormBuilderNodes = ({
  nodes,
  isBasicForm,
  hasSubmitScreen,
}: {
  nodes: Array<FormBuilder_NodeFragment>;
  isBasicForm: boolean;
  hasSubmitScreen: boolean;
}): Array<Node<CommonNodeData>> => {
  const sortedNodes = sortNodes({ nodes });

  const formattedNodes = sortedNodes.map((node, index) => ({
    data: {
      defaultNext:
        // Link up the last node with the trailing node
        index === nodes.length - 1
          ? TRAILING_NODE_ID
          : node.defaultNext?.targetNodeId,
      isBounding: false,
      // Allows to hide the event node edges in basic forms
      hidden: isBasicForm && node.__typename === 'FormBuilder_EventNode',
    },
    type:
      node.__typename === 'FormBuilder_EventNode' ? 'eventNode' : 'screenNode',
    id: node.id,
    position: {
      x: 0,
      y: 0,
    },
  }));

  const startNode = {
    id: START_NODE_ID,
    type: 'boundingNode',
    position: { x: 0, y: 0 },
    data: { defaultNext: formattedNodes[0].id, isBounding: true },
  };
  const trailingNode = {
    id: TRAILING_NODE_ID,
    type: 'boundingNode',
    position: { x: 0, y: 0 },
    data: { defaultNext: null, isBounding: true },
  };
  const hideTrailingNode = isBasicForm && hasSubmitScreen;

  return [
    startNode,
    ...formattedNodes,
    ...(hideTrailingNode ? [] : [trailingNode]),
  ];
};

export type Props = {
  nodes: Array<FormBuilder_NodeFragment>;
};

const Canvas: React.FC<Props> = ({ nodes: passedNodes }) => {
  const [menuState, setMenuState] = useRecoilState(menuAtom);
  const setNodesState = useSetRecoilState(nodesSelector);
  const setInteraction = useSetRecoilState(interactionState);
  const nodeTypes = useNodeTypes();
  const edgeType = useEdgeTypes();
  const renderedNodes = useNodes();
  const isBasicForm = useIsBasicForm();
  const hasSubmitScreen = useRecoilValue(submitScreenState) != null;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const renderedNodeSizes = renderedNodes.reduce((prev, current) => {
    prev[current.id] = {
      width: current.width,
      height: current.height,
    };
    return prev;
  }, {});

  // key used to update the nodes with sizes without causing infinite calls to useEffect
  const renderedNodeSizesKey = JSON.stringify(renderedNodeSizes);

  // Whenever the rendered nodes are updated, pass the width and height to nodes
  // so that we can position them accordingly
  useEffect(() => {
    const formattedNext = formatFormBuilderNodes({
      nodes: passedNodes,
      isBasicForm,
      hasSubmitScreen,
    });
    const nodesWithSize = formattedNext.map((node: Node<CommonNodeData>) => ({
      ...node,
      width: renderedNodeSizes[node.id]?.width,
      height: renderedNodeSizes[node.id]?.height,
    }));
    const nextEdges = getEdgesForNodes(nodesWithSize);
    const nextLayouted = getLayoutedElements({
      nodes: nodesWithSize,
      edges: nextEdges,
      isBasicForm,
    });

    setNodes(nextLayouted.nodes);
    setEdges(nextLayouted.edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedNodeSizesKey, passedNodes, isBasicForm]);

  const onConnect = useCallback(
    params => setEdges(els => addEdge(params, els)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeType}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        fitViewOptions={{ maxZoom: 0.5 }}
        attributionPosition="bottom-left"
        nodesDraggable={false}
        edgesFocusable={false}
      >
        <Background />
        <MarkerDefinitions />

        {menuState && (
          <AddNodeMenu
            onSelect={(createType, relation) => {
              // Show modal for getting the name of the screen
              // insertNode will be done from the Interaction Handler
              if (createType === 'screen') {
                setMenuState(null);
                return setInteraction({
                  interactionType: 'create-node-screen',
                  relation,
                });
              }

              setNodesState(prev =>
                insertNode({
                  createType,
                  relation,
                  prevNodes: prev,
                }),
              );
              setMenuState(null);
            }}
            onClose={() => {
              setMenuState(null);
            }}
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${menuState.targetX}px,${menuState.targetY + 100}px)`,
              fontSize: 12,
              zIndex: 9999,
            }}
          />
        )}
      </ReactFlow>
    </Container>
  );
};

const Container = styled.div`
  height: 90vh;
  width: 100%;
`;

export default Canvas;
