import styled from 'styled-components';
import React from 'react';

import type { TableHeaderCellType } from '~/components/bad/DataTables/types.flow';

import { Checkbox } from '~/components/bad/Inputs/Checkbox';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  column: TableHeaderCellType<any>;
  onChange: () => void;
  minus: boolean;
  value: boolean;
  noRows: boolean;
  isFocused: boolean;
};

const CheckboxHeaderCell = React.forwardRef<HTMLDivElement, Props>(
  ({ column, onChange, minus, value, noRows, isFocused, ...rest }, ref) => (
    <CheckboxContainer
      align="center"
      {...rest}
      data-testid={column.dataTestId}
      ref={ref}
    >
      {!noRows && (
        <Checkbox
          name="selectAllCheckbox"
          value={value}
          minus={minus}
          isFocused={isFocused}
          onChange={onChange}
          large
        />
      )}
    </CheckboxContainer>
  ),
);

const CheckboxContainer = styled(JustificationContainer)<{}>`
  height: 100%;
`;

export default CheckboxHeaderCell;
