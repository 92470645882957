import React from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '~/theme';
import Checkbox from '~/components/bad/Inputs/Checkbox';
import MasonryCard from '~/components/molecule/MasonryCard';
import RadioButton from '../RadioButton';

export type Props = {
  checkboxTitle: React.ReactNode;
  description?: React.ReactNode;
  type: 'checkbox' | 'radio';
  maxCardWidth?: string;

  checked: boolean;
  disabled?: boolean;

  onClick?: () => void;
};

const SelectBlock: React.FCC<Props> = ({
  dataTestId,
  type,
  checkboxTitle,
  checked,
  disabled,
  description,
  children,
  maxCardWidth,
  onClick,
  ...rest
}) => {
  const componentByType = {
    checkbox: StyledCheckbox,
    radio: StyledRadioButton,
  };

  const ComponentByType: React.FCC<CardProps> =
    componentByType[type] ?? componentByType.checkbox;

  // When the component is disabled we shouldn't be able to do anything with the component
  const _onClick = disabled ? () => {} : onClick;

  return (
    <StyledMasonryCard
      dataTestId={dataTestId}
      checked={checked}
      disabled={disabled}
      onClick={_onClick}
      maxWidth={maxCardWidth}
      {...rest}
    >
      <ComponentByType
        value={checked}
        label={checkboxTitle}
        checked={checked}
        disabled={disabled}
        onChange={_onClick}
        dataTestId={`${type}-test-id`}
      />
      {description && <Description>{description}</Description>}
      {children}
    </StyledMasonryCard>
  );
};

type CardProps = {
  value?: boolean;
  label?: React.ReactNode;
  checked: boolean;
  disabled?: boolean;

  onClick?: Props['onClick'];
  onChange?: Props['onClick'];
};

const getComponentColor = (theme: Theme, disabled: boolean = false): string =>
  disabled ? theme.color('tertiary', 'dark') : theme.color('primary');

const StyledMasonryCard = styled(MasonryCard)<CardProps>(
  ({ theme, checked, disabled }) => css`
    user-select: none;

    margin-bottom: ${theme.space('l')};
    padding: ${theme.space('l')};
    min-height: unset;

    border: ${theme.getTokens().border.width.base} solid
      ${checked
        ? getComponentColor(theme, disabled)
        : theme.color('primary', 'translucent')};
    transition: all 0.3s ease;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    ${!disabled &&
    css`
      &:hover {
        border: ${theme.getTokens().border.width.base} solid
          ${theme.color('primary')};
      }
    `}
  `,
);

const StyledRadioButton = styled(RadioButton)<CardProps>(
  ({ theme, disabled }) => css`
    cursor: inherit;
    font-weight: ${theme.fw('semiBold')};
    color: ${disabled
      ? getComponentColor(theme, disabled)
      : theme.color('secondary')};
  `,
);

const StyledCheckbox = styled(Checkbox)<CardProps>(
  ({ theme, disabled }) => css`
    cursor: inherit;
    font-weight: ${theme.fw('semiBold')};
    color: ${disabled
      ? getComponentColor(theme, disabled)
      : theme.color('secondary')};

    & > input {
      &:hover,
      &:focus {
        border-color: ${theme.color('tertiary', 'dark')};
      }
    }
  `,
);

const RADIO_MARGIN = '28px';
const CHECKBOX_MARGIN = '35px';

const Description = styled.div<{ radioButton?: boolean }>(
  ({ theme, radioButton }) => css`
    margin-left: ${radioButton ? RADIO_MARGIN : CHECKBOX_MARGIN};
    margin-top: ${theme.space('m')};
  `,
);

export default SelectBlock;
