import { clone, equals, isNil, last, lensPath, pick, set, view } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  ContactFiltersSubjectFragment,
  ContactFiltersSubjectAttributeActionFragment,
  ContactFiltersSubjectInstanceAttributeActionFragment,
  ConnectorOperator,
} from '~/graphql/types';

import Icon from '~/components/atom/Icon';
import { Crumb } from '~/components/organism/Selector/components/Breadcrumbs';

import Selector, { DEFAULT_OFFSET } from '~/components/organism/Selector';
import JustificationContainer from '~/components/atom/JustificationContainer';
import FilterInnerSelector from '../FilterInnerSelector';
import getOuterFieldsByPath, {
  OuterContactFilterField,
} from '../../util/contactFilter/getOuterFieldsByPath';

import { generateDirectoryMap } from '../../util/contactFilter/getDirectory';

import getPathForLeaf from '../../util/contactFilter/getPathForLeaf';
import { CurrentLeaf, Leaf } from '../FilterGroupV2';

import renderHandlebarsTemplate from '~/util/handlebars';
import usePrevious from '~/hooks/usePrevious';
import useOnClickOutside from '~/hooks/useOnClickOutside';

import { FilterOptions } from '~/hooks/useContactFilterOptionsV2';
import { TopicMap } from '../../util/contactFilter/getTopic';
import { OptionTypeMap } from '../../util/contactFilter/getOptionType';
import {
  CommandMap,
  TypeIdToCommandsMap,
} from '../../util/contactFilter/getCommand';
import { SubjectMap } from '../../util/contactFilter/getSubject';
import { TypeMap } from '../../util/contactFilter/getType';
import useErrorReporter from '~/hooks/useErrorReporter';

import useSize from '~/hooks/useSize';
import useNonNullablePrevious from '~/components/bad/util/useNonNullablePrevious';
import ErrorScreen from '~/components/page/ErrorScreen';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import useRepositionToBeInView from '../../util/contactFilter/useRepositionToBeInView';
import { Heading6, Variant } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';

export type SelectedOuterField = Array<OuterContactFilterField>;

export type CurrentCommand = {
  commandIdx: number;
} | null;

export type Props = {
  onSave: () => void;
  leafs: Array<Leaf>;
  filterGroupIndex: number;
  setLeafs: React.Dispatch<React.SetStateAction<Props['leafs']>>;
  setCurrentLeaf: React.Dispatch<React.SetStateAction<CurrentLeaf>>;
  currentLeafIndex: number;
  currentCommand: CurrentCommand;
  setCurrentCommand: React.Dispatch<React.SetStateAction<CurrentCommand>>;
  filterOptions: FilterOptions;
  subjectMap: SubjectMap;
  topicMap: TopicMap;
  typeMap: TypeMap;
  optionTypeMap: OptionTypeMap;
  commandMap: CommandMap;
  typeIdToCommandsMap: TypeIdToCommandsMap;
  representationButtonElement: HTMLButtonElement | null;
};

const text = {
  selectFilter: 'Filter op...',
  helpLabel: 'Hulp nodig? Check onze kennisbank',
};
export const isSubjectFragment = (
  field?: $GetElementType<SelectedOuterField>,
): field is ContactFiltersSubjectFragment =>
  !isNil(field) && field.__typename === 'ContactFiltersSubject';

export const isSubjectAttributeAction = (
  field?: $GetElementType<SelectedOuterField>,
): field is ContactFiltersSubjectAttributeActionFragment =>
  !isNil(field) && field.__typename === 'ContactFiltersSubjectAttributeAction';

export const isSubjectInstanceAttributeAction = (
  field?: $GetElementType<SelectedOuterField>,
): field is ContactFiltersSubjectInstanceAttributeActionFragment &
  Pick<ContactFiltersSubjectFragment, 'eventId' | 'subjectId'> =>
  !isNil(field) &&
  field.__typename === 'ContactFiltersSubjectInstanceAttributeAction';

const FilterActionSelector: React.FCC<Props> = ({
  representationButtonElement,
  onSave,
  filterGroupIndex,
  currentLeafIndex,
  leafs,
  setLeafs,
  filterOptions,
  subjectMap,
  commandMap,
  typeMap,
  optionTypeMap,
  topicMap,
  typeIdToCommandsMap,
  setCurrentLeaf,
  setCurrentCommand,
  currentCommand,
}) => {
  const { subjects } = filterOptions;
  const leaf = leafs[currentLeafIndex];
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [disabled, setDisabled] = useState(false);

  const prevDisabled = usePrevious(disabled);

  // clearTimeout on unmount
  useEffect(
    () => () => {
      if (timer.current != null) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  useEffect(() => {
    if (currentCommand == null && prevDisabled != false) {
      timer.current = setTimeout(() => setDisabled(false), 50);
      return;
    }
    if (timer.current != null) clearTimeout(timer.current);
    if (currentCommand == null) return;
    setDisabled(true);
  }, [prevDisabled, currentCommand]);

  const initialSelectedField = useMemo(() => {
    if (!leaf || (!leaf.attribute && !leaf.instanceAttribute)) return [];

    const path = getPathForLeaf(leaf, {
      commandMap,
      topicMap,
      subjectMap,
    });
    if (path.error != null || path.result.basePath.error != null) return [];

    return path.result.basePath.result;
  }, [commandMap, leaf, subjectMap, topicMap]);

  const [selectedField, setSelectedField] = useState<SelectedOuterField>(
    initialSelectedField ?? [],
  );

  const [portalledSelectorRef, setPortalledSelectorRef] = useState<
    React.MutableRefObject<HTMLDivElement | null>
  >({ current: null });

  const directoryMap = generateDirectoryMap(subjects);

  const currentFields = useMemo(
    () =>
      getOuterFieldsByPath(selectedField, {
        subjectMap,
        directoryMap,
      }),
    [selectedField, subjectMap, directoryMap],
  );

  const subj = selectedField.find(isSubjectFragment);
  const currentInstanceAttributeAction = selectedField.find(
    isSubjectInstanceAttributeAction,
  );
  const subject = subj ? subjectMap[subj.subjectId][subj.eventId ?? ''] : null;
  const previousInstanceAttributeAction = useNonNullablePrevious(
    currentInstanceAttributeAction,
  );

  const onFieldSelect = (field: OuterContactFilterField) => {
    if (isSubjectAttributeAction(field)) {
      const leafPath = lensPath([currentLeafIndex]);

      setLeafs(prev =>
        set(
          leafPath,
          {
            ...view(leafPath, prev),
            attribute: {
              ...view(lensPath([currentLeafIndex, 'attribute']), prev),
              ...field,
              actionKey: field.key,
              subjectId: subject?.subjectId,
              eventId: subject?.eventId ?? undefined,
            },
            instanceAttribute: null,
          },
          clone(prev),
        ),
      );
    }
    if (isSubjectInstanceAttributeAction(field)) {
      const leafPath = lensPath([currentLeafIndex]);
      const instanceAttributePath = lensPath([
        currentLeafIndex,
        'instanceAttribute',
      ]);

      const commands =
        previousInstanceAttributeAction &&
        !equals(
          pick(['subjectId', 'eventId'], previousInstanceAttributeAction),
          pick(['subjectId', 'eventId'], field),
        ) // Reset the commands if the user changed the subject
          ? [{}]
          : view(instanceAttributePath, leafs)?.commands ?? [{}];

      if (commands.length === 1) {
        setCurrentCommand({ commandIdx: 0 });
      }

      setLeafs(prev =>
        set(
          leafPath,
          {
            ...view(leafPath, prev),
            instanceAttribute: {
              ...view(instanceAttributePath, prev),
              subjectId: subject?.subjectId,
              eventId: subject?.eventId ?? undefined,

              negate:
                view(
                  lensPath([currentLeafIndex, 'instanceAttribute', 'negate']),
                  prev,
                ) ?? false,
              connector:
                view(
                  lensPath([
                    currentLeafIndex,
                    'instanceAttribute',
                    'connector',
                  ]),
                  prev,
                ) ?? ConnectorOperator.And,

              commands:
                previousInstanceAttributeAction &&
                !equals(
                  pick(
                    ['subjectId', 'eventId'],
                    previousInstanceAttributeAction,
                  ),
                  pick(['subjectId', 'eventId'], field),
                ) // Reset the commands if the user changed the subject
                  ? [{}]
                  : view(instanceAttributePath, prev)?.commands ?? [{}],
            },
            attribute: null,
          },
          clone(leafs),
        ),
      );
    }

    return setSelectedField(prev => prev.concat(field));
  };

  const errorReporter = useErrorReporter();

  const [containerRef, transform, recalculatePosition] =
    useRepositionToBeInView<HTMLDivElement>(
      {
        element: representationButtonElement,
        placement: 'bottom-left',
      },
      0.1,
    );

  useOnClickOutside(
    containerRef,
    e => {
      const dropdownRoot = document.getElementById('dropdown-portal-root');

      if (
        e.target instanceof Node &&
        dropdownRoot &&
        dropdownRoot.contains(e.target)
      ) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        e.target.dispatchEvent(clickEvent);
      } else {
        onSave();
      }
    },
    currentCommand != null ? portalledSelectorRef : undefined,
  );

  useGlobalKeyBinding({
    keys: ['enter'],
    callback: event => {
      event.stopPropagation();
      event.preventDefault();
      onSave();
    },
    enabled: portalledSelectorRef?.current == null,
  });

  const [debouncedSize, recalculateSize] = useSize(containerRef);

  const onBreadcrumbClick = useCallback(
    (_crumb: Crumb, index: number) => {
      if (_crumb.key === 'root') {
        return setSelectedField([]);
      }

      return setSelectedField(selectedField.slice(0, index));
    },
    [selectedField],
  );

  const lastSelectedField = last(selectedField);

  const saveButtonEnabled =
    lastSelectedField?.__typename === 'ContactFiltersSubjectAttributeAction' ||
    lastSelectedField?.__typename ===
      'ContactFiltersSubjectInstanceAttributeAction';

  useEffect(() => {
    recalculateSize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedField]);

  useEffect(() => {
    if (currentCommand != null) return;
    recalculateSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCommand]);

  useEffect(() => {
    if (containerRef == null || containerRef.current == null) return;

    recalculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, debouncedSize]);

  if (currentFields.error !== undefined) {
    errorReporter.captureException(
      new Error(
        JSON.stringify(
          currentFields.error || 'FilterActionSelector currentFields error',
        ),
      ),
      'fatal',
    );
    return <ErrorScreen />;
  }

  return (
    <ModalPopover
      $translateX={transform.x}
      ref={r => {
        containerRef.current = r;
      }}
    >
      <Selector
        pointerPosition={{
          location: 'top',
          offset: DEFAULT_OFFSET - transform.x,
        }}
        overflowY={currentCommand != null ? 'hidden' : 'scroll'}
        helpLink={
          <Link
            to="https://help.dathuis.nl/nl/articles/5991139-het-filteren-van-de-contactenlijst"
            target="_blank"
          >
            <JustificationContainer
              justification="center"
              padding={[null, null, null, null]}
            >
              <Icon name="question-mark" margin={[null, 'xxs', null, null]} />
              <span>{text.helpLabel}</span>
            </JustificationContainer>
          </Link>
        }
        actions={[
          {
            key: 'trash',
            icon: 'trashcan',
            disabled,
            onClick: () => {
              setCurrentLeaf(null);
              setCurrentCommand(null);
              setLeafs(prev => {
                const clonedPrev = clone(prev);
                clonedPrev.splice(currentLeafIndex, 1);

                return clonedPrev;
              });
            },
            appearance: 'danger',
            dataTestId: 'delete-button',
          },
          saveButtonEnabled
            ? {
                key: 'save',
                icon: 'check',
                disabled,
                onClick: onSave,
                appearance: 'secondary',
                dataTestId: 'save-button',
              }
            : null,
        ]}
        crumbs={[
          {
            label: '...',
            key: 'root',
          },
          ...selectedField.map(field => ({
            label: field.label,
            key: getKeyForField(field),
            disabled: currentCommand != null,
          })),
        ]}
        onBreadcrumbClick={onBreadcrumbClick}
      >
        {lastSelectedField?.__typename ===
        'ContactFiltersSubjectInstanceAttributeAction' ? (
          <FilterInnerSelector
            setPortalledSelectorRef={setPortalledSelectorRef}
            debouncedActionSelectorSize={debouncedSize}
            filterGroupIndex={filterGroupIndex}
            currentCommand={currentCommand}
            setCurrentCommand={setCurrentCommand}
            basePath={selectedField}
            subjectMap={subjectMap}
            topicMap={topicMap}
            typeMap={typeMap}
            optionTypeMap={optionTypeMap}
            commandMap={commandMap}
            typeIdToCommandsMap={typeIdToCommandsMap}
            leaf={leaf}
            leafs={leafs}
            setLeafs={setLeafs}
            subject={subject}
            currentLeafIndex={currentLeafIndex}
          />
        ) : lastSelectedField?.__typename ===
          'ContactFiltersSubjectAttributeAction' ? (
          <Heading6
            margin={[null]}
            variant={Variant.primary}
            fontWeight="regular"
          >
            {renderHandlebarsTemplate(lastSelectedField.representation, {
              label: subject?.label,
            })}
          </Heading6>
        ) : selectedField.length === 0 ? (
          [
            <ListItem key={'DH###SelectFilter'} onClick={() => {}} isTitle>
              {text.selectFilter}
            </ListItem>,
            ...currentFields.result.map(
              field =>
                field && (
                  <ListItem
                    key={getKeyForField(field)}
                    onClick={() => onFieldSelect(field)}
                    data-objectid={getKeyForField(field)}
                  >
                    {field.label}
                  </ListItem>
                ),
            ),
          ]
        ) : (
          currentFields.result.map(
            field =>
              field && (
                <ListItem
                  key={getKeyForField(field)}
                  onClick={() => onFieldSelect(field)}
                  data-objectid={getKeyForField(field)}
                >
                  {field.label}
                </ListItem>
              ),
          )
        )}
      </Selector>
    </ModalPopover>
  );
};

const getKeyForField = (field: $GetElementType<SelectedOuterField>) =>
  field.__typename === 'ContactFiltersSubject'
    ? `${field?.subjectId}:${field?.eventId}`
    : field.__typename === 'ContactFiltersSubjectInstanceAttributeAction'
      ? 'InstanceAttribute'
      : field.key;

const ListItem = styled.li<{ isTitle?: boolean }>(
  ({ theme, isTitle }) => css`
    padding: ${theme.space('xxs')};
    cursor: ${isTitle ? 'cursor' : 'pointer'};
    color: ${theme.color('text')};
    border-radius: ${theme.getTokens().border.radius.base};

    font-weight: ${theme.fw(isTitle ? 'semiBold' : 'regular')};
    transition: all 0.2s ease-out;

    ${!isTitle &&
    `&:hover {
      background-color: ${theme.color('tertiary', 'light')};
      color: ${theme.color('tertiary', 'text')};
    }`}
  `,
);

const ModalPopover = styled.div<{ $translateX?: number }>`
  position: absolute;
  top: 40px;
  left: 0px;
  display: flex;
  flex-wrap: wrap;

  /*transition: all 0.1s ease-out;*/

  ${({ theme, $translateX }) => css`
    ${$translateX != null && `transform: translate(${$translateX}px);`}

    z-index: ${theme.z('dropdown')};
    min-width: 500px;
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.modal};
  `}
`;

export default FilterActionSelector;
