import React from 'react';
import { renderToString } from 'react-dom/server';
import { Check, Minus } from 'react-feather';
import styled, { css } from 'styled-components';
import { Label } from '~/components/atom/Typography';

export type Props = {
  onBlur?: (arg0: React.FocusEvent<any> | null | undefined) => void;
  onChange?: (arg0: React.SyntheticEvent<any> | any) => void;
  onFocus?: (arg0: React.FocusEvent<any> | null | undefined) => void;

  name?: string;

  /** Label to be displayed, will also act as placeholder if nothing is entered. */
  label?: React.ReactNode;

  /** Error, this replaces the label if its set. */
  error?: string | null | undefined;
  value: boolean;
  className?: string;

  /** Custom label component that can be used instead of the label */
  labelComponent?: React.ReactNode;

  minus?: boolean;
  disabled?: boolean;
  large?: boolean;
  /** Shows empty checkbox with primary background */
  isFocused?: boolean;
};

/**
 * CheckBox Element
 *
 * Should pretty much always be used in combination with a InputGroup
 * in order to have consistent styling.
 */
export const Checkbox: React.FCC<Props> = ({
  label,
  error,
  value,
  className,
  labelComponent,
  onChange,
  dataTestId,
  ...rest
}) => {
  const svgProps = {
    color: 'rgb(255,255,255)',
    height: rest.large ? '1.1em' : '0.95em',
    width: rest.large ? '1.1em' : '0.95em',
  };
  const checkIconStr = renderToString(<Check {...svgProps} />);
  const minusIconStr = renderToString(<Minus {...svgProps} />);

  return (
    <Container
      error={error}
      className={className}
      onClick={e => e.stopPropagation()}
      disabled={rest.disabled}
    >
      <CheckBoxElement
        type="checkbox"
        value={value.toString()}
        checked={value}
        onChange={e => {
          /**
           *  This is a fix for https://bugzilla.mozilla.org/show_bug.cgi?id=62151.
           *  We prevent the default behaviour of any parent of checkbox element while still enabling checkbox to be checked
           */
          e && e.preventDefault();
          setTimeout(() => onChange && onChange(e), 0);
        }}
        data-testid={dataTestId}
        $checkIconStr={checkIconStr}
        $minusIconStr={minusIconStr}
        {...rest}
      />
      &nbsp;
      <Label margin={[null]} size="base" fontWeight="regular">
        {error || label || labelComponent}
      </Label>
    </Container>
  );
};

type LabelProps = {
  error?: string | null;
  disabled?: boolean;
};
const Container = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme, error, disabled }) => {
    if (error) {
      return css`
        color: ${theme.color('danger')};
      `;
    }
    if (disabled) {
      return css`
        color: ${theme.color('tertiary', 'dark')};
      `;
    }

    return null;
  }};
`;

type CheckboxProps = {
  disabled?: boolean;
  $isFocused?: boolean;
  $large?: boolean;
  $minus?: boolean;
  $checkIconStr: string;
  $minusIconStr: string;
};
const CheckBoxElement = styled.input<CheckboxProps>`
  margin-right: 1em;
  cursor: pointer;
  position: relative;
  appearance: none;
  box-sizing: border-box;

  ${({ theme, $isFocused, $large, $minus, $checkIconStr, $minusIconStr }) => {
    const size = $large ? '1.5rem' : '1.2rem';

    return css`
      border: ${theme.getTokens().border.width.s} solid
        ${theme.color('tertiary', 'dark')};
      border-radius: ${theme.getTokens().border.radius.s};
      background-color: ${$isFocused
        ? theme.color('primary')
        : theme.color('white')};

      height: ${size};
      width: ${size};
      min-height: ${size};
      min-width: ${size};

      &:focus,
      &:hover {
        border: ${theme.getTokens().border.width.s} solid ${theme.color('text')};
      }

      &:disabled {
        background-color: ${theme.color('white', 'dark')};
        border-color: ${theme.color('tertiary', 'dark')};
        cursor: auto;

        &:checked {
          background-color: ${theme.color('tertiary', 'light')};
        }
      }

      &:checked {
        /** This is a temporary solution until we make a new checkbox component */
        background-image: ${`url('data:image/svg+xml;utf8,${
          $minus ? $minusIconStr : $checkIconStr
        }')`};
        background-repeat: no-repeat;
        background-position: center;
        border: none;

        background-color: ${({ theme }) => theme.color('secondary')};
      }
    `;
  }};
`;

export default Checkbox;
