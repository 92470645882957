import React from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'ramda';
import arrayToCss from '~/util/arrayToCss';
import TEST_ID from './TopSectionContainer.testid';
import useAccounts from '~/hooks/useAccounts';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { AccountStatus } from '~/graphql/types';
import { FONT_SIZE_OF_18 } from '~/styles/constants';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AccountSwitcher from '~/components/organism/AccountSwitcher';

type Props = {
  headerText: string;
  withBorder: boolean;
};
const TopSectionContainer: React.FCC<Props> = ({
  children,
  headerText,
  withBorder,
}) => {
  const accounts = useAccounts();

  const isCurrentAccountIncomplete =
    useCurrentAccount().status === AccountStatus.Incomplete;

  return (
    <Container
      align="center"
      direction="column"
      withBorder={withBorder}
      data-testid={TEST_ID.CONTAINER}
    >
      {accounts.length > 1 && isCurrentAccountIncomplete && (
        <DropdownContainer>
          <AccountSwitcher dataTestId={TEST_ID.ACCOUNTS_MENU} />
        </DropdownContainer>
      )}

      <HeaderContainer $equalMargin={isNil(children)}>
        {headerText}
      </HeaderContainer>
      {children}
    </Container>
  );
};

type ContainerProps = {
  withBorder: boolean;
};
const Container = styled(JustificationContainer)<ContainerProps>`
  width: 100%;

  ${({ theme, withBorder }) => {
    if (withBorder) {
      return css`
        border-bottom: 1px solid ${theme.color('tertiary')};
      `;
    }

    return '';
  }};
`;

const HeaderContainer = styled.div<{ $equalMargin: boolean }>(
  ({ theme, $equalMargin }) => css`
    align-self: center;
    font-weight: ${theme.fw('semiBold')};
    color: ${theme.color('primary')};
    font-size: ${FONT_SIZE_OF_18}px;
    margin: ${arrayToCss(
      $equalMargin ? ['xl', null] : ['xl', null, 's', null],
      theme,
    )};
  `,
);

const DropdownContainer = styled.div<{}>`
  position: absolute;
  align-self: flex-end;

  ${({ theme }) => css`
    margin-top: ${({ theme }) => theme.space('m')};

    ${theme.mq.lessThan('mobile')`
      position: static;
    `};
  `}
`;

export default TopSectionContainer;
