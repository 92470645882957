import { selector, selectorFamily } from 'recoil';
import { formState } from '.';
import getIssuesForNodes from '../utils/getIssuesForNodes';
import { FormBuilderMode, type FormBuilder_Locale } from '~/graphql/types';
import getIssuesForForm from '../utils/getIssuesForForm';
import { submitScreenState } from './submitScreen';
import { contactInputBlocksUsed } from './contactInputBlocks';
import { isNil } from 'ramda';
import getIssuesForBasicForm from '../utils/getIssuesForBasicForm';
import getMiscIssues from '../utils/getMiscIssues';
import type { SectionId } from './asideExplanded';

export type Issue = {
  level: 'error' | 'warning';
  message: string;

  /** Used to show Aside errors */
  sectionId?: SectionId;
  inputKey?: string | null;

  /** Used to show node errors */
  nodeId?: string | null;
  blockKey?: string | null;
  localeKey?: FormBuilder_Locale;
  optionKey?: string | null;
};

export const issueState = selector<Array<Issue>>({
  key: 'formBuilder/issueState',
  get: ({ get }) => {
    const form = get(formState);
    if (!form) return [];

    const isBasicForm = form.mode === FormBuilderMode.Basic;
    const hasSubmitScreen = !isNil(get(submitScreenState));
    const contactEmailMissing = isNil(
      get(contactInputBlocksUsed).Contact_Email,
    );

    const issues: Array<Issue> = [
      ...getIssuesForForm(form),
      ...getIssuesForNodes(form),
      ...getIssuesForBasicForm({
        isBasicForm,
        hasSubmitScreen,
        nodes: form.nodes,
      }),
      ...getMiscIssues({
        nodes: form.nodes,
        contactEmailMissing,
      }),
    ];

    return issues;
  },
});

export const issuesByPath = selectorFamily<
  Array<Issue | null>,
  [string, string] | [string]
>({
  key: 'formBuilder/issuesByBlockId',
  get:
    pathToBlock =>
    ({ get }) => {
      const issues = get(issueState);
      if (!issues) return [];

      const issuesForNode = issues.filter(
        issue => issue.nodeId === pathToBlock[0],
      );

      if (!pathToBlock[1]) return issuesForNode;

      const issuesForBlock = issuesForNode.filter(
        issue => issue.blockKey === pathToBlock[1],
      );

      return issuesForBlock;
    },
});

export const issuesBySettingsId = selectorFamily<
  Array<Issue | null>,
  SectionId
>({
  key: 'formBuilder/issuesBySettingsId',
  get:
    id =>
    ({ get }) => {
      const issues = get(issueState);
      if (!issues) return [];

      const issuesForSettings = issues.filter(
        issue => !isNil(issue.sectionId) && issue.sectionId === id,
      );

      return issuesForSettings;
    },
});
