import React from 'react';
import { animated, CSS } from 'react-spring';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import useHover from '~/components/bad/util/useHover';
import { Body, Heading4 } from '~/components/atom/Typography';
import TextButton from '~/components/atom/TextButton';
import Tooltip from '~/components/molecule/Tooltip';
import Icon, { IconType } from '~/components/atom/Icon';

export type Variant = 'success' | 'danger' | 'primary';

export type Props = {
  heading: string;
  description?: React.ReactNode;
  token?: string;
  variant?: Variant;
  disabled?: boolean;
  style?: typeof CSS;
  cancelText?: string;
  tooltipText?: string;
  onDelete?: () => void;
};

const APIBlock: React.FCC<Props> = ({
  dataTestId,
  heading,
  description,
  token,
  cancelText,
  onDelete,
  tooltipText,
  variant = 'primary',
  disabled = false,
  ...rest
}) => {
  const [showTooltip, tooltipProps] = useHover();

  return (
    <Container
      data-testid={dataTestId}
      data-variant={variant}
      $variant={variant}
      $disabled={disabled}
      {...rest}
    >
      <Heading4 color={{ group: variant }}>{heading}</Heading4>
      {token && <Token margin={[null]}>{token}</Token>}
      <Description as="div">
        {description && (
          <>
            <StyledIcon
              name={getIconNameForVariant(variant)}
              background={variant}
              color="#fff"
            />
            {description}
          </>
        )}
      </Description>

      {onDelete && cancelText && (
        <>
          {showTooltip && tooltipText && (
            <StyledTooltip>{tooltipText}</StyledTooltip>
          )}
          <TextButton
            onClick={onDelete}
            label={cancelText}
            appearance="danger"
            padding={[null]}
            dataTestId={TEST_ID.DELETE_TOKEN}
            disabled={disabled}
            {...tooltipProps}
          />
        </>
      )}
    </Container>
  );
};

const getIconNameForVariant = (variant: Variant): IconType => {
  switch (variant) {
    case 'primary':
      return 'clock';
    case 'danger':
      return 'error';
    default:
      return 'check';
  }
};

const StyledTooltip = styled(Tooltip)<{}>`
  top: 0;
`;

const Container = styled(animated.div)<{
  $variant: Variant;
  $disabled: boolean;
}>(
  ({ theme, $variant, $disabled }) => css`
    display: flex;
    flex-direction: column;

    background-color: ${theme.color($variant, 'translucent')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.m};
    opacity: ${$disabled ? '0.7' : 1};
    cursor: ${$disabled ? 'not-allowed' : 'default'};
  `,
);

const Token = styled(Body)<{}>(
  ({ theme }) => css`
    color: ${theme.color('tertiary', 'dark')};
  `,
);

const Description = styled(Body)<{}>`
  display: flex;
  flex: 0 1;
  height: 100%;

  ${({ theme }) => css`
    margin-top: ${theme.space('xxs')};
    margin-bottom: ${theme.space('m')};
    font-size: ${theme.fs('base')};
  `}
`;

const StyledIcon = styled(Icon)<{}>`
  margin-right: 1em;
`;

export default APIBlock;
