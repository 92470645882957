import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { iconForTypeInHeader, taskTypeOptions } from '~/util/taskTypeOptions';
import { stringSlicer } from '~/util/string';
import { Task } from '~/components/page/Tasks/types';
import {
  TaskStatus,
  TaskType,
  type UpdateTaskMutation,
  type UpdateTaskMutationVariables,
} from '~/graphql/types';

import Editor from '~/components/bad/Editor';
import Button from '~/components/atom/Button';

import TEST_ID from './index.testid';

import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import { Field, FormRenderProps } from 'react-final-form';
import { FormType } from '~/components/organism/ActivityTabs/components/CreateNewTaskTab';
import useCurrentUser from '~/hooks/useCurrentUser';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import text from '../../text';
import { convertDateToServerDate } from '~/util/date';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useViewingModeProps from '~/hooks/useViewingModeProps';

import { Heading4, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Alerts from '~/components/bad/Alerts';
import Dropdown, { SelectedOption } from '~/components/molecule/Dropdown';
import TaskInfoBlock from '~/components/molecule/TaskInfoBlock';
import FormUtils from '~/components/organism/FormUtils';
import { DateField } from '~/components/organism/Forms';
import UpdateFormButtons from '../UpdateFormButtons';
import Input from '~/components/molecule/Input';
import useUserOptions from '~/hooks/useUserOptions';
import type { MutationFunction } from '@apollo/client';
import TimePicker from '~/components/organism/TimePicker';

type UpdateTaskMutationFunction = MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

export type Props = {
  formProp: FormRenderProps<FormType, Partial<FormType>>;
  taskDetails: Task;
  handleCloseModal: () => void;
  contactId?: string | null;
  loading: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  errorMsg?: string | null;
  updateTask: UpdateTaskMutationFunction;
  dueDateDate: string | null;
};

const TITLE_MAX_LENGTH = 60;

const ActualForm: React.FCC<Props> = ({
  formProp,
  taskDetails,
  handleCloseModal,
  contactId,
  loading,
  isEdit,
  setIsEdit,
  errorMsg,
  updateTask,
  dueDateDate,
  ...rest
}) => {
  const viewingModeProps = useViewingModeProps();
  const account = useCurrentAccount();
  const [validate, setValidate] = useState<boolean>(false);

  const { handleSubmit, errors, submitting, form } = formProp;

  const disabledForm = submitting || loading;

  const { getFieldState, change } = form;

  const officeFieldState = getFieldState('officeId');
  const userOptions = useUserOptions({
    officeId: officeFieldState?.value,
    withAllUsersOption: true,
  });

  const formValues = formProp.values;
  const reset = formProp.form.reset;

  const officeName = useOffice(formValues.officeId)?.name ?? null;
  const user = useUser(formValues.userId);
  const userName = user ? getUserName(user) : null;

  const me = useCurrentUser();
  const officeChoices = useOfficeOptions({ userId: me.id });

  const preSubmitCheck = () => {
    const hasErrors = errors && Object.keys(errors).length > 0;
    if (hasErrors) {
      setValidate(hasErrors);
    }
    return hasErrors;
  };

  const { id, status } = taskDetails;

  const toggleTaskStatus = (updateTask: UpdateTaskMutationFunction) => {
    if (!contactId) return;

    const newStatus =
      status === TaskStatus.Open ? TaskStatus.Closed : TaskStatus.Open;

    return updateTask({
      variables: {
        accountId: account.id,
        id,
        update: {
          status: newStatus,
        },
      },
    }).then(({ data, errors }) => {
      if (data && !errors?.length) {
        handleCloseModal();
      }
    });
  };

  const setEditMode = (isEdit: boolean) => {
    setIsEdit(isEdit);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <JustificationContainer
        direction="column"
        dataTestId={TEST_ID.CONTAINER}
        data-queryinflight={loading}
        {...rest}
      >
        <JustificationContainer
          align="end"
          justification="space-between"
          width="100%"
          margin={!isEdit ? [null, null, 's', null] : []}
        >
          <JustificationContainer align="end" gap="m" width="100%">
            <IconWrapper isEdit={isEdit}>
              <Field name="type">
                {({ input: { onChange, value }, meta: { error } }) => {
                  if (isEdit) {
                    return (
                      <DropdownContainer>
                        <Dropdown
                          disabled={disabledForm}
                          dataTestId={TEST_ID.TYPE_FIELD}
                          error={FormUtils.showError(error, validate)}
                          onChange={selectedOption => {
                            const { option } = selectedOption;
                            return onChange(
                              option ? option.payload : TaskType.Call,
                            );
                          }}
                          selectedOptionIdx={taskTypeOptions.findIndex(
                            option => option.payload === value,
                          )}
                          options={taskTypeOptions}
                        />
                      </DropdownContainer>
                    );
                  } else {
                    return iconForTypeInHeader(value);
                  }
                }}
              </Field>
            </IconWrapper>
            <Field name="title">
              {({ input, meta: { error } }) => {
                if (isEdit) {
                  return (
                    <Input
                      data-testid={TEST_ID.TASK_TITLE}
                      disabled={disabledForm}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, validate)
                          ? [error]
                          : undefined
                      }
                      {...viewingModeProps}
                      {...input}
                      width="100%"
                    />
                  );
                } else {
                  return (
                    <Heading4
                      variant={Variant.primary}
                      margin={[null]}
                      data-testid={TEST_ID.TASK_TITLE_TEXT}
                      {...viewingModeProps}
                    >
                      {stringSlicer(input.value, TITLE_MAX_LENGTH)}
                    </Heading4>
                  );
                }
              }}
            </Field>
          </JustificationContainer>
          <JustificationContainer margin={[null, null, null, 's']}>
            <UpdateFormButtons
              handleSubmit={() => {
                const hasErrors = preSubmitCheck();
                if (!hasErrors) {
                  void handleSubmit();
                }
              }}
              setEditMode={setEdit => {
                if (!setEdit) {
                  reset();
                }
                setEditMode(setEdit);
              }}
              editMode={isEdit}
              saveLoading={loading}
            />
          </JustificationContainer>
        </JustificationContainer>

        {isEdit ? (
          <DeadlineAndAssignContainer
            margin={['m', null]}
            align="end"
            width="100%"
            gap="s"
          >
            <JustificationContainer gap="s" align="end">
              <Field name="dueDateDate">
                {({ input: { value, onChange }, meta: { error } }) => {
                  const touched = dueDateDate !== value;
                  return (
                    <DateField
                      error={FormUtils.showError(error, touched)}
                      label={text.fields.date}
                      value={value}
                      inputComponentProps={{
                        disabled: loading,
                      }}
                      onChange={onChange}
                      name="dueDateDate"
                      data-testid={TEST_ID.DUE_DATE_DATE}
                    />
                  );
                }}
              </Field>

              <Field name="dueDateTime">
                {({ input: { onChange, value }, meta: { error } }) => (
                  <TimePicker
                    value={value}
                    disabled={disabledForm}
                    externalErrors={
                      FormUtils.showError(error, validate)
                        ? [FormUtils.showError(error, validate)]
                        : []
                    }
                    name="dueDateTime"
                    onChange={onChange}
                    dataTestId={TEST_ID.DUE_DATE_TIME}
                  />
                )}
              </Field>
            </JustificationContainer>
            <JustificationContainer gap="s" align="end" width="100%">
              <Field name="officeId">
                {({ input: { onChange, value }, meta: { error } }) => (
                  <Dropdown
                    dataTestId={TEST_ID.OFFICEID_FIELD}
                    disabled={disabledForm}
                    label={text.fields.userAndOfficeLabel}
                    error={FormUtils.showError(error, validate)}
                    onChange={(selectedOption: SelectedOption) => {
                      const { option } = selectedOption;
                      const payload = option ? option.payload : null;
                      const newOfficeId = payload ? payload.id : null;
                      onChange(newOfficeId);
                      change('userId', null);
                    }}
                    selectedOptionIdx={officeChoices.findIndex(office => {
                      if (value === null) return office.payload === null;
                      return office.payload?.id == value;
                    })}
                    options={officeChoices}
                  />
                )}
              </Field>

              <Field name="userId">
                {({ input: { onChange, value }, meta: { error } }) => (
                  <Dropdown
                    label={text.fields.userAndOfficeLabel}
                    dataTestId={TEST_ID.USERID_FIELD}
                    disabled={disabledForm}
                    error={FormUtils.showError(error, validate)}
                    onChange={(selectedOption: SelectedOption) => {
                      const { option } = selectedOption;
                      const payload = option ? option.payload : null;
                      onChange(payload?.id ? payload.id : null);
                    }}
                    selectedOptionIdx={userOptions.findIndex(user => {
                      if (value == null || value === '')
                        return user.key === 'no-selection';
                      return user.payload?.id == value;
                    })}
                    options={userOptions}
                  />
                )}
              </Field>
            </JustificationContainer>
          </DeadlineAndAssignContainer>
        ) : (
          <TaskInfoBlock
            contactName={null}
            officeName={officeName}
            userName={userName}
            dueDate={convertDateToServerDate(
              formValues.dueDateDate,
              formValues.dueDateTime,
            )}
          />
        )}

        <DescriptionContainer
          width="100%"
          direction="column"
          {...viewingModeProps}
        >
          <Field name="description">
            {({ input, meta: { submitSucceeded } }) => {
              if (isEdit) {
                return (
                  <Editor
                    dataTestId={TEST_ID.DESCRIPTION_TEXTAREA_FIELD}
                    disabled={disabledForm}
                    value={input.value}
                    placeholder="Hoe kan je deze taak omschrijven?"
                    onChange={value => input.onChange(value)}
                    shouldReset={submitSucceeded}
                  />
                );
              }

              return (
                <TaskDescription
                  data-testid={TEST_ID.DESCRIPTION_TEXT}
                  dangerouslySetInnerHTML={{
                    __html: input.value,
                  }}
                />
              );
            }}
          </Field>
        </DescriptionContainer>
        <JustificationContainer
          align="center"
          justification={!errorMsg ? 'end' : 'space-between'}
          width="100%"
        >
          {errorMsg && (
            <ErrorWrapper>
              <Alerts.SimpleText type={'error'} text={errorMsg} />
            </ErrorWrapper>
          )}
          {!isEdit && (
            <JustificationContainer justification="end">
              <Button
                data-testid={TEST_ID.CHANGE_STATUS_BUTTONS}
                onClick={() => toggleTaskStatus(updateTask)}
                loading={loading}
                appearance="secondary"
                size="medium"
                label={
                  status === TaskStatus.Open
                    ? text.changeStatusButtonClose
                    : text.changeStatusButtonOpen
                }
              />
            </JustificationContainer>
          )}
        </JustificationContainer>
      </JustificationContainer>
    </form>
  );
};

type WrapperProps = {
  isEdit: boolean;
};

const DeadlineAndAssignContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    ${theme.mq.lessThan('desktop')`
      flex-wrap: wrap;
    `}
  `,
);

const IconWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isEdit }) => {
    if (!isEdit) {
      return css`
        svg {
          font-size: 20px;
        }
      `;
    }
    return ``;
  }};
`;

const DropdownContainer = styled.div<{}>`
  display: inline-block;
  ${({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `};
`;

const DescriptionContainer = styled(JustificationContainer)<{}>`
  word-break: break-all;
  overflow-x: hidden;
`;

const TaskDescription = styled.div<{}>(
  ({ theme }) => css`
    border: 0;
    margin: ${theme.space('m')} 0;

    p {
      margin: 0;
    }
  `,
);

const ErrorWrapper = styled.div<{}>`
  > div {
    padding: 0;
  }
`;

export default ActualForm;
