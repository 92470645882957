import type { FormBuilder_Node } from '~/graphql/types';
import type { Issue } from '../../state/issues';
import getOutputBlocksCount from '../getOutputBlocksCount';

const text = {
  contactEmailMissing:
    "Er moet een contact e-mailadres in het formulier voorkomen, voeg het blok toe in een van de pagina's.",
};

/**
 * Returns miscellaneous issues (anything that does not belong in the form or node fields)
 * @param {Array<FormBuilder_Node>} nodes - nodes
 * @param {boolean} contactEmailMissing - contact email block is not used
 */
const getMiscIssues = ({
  nodes,
  contactEmailMissing,
}: {
  nodes: Array<FormBuilder_Node>;
  contactEmailMissing: boolean;
}): Array<Issue> => {
  const outputBlocksCount = getOutputBlocksCount(nodes);

  const issues: Array<Issue> = [];

  if (outputBlocksCount > 0 && contactEmailMissing) {
    issues.push({
      level: 'error',
      message: text.contactEmailMissing,
    });
  }

  return issues;
};

export default getMiscIssues;
