import React, { useState } from 'react';
import {
  RouteComponentProps,
  useLocation,
  navigate,
} from '@gatsbyjs/reach-router';
import styled, { css } from 'styled-components';
import DHRouter from '~/components/atom/DHRouter';
import WizardLayout from '~/components/molecule/WizardLayout';
import StepList from '~/components/organism/StepList';
import SelectTemplate from './components/SelectTemplate';
import TEST_ID from './index.testid';
import { withOverlay } from '~/components/organism/ModalsV2/Overlay';

import DescribeForm from './components/DescribeForm';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useBrandSettings from '~/hooks/useBrandSettings';
import { useImportFormMutation, type FormTemplate } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';

export type Props = {
  onClose: () => void;
} & RouteComponentProps;

export const LINK_PREFIX = '/-/forms/wizard';

const steps = [
  {
    id: 'select-template',
    label: 'Kies een template',
    disabled: false,
  },
  {
    id: 'add-form-name',
    label: 'Naam toevoegen',
    disabled: false,
  },
];

const Wizard: React.FCC<
  Omit<Props, 'onClose'> & {
    onClose: () => false | void | undefined;
  }
> = ({ onClose }) => {
  const location = useLocation();
  const account = useCurrentAccount();
  const brandSettings = useBrandSettings();
  const addToast = useAddToast();
  const [importForm, { loading }] = useImportFormMutation();
  const [template, setTemplate] = useState<FormTemplate | null>(null);

  const onCreate = async (name: string, description: string = '') => {
    if (template !== null && brandSettings) {
      const { data, errors } = await importForm({
        variables: {
          accountId: account.id,
          token: template.token,
          overwrite: {
            name: name !== '' ? name : undefined,
            description: description !== '' ? description : undefined,
          },
        },
      });

      if (errors && errors.length !== 0) {
        return addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
      }

      if (data && data.copyForm.id) {
        return navigate(`/-/forms/${data.copyForm.id}`);
      }
    }

    return;
  };

  // Since there are only 2 steps, this is fine.🤓
  const currentStep = location.pathname.includes('add-form-name') ? 1 : 0;

  return (
    <StyledLayout
      data-testid={TEST_ID.CONTAINER}
      layoutClassName="layout"
      header="Nieuw Formulier"
    >
      <NonUpdatingStepIndicator currentStep={currentStep} />
      <DHRouter>
        <SelectTemplate
          default
          path="/*"
          onClose={onClose}
          onSelect={template => {
            setTemplate(template);
            // navigate to the next step
            return navigate(LINK_PREFIX + '/add-form-name');
          }}
          template={template}
        />
        {template && (
          <DescribeForm
            path="/add-form-name"
            onClose={onClose}
            onCreate={onCreate}
            template={template}
            loading={loading}
          />
        )}
      </DHRouter>
    </StyledLayout>
  );
};

const StyledLayout = styled(WizardLayout)<{}>(
  ({ theme }) => css`
    /* This is the fix for the form templates width.
       270px is the width of the wizard "MasterDetail" */

    .layout {
      grid-template-columns: 270px auto;

      ${theme.mq.lessThan('mobile')`
         grid-template-columns: 1fr;
       `}
    }
  `,
);

const NonUpdatingStepIndicator: React.FCC<{ currentStep: number }> = React.memo(
  ({ currentStep }) => <StepList currentStep={currentStep} steps={steps} />,
);

export default withOverlay<Props>(Wizard);
