/**
 * This is a catalog for generic texts that are shared over multiple components.
 */

export default {
  genericSuccessMessage: 'Wijzigingen opgeslagen',
  genericUnknownErrorModalTitle: 'Oeps!',
  genericUnknownErrorMessage:
    'Er is een onverwachte fout opgetreden. Probeer het alsjeblieft nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  genericUnknownErrorMessageShort: 'Er is een onverwachte fout opgetreden',
  imageUploadErrorMessage:
    'Het uploaden van afbeelding is mislukt. Gebruik een gif, jpeg, jpg, png of svg met een maximale grootte van 5mb',
  noResults: 'Er zijn geen resultaten',
  requiredField: 'Dit is een verplicht veld',
  tryAgainLabel: 'Probeer opnieuw',
  unauthorisedErrorMessage: 'Je hebt onvoldoende rechten',

  yes: 'Ja',
  no: 'Nee',
  confirm: 'Bevestigen',
  cancel: 'Annuleren',

  // Tags
  dirtyTagInputField:
    'Je hebt een tag ingevoerd, klik op het plusje om deze tag toe te voegen',

  // **** ACTIVITIES ****
  addTaskTabLabel: 'Nieuwe taak',
  addLogTabLabel: 'Log activiteit',
  descriptionTabLabel: 'Taak omschrijving',

  // **** FIELDS ****
  // Phone number
  phoneLabel: 'Telefoonnummer',
  noPhone: 'Voer een telefoonnummer in',
  invalidPhone: 'Voer een geldig telefoonnummer in',

  // Email
  emailLabel: 'E-mail',
  noEmail: 'Voer een e-mailadres in',
  invalidEmail: 'Voer een geldig e-mailadres in',

  // Name
  nameLabel: 'Naam',
  noName: 'Voer een naam in',

  // Address
  addressLabel: 'Adres',
  noAddress: 'Voer een adres in',

  // Street
  streetLabel: 'Straat',

  // House number
  houseNumberLabel: 'Huisnummer',
  houseAdditionLabel: 'Toevoeging',
  noHouseNumber: 'Voer een huisnummer in',

  // Postal code
  postalCodeLabel: 'Postcode',
  invalidPostalCode: 'Voer een geldige postcode in',
  noPostalCode: 'Voer een postcode in',

  // City
  cityLabel: 'Plaats',
  noCity: 'Voer een plaatsnaam in',

  // Password
  oldPasswordLabel: 'Huidige wachtwoord',
  newPasswordLabel: 'Nieuw wachtwoord',
  newPasswordRepeatLabel: 'Nieuw wachtwoord bevestigen',
  passwordLabel: 'Wachtwoord',
  passwordRepeatLabel: 'Wachtwoord bevestigen',
  noPassword: 'Voer een wachtwoord in',
  noPasswordRepeat: 'Voer je wachtwoord nogmaals in',
  noOldPassword: 'Voer je huidige wachtwoord in',
  invalidPassword: 'Het wachtwoord voldoet niet aan de regels',
  passwordsDoNotMatch: 'De wachtwoorden moeten overeenkomen',
  wrongOldPassword: 'Het huidige wachtwoord klopt niet',

  // URL
  invalidURL: 'Ongeldige URL. De waarde moet beginnen met http:// of https://',

  // Office
  officeLabel: 'Vestiging',
  officesLabel: 'Vestigingen',
  mainOfficeLabel: 'Hoofdvestiging',
  noOffice: 'Selecteer vestiging',

  // Role
  roleLabel: 'Rechten',

  // User
  userLabel: 'Gebruiker',
  usersLabel: 'Gebruikers',
  noUser: 'Selecteer gebruiker',

  // Account
  accountLabel: 'Account',

  // E-mail templates
  emailTemplateLabel: 'E-mail template',
  emailTemplatesLabel: 'E-mail templates',

  // Dates
  invalidDate: 'Voer een geldige datum in',

  // Tags
  tagLabel: 'Tag',
  tagsLabel: 'Tags',

  // Flows
  flowLabel: 'Flow',
  flowsLabel: 'Automation',

  flows: {
    variableTypeLabel: 'Waardetype',
    pointerVariable: 'Referentie',
    primitiveVariable: 'Waarde',
    pointerVariableLabel: 'Selecteer stap',
    primitiveValueLabel: 'Vul in ...',
    pointerFieldLabel: 'Veld in variabele',
    conditionTypeLabel: 'Conditietype',
    fieldLabel: 'Selecteer veld',
    operatorLabel: 'Operatie',
  },

  // Value reports
  valueReports: {
    nameLabel: 'Naam',
    noName: 'Voer een naam in',
    algasIdHeader: 'Identificatienummer',
    noAlgasId: 'Voer een identificatienummer in',
    sendContactEmailLabel: 'Verstuur aanvrager het rapport',
    sendAgentEmailLabel: 'Ontvang lead per e-mail',
  },

  // subscription
  subscriptionLabel: 'Abonnement',

  // invoices
  invoicesLabel: 'Facturen',
  // wallet
  walletLabel: 'Wallet',
  // brand
  brandLabel: 'Huisstijl',

  // automation
  automationLabel: 'Automation',

  // widget
  widgetLabel: 'Widget',

  // costs money warning
  mayIncreaseCostsWarning: (type: string) =>
    `Let op: ${type} toevoegen kan extra kosten met zich meebrengen, controleer je abonnement.`,
};
