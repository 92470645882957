import { memoize } from 'underscore';

import {
  SortDirection,
  type GetFormsQueryVariables,
  type GetFormsQuery,
} from '~/graphql/types';
import { ascend, descend, isNil, path } from 'ramda';
import { SortSettings } from '~/hooks/useSortSettings';

export type FormListData = {
  id: string;
  name: string;
  description?: string | null;
  shared: {
    enabled: boolean;
    id: string;
  };
};

export const composeListData = (
  items: GetFormsQuery['getForms'] | null | undefined,
  sortSettings: SortSettings<Array<string>>,
): Array<FormListData> => {
  if (items == null) return [];

  // @ts-ignore I couldn't explain TS what we're doing here, so ignore for now.
  const sorter = (sortSettings.desc ? descend : ascend)(path(sortSettings.id));

  return items
    .map(({ name, description, id, enabled, share }) => ({
      id,
      name,
      formDescription: description,
      enabled,
      shared: {
        enabled: !isNil(share),
        id,
      },
    }))
    .sort((a, b) => sorter(a, b));
};

const memoizedComposeFormListData = memoize(
  composeListData,
  (
    items: Array<any>,
    variables: GetFormsQueryVariables,
    version: string,
    sortSettings: SortSettings<Array<string>>,
  ) => {
    const result = `${version}*@@*${items.length}*@@*${sortSettings.id}*@@*${
      sortSettings.desc ? SortDirection.Desc : SortDirection.Asc
    }`;

    return result;
  },
);

export default memoizedComposeFormListData;
