import React from 'react';

import useInfinitePagination from '~/components/bad/util/useInfinitePagination';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import composeInvoiceListData from '../../util/composeInvoiceListData';
import invoiceListColumns from '../../util/invoiceListColumns';
import InvoiceListTable from '../InvoiceListTable';
import PaginationContainer from '../PaginationContainer';
import TEST_ID from './index.testid';
import { useGetInvoicesQuery } from '~/graphql/types';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import PaginationButton from '~/components/molecule/Pagination/components/PaginationButton';
import AnimatedBlock from '~/components/atom/AnimatedBlock';

type Props = {
  limit: number;
};
const StandardInvoices: React.FCC<Props> = ({ limit }) => {
  const account = useCurrentAccount();
  const [currentPage, goToPreviousPage, goToNextPage, grabItems, knownPages] =
    useInfinitePagination(limit);

  const variables = { accountId: account.id, limit };

  const { data, loading, networkStatus, fetchMore, error } =
    useGetInvoicesQuery({
      variables,
      notifyOnNetworkStatusChange: true,
    });

  const invoices = data?.getInvoices?.items;

  if (!loading && (error || !invoices)) {
    return <AppErrorScreen />;
  }

  const nextToken = data?.getInvoices?.nextToken;

  const loadMore = () =>
    fetchMore({
      variables: {
        ...variables,
        nextToken: nextToken || null,
      },
    });

  return (
    <>
      <AnimatedBlock manualLayout>
        <InvoiceListTable
          loading={loading}
          networkStatus={networkStatus}
          columns={invoiceListColumns()}
          data={composeInvoiceListData(grabItems(invoices), variables)}
          dataTestId={TEST_ID.CONTAINER}
        />
      </AnimatedBlock>
      <PaginationContainer>
        <PaginationButton
          disabled={knownPages === currentPage && nextToken == null}
          icon="chevron-right"
          dataTestId={TEST_ID.GO_TO_NEXT_BUTTON}
          onClick={() => {
            if (knownPages === currentPage && nextToken != null) {
              loadMore()
                .then(() => {
                  goToNextPage();
                })
                .catch(e => {
                  throw Error(`${cleanedFilename(__filename)} | Error ${e}`);
                });
            } else {
              goToNextPage();
            }
          }}
        />
        <PaginationButton
          disabled={currentPage === 1}
          icon="chevron-left"
          dataTestId={TEST_ID.GO_TO_PREVIOUS_BUTTON}
          onClick={() => {
            goToPreviousPage();
          }}
        />
      </PaginationContainer>
    </>
  );
};

export default StandardInvoices;
