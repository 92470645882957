import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import MasonryGrid from '~/components/organism/MasonryGrid';
import SelectBlock from '~/components/molecule/SelectBlock';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldList,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import {
  WidgetSettingsAppConfigurationItem,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useApp from '~/hooks/useApp';
import useWizardStep from '~/hooks/useWizardStep';
import ErrorScreen from '~/components/page/ErrorScreen';
import TEST_ID from './index.testid';
import { OutputMap } from '../..';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Loading from '~/components/atom/Loading';
import getUWAppConfigurationItems from '~/util/getUWAppConfigurationItems';
import getUpdateForAppStatus from '../../Apps/components/AppStatusOverview/utils/getUpdateForAppStatus';
import { ExtendedAppConfig } from '~/hooks/useApps';
import { isEmpty, isNil } from 'ramda';
import TextButton from '~/components/atom/TextButton';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorReporter from '~/hooks/useErrorReporter';

export const id = 'GenericWidgetConfiguration';
export const title = 'Diensten toevoegen aan de widget';

export type OutputType = {
  type: typeof id;
  selectedApps: OutputFieldList<WidgetSettingsAppConfigurationItem>;
};

const text = {
  successMsg: 'Apps zijn ingeschakeld in de Widget',
  category: 'Ingeschakelde apps',
  pageDescription: (
    <>
      Laat de bezoeker van jouw website gemakkelijk offertes opvragen van
      dienstverleners die nodig zijn in de woonreis.
      <br />
      <br />
      Kies hier welke categorieën aan dienstverleners je beschikbaar wil maken
      in de widget. Dit kan je later aanpassen in de widget instellingen.
    </>
  ),
  selectAll: 'Alles selecteren',
  deselectAll: 'Alles deselecteren',
};

export const Component: React.FCC<WizardStepProps> = props => {
  const { app, loading } = useApp(props.step.metadata?.typename);

  if (loading) {
    return <Loading />;
  }

  if (!props.step.metadata || !app) {
    return <ErrorScreen />;
  }

  return <Child {...props} app={app} />;
};

const Child: React.FCC<WizardStepProps & { app: ExtendedAppConfig }> = ({
  step,
  outputMap,
  app,
}) => {
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType | undefined;

  const addToast = useAddToast();
  const [enabledApps, setEnabledApps] = useState<
    Array<WidgetSettingsAppConfigurationItem>
  >(
    currentOutput && 'selectedApps' in currentOutput
      ? currentOutput.selectedApps.value
      : [],
  );

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const items = getUWAppConfigurationItems(app.appStatus);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    if (items.length === enabledApps.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [items, enabledApps]);

  const toggleSelection = () => {
    if (!allSelected) {
      setEnabledApps(items);
      setAllSelected(true);
    } else {
      setEnabledApps([]);
      setAllSelected(false);
    }
  };
  useEffect(() => {
    const initiallyEnabled = items.filter(item => item.visible === true);

    if (isEmpty(enabledApps)) {
      setEnabledApps(initiallyEnabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;
      const updatedItems = items.map(item => {
        const isEnabled = updatedOutput.selectedApps.value.some(
          app => app.id === item.id,
        );

        return {
          ...item,
          visible: isEnabled,
        };
      });
      if (step.metadata?.typename) {
        if (isNil(app.appStatus)) {
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | app.appStatus is null or undefined`,
            ),
          );
          return outputMap[id];
        }

        const update = getUpdateForAppStatus({
          completed: false,
          currentAppStatus: app.appStatus,
          updatedItems,
        });

        const { data, errors } = await updateAppStatus({
          variables: {
            accountId,
            update,
          },
        });

        if (data && !errors?.length) {
          addToast([formatToastMessage(text.successMsg, 'success')]);
        }

        if (errors && errors.length > 0) {
          reporter.captureException(
            new Error(
              `${cleanedFilename(__filename)} | updateAppStatus failed`,
            ),
          );
        }
      }

      const output: OutputType = {
        type: 'GenericWidgetConfiguration',
        selectedApps: {
          category: text.category,
          type: 'list',
          value: updatedItems
            .filter(item => item.visible === true)
            .map(item => ({
              ...item,
              label: item.name,
            })),
        },
      };
      return output;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, app, step.metadata?.typename, updateAppStatus],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  useEffect(() => {
    const output: OutputType = {
      type: 'GenericWidgetConfiguration',
      selectedApps: {
        category: text.category,
        type: 'list',
        value: enabledApps.map(selectedApp => ({
          ...selectedApp,
          label: selectedApp.name,
        })),
      },
    };
    // Step ready to go from mount so free it up
    api.free(output);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledApps]);

  const [, api] = useWizardStep(step.id, stepOptions);

  return (
    <>
      <Body margin={[null]}>{text.pageDescription}</Body>
      <TextButton
        icon={allSelected ? 'check-square' : 'square'}
        size="medium"
        margin={['m', null, null, null]}
        padding={[null]}
        onClick={toggleSelection}
        label={allSelected ? text.deselectAll : text.selectAll}
      />
      <Container>
        {items.map(item => {
          const isSelected = enabledApps.some(app => app.id === item.id);
          return (
            <SelectBlock
              key={item.id}
              type="checkbox"
              checked={isSelected}
              data-checked={isSelected}
              checkboxTitle={item.name}
              dataTestId={TEST_ID.TEMPLATE}
              data-objectid={item.id}
              onClick={() => {
                if (isSelected) {
                  setEnabledApps(
                    enabledApps.filter(
                      selectedApp => selectedApp.id !== item.id,
                    ),
                  );
                } else {
                  setEnabledApps([...enabledApps, item]);
                }
              }}
            />
          );
        })}
      </Container>
    </>
  );
};

const Container = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default {
  id,
  title,
};
