import { lensIndex, over, difference, lensPath } from 'ramda';
import { selector, selectorFamily } from 'recoil';
import type {
  FormBuilder_ScreenNode,
  FormBuilder_Event,
  FormBuilder_Event_Field,
  FormBuilder_NodeFragment,
  FormBuilder_ScreenNodeFragment,
} from '~/graphql/types';
import isRecoilDefaultValue from '~/typeguards/isRecoilDefaultValue';
import { formState } from '.';
import getPreviousNodes from '../utils/getPreviousNodes';
import getOptionListsMap from '../utils/getOptionListMap';
import isSelectTypeBlock, {
  type SelectBlockType,
} from '../utils/typeguards/isSelectTypeBlock';
import getBlockKeyFromPointer from '../utils/getBlockKeyFromPointer';
import type { InputBlockType } from '../utils/typeguards/isInputTypeBlock';
import isBlockWithOutput from '../utils/typeguards/isBlockWithOutput';

export const nodesSelector = selector<Array<FormBuilder_NodeFragment>>({
  key: 'formBuilder/nodesSelector',
  get: ({ get }) => {
    const form = get(formState);
    if (!form) return [];

    return form.nodes ?? [];
  },

  set: ({ get, set }, nodes) => {
    if (isRecoilDefaultValue(nodes)) return;

    const prevForm = get(formState);
    set(formState, { ...prevForm, nodes });
  },
});

export const nodeById = selectorFamily<FormBuilder_NodeFragment | null, string>(
  {
    key: 'formBuilder/nodeById',
    get:
      nodeId =>
      ({ get }) => {
        const form = get(formState);
        if (!form) return null;
        const found = form.nodes.find(({ id }) => id === nodeId);

        return found ?? null;
      },

    set:
      nodeId =>
      ({ get, set }, node) => {
        if (isRecoilDefaultValue(node) || !node) return;
        const prevForm = get(formState);
        if (!prevForm) return;

        const index = prevForm.nodes.findIndex(({ id }) => id === nodeId);
        const prevNode = prevForm.nodes[index];

        const lens = lensIndex<FormBuilder_NodeFragment>(index);
        const updatedNodes = over(lens, () => node, prevForm.nodes);
        let nextOptionLists = [...prevForm.optionLists];

        // Check if we need to remove any option lists.
        if (
          node.__typename === 'FormBuilder_ScreenNode' &&
          prevNode.__typename === 'FormBuilder_ScreenNode'
        ) {
          // Blocks have changed
          if (node.blocks.length < prevNode.blocks.length) {
            // Finds the set of all elements in the first list not contained in the second list.
            const delta = difference(prevNode.blocks, node.blocks);
            const removedSelectTypeBlocks = delta.filter(isSelectTypeBlock);
            const optionListsMap = getOptionListsMap(
              updatedNodes,
              nextOptionLists,
            );

            for (const removedSelectTypeBlock of removedSelectTypeBlocks) {
              if (
                optionListsMap[removedSelectTypeBlock.optionListId]?.length ===
                0
              ) {
                // No more other nodes use this option list so we can remove it.
                nextOptionLists = prevForm.optionLists.filter(
                  ({ id }) => id !== removedSelectTypeBlock.optionListId,
                );
              }
            }
          }
        }

        return set(formState, {
          ...prevForm,
          nodes: updatedNodes,
          optionLists: nextOptionLists,
        });
      },
  },
);

export const previousNodesById = selectorFamily<
  Array<FormBuilder_ScreenNodeFragment>,
  string
>({
  key: 'formBuilder/previousNodesById',
  get:
    nodeId =>
    ({ get }) => {
      const form = get(formState);
      if (!form) return [];

      const allIncoming = getPreviousNodes(form.nodes, nodeId).filter(
        (node): node is FormBuilder_ScreenNodeFragment =>
          node.__typename === 'FormBuilder_ScreenNode',
      );

      return allIncoming;
    },
});

export const pointerSelector = selectorFamily<string, [string, string]>({
  key: 'formBuilder/pointerSelector',
  get:
    pointer =>
    ({ get }) => {
      const form = get(formState);
      if (!form) return 'No Form';

      const node = get(nodeById(pointer[0])) as FormBuilder_ScreenNode;

      const block = node?.blocks
        .filter((block): block is SelectBlockType | InputBlockType =>
          isBlockWithOutput(block),
        )
        .find(({ key }) => key === getBlockKeyFromPointer(pointer));

      return (
        `${node?.name || 'Onbekende pagina'} > ${block?.label?.nl ?? block?.label?.en}` ||
        'Onbekend'
      );
    },
});

export const eventsState = selector<Array<FormBuilder_Event>>({
  key: 'formBuilder/eventsSelector',
  get: ({ get }) => {
    const form = get(formState);

    return form?.events ?? [];
  },

  set: ({ get, set }, events) => {
    if (isRecoilDefaultValue(events)) return;

    const prevForm = get(formState);
    set(formState, { ...prevForm, events });
  },
});

export const eventById = selectorFamily<FormBuilder_Event | null, string>({
  key: 'formBuilder/eventById',
  get:
    eventId =>
    ({ get }) => {
      const form = get(formState);
      if (!form) return null;

      const found = form.events.find(({ id }) => id === eventId);

      return found ?? null;
    },

  set:
    eventId =>
    ({ get, set }, event) => {
      if (isRecoilDefaultValue(event)) return;
      const prevForm = get(formState);
      if (!prevForm) return;

      const index = prevForm.events.findIndex(({ id }) => id === eventId);
      const lens = lensIndex(index);

      const updatedEvents = over(lens, () => event, prevForm.events);

      return set(formState, { ...prevForm, events: updatedEvents });
    },
});

export const fieldById = selectorFamily<
  FormBuilder_Event_Field | null,
  { eventId: string; fieldId: string }
>({
  key: 'formBuilder/fieldById',
  get:
    ({ eventId, fieldId }) =>
    ({ get }) => {
      const form = get(formState);
      if (!form) return null;

      const found = form.events.find(({ id }) => id === eventId);
      const field = found?.fields.find(({ key }) => key === fieldId);

      return field ?? null;
    },

  set:
    ({ eventId, fieldId }) =>
    ({ get, set }, event) => {
      if (isRecoilDefaultValue(event)) return;
      const prevForm = get(formState);
      if (!prevForm) return;

      const index = prevForm.events.findIndex(({ id }) => id === eventId);
      const fieldIndex = prevForm.events[index].fields.findIndex(
        ({ key }) => key === fieldId,
      );
      const lens = lensPath([index, 'fields', fieldIndex]);

      const updatedEvents = over(lens, () => event, prevForm.events);

      return set(formState, { ...prevForm, events: updatedEvents });
    },
});
