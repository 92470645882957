import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  AccountStatus,
  GetSessionHydrationQuery,
  AccountModification,
  ImpersonationViewingMode,
  useGetSessionHydrationQuery,
} from '~/graphql/types';

import AccountPaused from '~/components/page/AccountStatus/AccountPaused';
import AccountCancelled from '~/components/page/AccountStatus/AccountCancelled';
import AccountModificationInProgress from '~/components/page/AccountStatus/AccountModification';
import ErrorScreen from '~/components/page/ErrorScreen';
import { WithNavigationFrame as NotFound } from '~/components/page/404';
import ModalContextProvider from '~/contexts/ModalContextProvider';
import { assertNever } from '~/util/assertion';
import AccountLocked from '~/components/page/AccountStatus/AccountLocked';
import { Redirect, type RouteComponentProps } from '@gatsbyjs/reach-router';
import DHRouter from '~/components/atom/DHRouter';
import Tasks from '~/components/page/Tasks';
import Contacts from '~/components/page/Contacts';
import Automation from '~/components/page/Automation';
import Apps from '~/components/page/Apps';
import Settings from '~/components/page/Settings';
import External from '~/components/page/External';
import CreateActivities from '~/components/page/CreateActivities';
import useBeforeUnload from '~/hooks/useBeforeUnload';
import ToastsContainer from '~/components/organism/ToastsContainer';
import Dashboard from '~/components/page/Dashboard';
import SessionHydrationContext from '~/contexts/SessionHydrationContext';
import Accounts from '~/components/page/Accounts';
import { AuthContext } from '~/Root/Auth';
import ImpersonationContainer from '~/components/organism/ImpersonationContainer';
import { isNil } from 'ramda';
import NavigationFrame from '~/components/organism/NavigationFrame';
import useErrorReporter from '~/hooks/useErrorReporter';
import { useUnleashContext } from '@unleash/proxy-client-react';
import Wizard from '~/components/organism/Wizard';
import useFireTrackingEvent from './hooks/useFireTrackingEvent';
import ViewingMode from './contexts/ViewingMode';
import DemoStrapiPages from './components/page/DemoStrapiPages';
import SetupSubscriptionPage from './components/page/Settings/Subscription/components/SetupSubscriptionPage';
import OnboardingWizardPage from './components/page/OnboardingWizardPage';
import isPopupWindow from './util/isPopupWindow';
import ScrollToTop from './components/atom/ScrollToTop';
import Loading from './components/atom/Loading';
import useDHFlag from './hooks/useDHFlag';
import Widget from './components/page/Widget';
import TooltipLayer from './components/TooltipLayer';
import Wrapper from './Wrapper';
import Forms from './components/page/Forms';
import DnDContext from './contexts/DnDContext';
import useMarkerIo from './hooks/useMarkerIo';
import CommandPaletteProvider from './components/organism/CommandPaletteProvider';

type Props = RouteComponentProps & {
  accountId: string;
};

const MainApp: React.FCC<Props> = ({ accountId }) => {
  const updateUnleashContext = useUnleashContext();
  const fireTrackingEvent = useFireTrackingEvent();
  const markerIOWidget = useMarkerIo();

  const { loading, error, data, refetch } = useGetSessionHydrationQuery({
    variables: { accountId },
  });
  useBeforeUnload('Let op: openstaande wijzigingen');

  useEffect(() => {
    if (data && data.getSessionHydration.me) {
      void updateUnleashContext({ userId: data.getSessionHydration.me.id });

      markerIOWidget?.setCustomData({
        userId: data.getSessionHydration.me.id,
        accountId: data.getSessionHydration.account.id,
        accountName: data.getSessionHydration.account.name,
      });
    }
  }, [data, markerIOWidget, updateUnleashContext]);

  useEffect(() => {
    const width = window.innerWidth || document.body.clientWidth;
    const height = window.innerHeight || document.body.clientHeight;

    const _width = Math.round(width / 100) * 100;
    const _height = Math.round(height / 100) * 100;

    const size = `${_width}x${_height}`;

    fireTrackingEvent({
      event: 'screenSize',
      size: size,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (!data && error) return <ErrorScreen />;
  if (!data) return <NotFound />;

  const { account } = data.getSessionHydration;

  return (
    <SessionHydrationContext.Provider
      value={{ data: data.getSessionHydration, refetch }}
    >
      <ViewingMode.Provider
        value={{
          viewingMode:
            data.getSessionHydration.impersonationOptions?.viewingMode ||
            ImpersonationViewingMode.Complete,
        }}
      >
        <Wrapper>
          <TooltipLayer />
          <AccountPageByStatus
            account={account}
            ongoingAccountModification={
              account.ongoingAccountModification ?? null
            }
          />
        </Wrapper>
        <ToastsContainer />
        {!isNil(data.getSessionHydration.impersonatedBy) && (
          <ImpersonationContainer />
        )}
      </ViewingMode.Provider>
    </SessionHydrationContext.Provider>
  );
};

/**
 * Returns the appropriate account page for the account status.
 *
 * @param accountStatus AccountStatus
 */
const AccountPageByStatus = ({
  account,
  ongoingAccountModification,
}: {
  account: GetSessionHydrationQuery['getSessionHydration']['account'];
  ongoingAccountModification: AccountModification | null;
}): JSX.Element => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const { isDHAdmin } = useContext(AuthContext);
  const isDeveloper = useDHFlag('is-developer');
  const status = account.status ?? AccountStatus.Active;

  // This initialises the error reporter, ensuring contexts get set when they are available.
  useErrorReporter();

  switch (status) {
    case AccountStatus.Incomplete:
      return (
        <DHRouter>
          <SetupSubscriptionPage path="/" />
        </DHRouter>
      );

    case AccountStatus.Locked:
      return (
        <DHRouter>
          <AccountLocked path="/locked" />
        </DHRouter>
      );

    case AccountStatus.Paused:
      return (
        <DHRouter>
          <AccountPaused path="/paused" />
        </DHRouter>
      );

    case AccountStatus.Cancelled:
    case AccountStatus.Deleted:
      return (
        <DHRouter>
          <AccountCancelled path="/cancelled" />
        </DHRouter>
      );

    case AccountStatus.Active: {
      if (
        !isNil(ongoingAccountModification) &&
        ongoingAccountModification === AccountModification.UpdatePlan
      ) {
        return <AccountModificationInProgress default />;
      }

      const isOnboardingCompleted =
        account.AccountSettings?.metadata?.setup?.completed === true;
      const isInPopupWindow = isPopupWindow();

      return (
        <ModalContextProvider>
          <Wizard header="Global wizard" id="onboarding-wizard" steps={[]}>
            {!isOnboardingCompleted && !isInPopupWindow ? (
              <DHRouter>
                <OnboardingWizardPage default path="/" />
              </DHRouter>
            ) : (
              <DnDContext>
                <NavigationFrame ref={contentContainerRef}>
                  <DHRouter>
                    <Dashboard path="/" />
                    <Tasks path="/tasks/*" />
                    <Contacts path="/contacts/*" />
                    <Automation path="/automation/*" />
                    <Apps path="/apps/*" />
                    <Widget path="/widget/*" />
                    <Forms path="/forms/*" />
                    <Settings path="/settings/*" />
                    <External path="/external/*" />
                    <CreateActivities path="/createActivities" />
                    <DemoStrapiPages path="/strapi/:slug" />

                    {isDHAdmin && <Accounts path="/accounts/*" />}
                    {(isDHAdmin || isDeveloper) && (
                      <Redirect
                        from="/experimental/widget-url-builder"
                        to="/widget/link-builder"
                        noThrow
                      />
                    )}
                    <NotFound default />
                  </DHRouter>
                  <ScrollToTop containerRef={contentContainerRef} />
                  <CommandPaletteProvider />
                </NavigationFrame>
              </DnDContext>
            )}
          </Wizard>
        </ModalContextProvider>
      );
    }
    default:
      return assertNever(status);
  }
};

const LoadingContainer = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default MainApp;
