import React from 'react';
import styled from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import BillingAddress from '~/components/molecule/BillingAddress';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import {
  Body,
  Heading4,
  Heading5,
  Variant,
} from '~/components/atom/Typography';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import { useGetBillingDetailsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOnUpdateBillingDetails from './hooks/useOnUpdateBillingDetails';
import TextButton from '~/components/atom/TextButton';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Divider from '~/components/atom/Divider';
import TEST_ID from './index.testid';

export type Props = {};

const text = {
  noBillingSetup:
    'Jouw huidige abonnement heeft geen ondersteuning voor facturatie gegevens.',
  paymentsTitle: 'Betaalmethode',
  changeBillingDetails: 'Wijzig factuurgegevens',
  showInvoices: 'Bekijk facturen',
  emailLabel: 'E-mail voor facturatie',
};

const BillingDetails: React.FC<Props> = ({ ...rest }) => {
  const account = useCurrentAccount();
  const onUpdateBillingDetails = useOnUpdateBillingDetails({
    onComplete: () => {},
  });
  const { data, networkStatus, error } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });

  if (isInitialLoadStatus(networkStatus)) {
    // If there are no overages we do not want to show anything, so also don't show loading
    return <DatHuisLoading />;
  }

  if (error) {
    return <AppErrorScreen inline setBackgroundColor={false} />;
  }

  if (!data?.getBillingDetails) {
    return (
      <Container data-testid={TEST_ID.CONTAINER} {...rest}>
        <Body variant={Variant.primary} size="base">
          {text.noBillingSetup}
        </Body>
      </Container>
    );
  }

  return (
    <>
      <Container data-testid={TEST_ID.CONTAINER} {...rest}>
        <Heading4 variant={Variant.primary} margin={[null]}>
          {data.getBillingDetails.name}
        </Heading4>
        {data.getBillingDetails.address && (
          <BillingAddress
            address={data.getBillingDetails.address}
            dataTestId={TEST_ID.BILLING_ADDRESS}
          />
        )}
        <Heading5 margin={[null]}>{text.emailLabel}</Heading5>
        <Body margin={[null]} dataTestId={TEST_ID.BILLING_EMAIL}>
          {data.getBillingDetails.email}
        </Body>
        <JustificationContainer padding={['s', null]}>
          <TextButton
            padding={[null]}
            label={text.changeBillingDetails}
            onClick={onUpdateBillingDetails}
            dataTestId={TEST_ID.UPDATE_BILLING_TRIGGER}
          />
        </JustificationContainer>
        <Button
          label={text.showInvoices}
          onClick={() => navigate('/-/settings/invoices')}
          size="medium"
          dataTestId={TEST_ID.GO_INVOICES}
        />
      </Container>
      <Divider margin={['s', null]} />
    </>
  );
};

const Container = styled.div<{}>``;

export default BillingDetails;
