import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MiniMap, Node } from 'reactflow';
import Breadcrumbs from '~/components/molecule/ContentContainer/components/Breadcrumbs';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Toolbar from '~/components/molecule/Toolbar';
import AsideContainer from '../AsideContainer';
import Button from '~/components/atom/Button';
import ControlHUD from '../../../../../../../organism/ControlHUD';
import { flowNameSelector } from '~/components/page/Automation/v2/state/flowSettings';
import { getCardValues } from '../nodeTypes/components/CardTemplate/utils/getCardValues';
import { NodeData } from '../nodeTypes/components/CardTemplate';
import { ClientFlowAction } from '~/graphql/types.client';
import flowChanges from '~/components/page/Automation/v2/state/flowChanges';
import checkFlowHasChanges from '../../utils/checkFlowHasChanges';
import TEST_ID from './index.testid';
import { useLocation } from '@gatsbyjs/reach-router';
import { REACT_FLOW_MINI_MAP } from '../../constants/reactFlowLayers';
import flowIssues from '~/components/page/Automation/v2/state/flowIssues';
import { clone, isEmpty, isNil } from 'ramda';
import RouterTransitionContext from '~/contexts/RouterTransitionContext';
import { isEmptyObject } from '~/util/object';
import useControlFunctions from './useControlFunctions';
import flowActions from '~/components/page/Automation/v2/state';
import { useGetSessionHydrationQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import interactions from '../../../../state/interactions';

import CopyStateToolbar from './components/CopyStateToolbar';

type Props = {
  dataTestId?: string;
  isFullScreen?: boolean;
  toggleFullScreen: () => void;
};

const text = {
  infoLink:
    'https://help.dathuis.nl/nl/collections/2405287-4-leads-activeren-met-e-mail-campagnes',
};

export type Expanded = 'blocks' | 'issues' | 'stats' | 'settings';

const ControlComponents: React.FCC<Props> = React.memo(
  ({ dataTestId, isFullScreen, toggleFullScreen, ...rest }) => {
    const account = useCurrentAccount();

    const [currentInteraction, setInteraction] = useRecoilState(interactions);

    const location = useLocation();
    const theme = useTheme();
    const [expanded, setExpanded] = useState<Expanded | null>('settings');
    const flowName = useRecoilValue(flowNameSelector);

    const [showMiniMap, setShowMiniMap] = useState<boolean>(true);
    const issuesInFlow = useRecoilValue(flowIssues);

    const currentFlowChanges = useRecoilValue(flowChanges);
    const [actions, setActions] = useRecoilState(flowActions);

    const { leaveHandler } = useContext(RouterTransitionContext);
    useEffect(() => {
      const hasChanges = checkFlowHasChanges(currentFlowChanges);
      if (hasChanges) {
        leaveHandler(false);
      }

      return () => {
        leaveHandler(true);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFlowChanges]);

    const onToggleExpand = (expand: Expanded) => () => {
      // When the user clicks the expanded category, close it
      if (expanded === expand) return setExpanded(null);
      return setExpanded(expand);
    };

    const breadcrumbs = [
      { label: 'Automation', to: '/-/automation' },
      { label: 'Flows', to: '/-/automation/flows' },
      { label: flowName },
    ];

    const toggleMiniMap = useCallback(() => {
      setShowMiniMap(prev => !prev);
    }, []);

    const getNodeColor = useCallback(
      (node: Node<NodeData<ClientFlowAction>>) => {
        if (!node.data || !node.data.action) return theme.color('text');

        return getCardValues({
          actionType: node.data.action.actionType,
          issueLevel: null,
          theme,
        }).color;
      },
      [theme],
    );

    const { onSave, onCancel, loading } = useControlFunctions();
    const { refetch, loading: sessionHydrationLoading } =
      useGetSessionHydrationQuery({
        variables: {
          accountId: account.id,
        },
      });

    const onlyHasStartAction =
      actions.length === 1 && actions[0].__typename === 'FlowV2_Action_Start';

    const controlButtonsEnabled =
      location.pathname.includes('builder-v2/create/') ||
      checkFlowHasChanges(currentFlowChanges);

    const hasIssues =
      !isEmptyObject(issuesInFlow) &&
      Object.values(issuesInFlow).some(value => !isEmpty(value));

    if (
      currentInteraction?.type === 'copyAction' &&
      !isNil(currentInteraction.copiedAction)
    ) {
      return (
        <Container>
          <BreadcrumbContainer
            align="center"
            justification="space-between"
            margin={['l', null, null, null]}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbContainer>
          <CopyStateToolbar
            currentInteraction={currentInteraction}
            onClose={() => {
              setInteraction(null);
            }}
            onCancel={() => {
              setActions(clone(currentInteraction.actionsBeforeCopy));
              setInteraction(null);
            }}
            hasChanges={checkFlowHasChanges(currentFlowChanges)}
          />
        </Container>
      );
    }

    return (
      <Container data-testid={dataTestId} {...rest}>
        {!isFullScreen && (
          <>
            <BreadcrumbContainer
              align="center"
              justification="space-between"
              margin={['l', null, null, null]}
            >
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </BreadcrumbContainer>
            <JustificationContainer align="start" justification="start">
              <Toolbar>
                {/* Empty span to move buttons to right hand side */}
                <span />
                <JustificationContainer>
                  <Button
                    size="medium"
                    label="Afbreken"
                    ghost
                    margin={[null, 'base', null, null]}
                    onClick={onCancel}
                    disabled={!controlButtonsEnabled}
                  />
                  <Button
                    size="medium"
                    dataTestId={TEST_ID.SAVE_BUTTON}
                    label={
                      loading || sessionHydrationLoading
                        ? 'Opslaan...'
                        : 'Aanpassingen opslaan'
                    }
                    icon="save"
                    appearance="secondary"
                    onClick={async () => {
                      await onSave();
                      await refetch();
                    }}
                    disabled={
                      !controlButtonsEnabled || hasIssues || onlyHasStartAction
                    }
                    loading={loading}
                  />
                </JustificationContainer>
              </Toolbar>
            </JustificationContainer>
          </>
        )}
        <ControlHUDContainer
          direction="column"
          align="end"
          isFullScreen={isFullScreen}
        >
          <ControlHUD
            isFullScreen={isFullScreen}
            onFullScreen={toggleFullScreen}
            infoLink={text.infoLink}
            toggleMiniMap={toggleMiniMap}
          />
          {showMiniMap && (
            <MiniMap
              nodeStrokeWidth={4}
              nodeBorderRadius={4}
              nodeColor={getNodeColor}
              pannable
              zoomable
            />
          )}
        </ControlHUDContainer>
        <AsideContainer
          onToggleExpand={onToggleExpand}
          expanded={expanded}
          isFullScreen={isFullScreen}
        />
      </Container>
    );
  },
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: 0 ${theme.space('l')};
    position: absolute;
    top: 0;
    /* Less than footer */
    z-index: 800;
    width: 100%;
    background-color: ${theme.color('tertiary', 'translucent')};

    ${REACT_FLOW_MINI_MAP} {
      background: ${theme.color('primary', 'light')};
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: ${theme.space('m')};
    }
  `,
);

const ControlHUDContainer = styled(JustificationContainer)<{
  isFullScreen?: boolean;
}>(
  ({ theme, isFullScreen = false }) => css`
    position: absolute;
    right: ${theme.space('l')};
    z-index: ${theme.z('top')};
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};
  `,
);

const BREADCRUMB_MOBILE_HEIGHT = '50px';
const BREADCRUMB_HEIGHT = '72px';
const BreadcrumbContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    height: ${BREADCRUMB_HEIGHT};

    ${theme.mq.lessThan('tabletLandscape')`
      height: ${BREADCRUMB_MOBILE_HEIGHT};
    `}
  `};
`;

export default ControlComponents;
